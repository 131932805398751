import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Flex, Form, Typography } from '@solace-health/ui';
import { USA_STATES, UsaState } from '../../../../../../shared/constants/general-constants';
import usePhysicianStates from '../../../../../../hooks/usePhysicianStates';

export default function PhysicianStates({ physicianId }: { physicianId: string }) {
  const { data, addPhysicianState, deletePhysicianState, refresh } = usePhysicianStates({ physicianId });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography.Header>Physician's States</Typography.Header>
      </AccordionSummary>
      <AccordionDetails>
        <Flex wrap="wrap" vertical gap="1rem">
          {USA_STATES.map((state) => (
            <Form.Container onSubmit={() => null}>
              <Flex gap="1rem">
                <Form.Checkbox
                  name="state"
                  option={{ label: `${state.abbreviation} - ${state.name}`, value: state.abbreviation }}
                  checked={data.some((physicianState) => physicianState.state === state.abbreviation)}
                  formOptions={{
                    async onChange(event) {
                      const shouldDeleteState = !event?.target?.value;
                      if (shouldDeleteState) {
                        await deletePhysicianState({ id: physicianId, state: state.abbreviation as UsaState });
                      } else {
                        await addPhysicianState({ id: physicianId, state: state.abbreviation as UsaState });
                      }
                      refresh();
                    },
                  }}
                />
              </Flex>
            </Form.Container>
          ))}
        </Flex>
      </AccordionDetails>
    </Accordion>
  );
}
