import { Flex, Form } from '@solace-health/ui';
import { Role, User } from '../../../../../types/user';
import * as S from './style';
import { useGetRoles } from '../../../../../hooks/useGetRoles';
import { orderBy } from 'lodash';
import { SolaceAPI } from '../../../../../utils/api';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../../../../../utils/errors';

export type Props = {
  user: User & { roles: Role[] };
  refresh: () => void;
};

export const RolesSection = ({ user, refresh }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: roles } = useGetRoles();

  const handleChange = async (roleId: number) => {
    try {
      await SolaceAPI.put({ path: `/api/internal_users/${user.id}/roles`, body: { role_id: roleId } });
      const role = roles.find((role) => role.id === roleId) as Role;
      enqueueSnackbar(`Updated the ${role.name} role.`, { variant: 'success' });
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to update user's role - ${error.message}`, { variant: 'error' });
    }
    refresh();
  };

  const sortedRoles = orderBy(roles, ['name']);

  const isChecked = (role: Role) => {
    return user.roles.some((userRole) => userRole.name === role.name);
  };

  return (
    <S.Container>
      <Flex vertical justify="space-between" gap="1rem">
        <Form.Container onSubmit={() => null}>
          <Flex vertical gap="1rem" style={{ padding: '24px' }}>
            {sortedRoles.map((role) => {
              return (
                <Form.Checkbox
                  name={role.name}
                  option={{ label: role.name, value: String(role.id) }}
                  checked={isChecked(role)}
                  formOptions={{
                    async onChange() {
                      handleChange(role.id);
                    },
                  }}
                />
              );
            })}
          </Flex>
        </Form.Container>
      </Flex>
    </S.Container>
  );
};
