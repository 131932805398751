import { Button, Divider, Flex, Icons } from '@solace-health/ui';
import * as S from './style';

type DrawerFooterProps = {
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  primaryButtonIsSubmitting?: boolean;
  primaryButtonShowArrow?: boolean;
  showBackButton?: boolean;
  backButtonOnClick?: () => void;
  showCheckAnotherPolicy?: boolean;
  checkAnotherPolicyOnClick?: () => void;
  children?: React.ReactNode;
};

export const DrawerFooter = ({
  primaryButtonText = undefined,
  primaryButtonOnClick = () => null,
  primaryButtonIsSubmitting = false,
  primaryButtonShowArrow = false,
  showBackButton = true,
  backButtonOnClick = () => null,
  showCheckAnotherPolicy = false,
  checkAnotherPolicyOnClick = () => null,
  children = undefined,
}: DrawerFooterProps) => {
  return primaryButtonText || showBackButton || checkAnotherPolicyOnClick ? (
    <S.Container gap="2rem" vertical>
      <Divider style={{ margin: 0 }} />

      <Flex vertical>
        <S.ButtonContainer gap="1rem">
          {showBackButton && (
            <Button.Outline onClick={backButtonOnClick} style={{ flex: primaryButtonText ? '0 1' : '1 1' }}>
              <Flex gap="0.5rem" align="center">
                Back
              </Flex>
            </Button.Outline>
          )}

          {showCheckAnotherPolicy && (
            <Button.Outline onClick={checkAnotherPolicyOnClick} style={{ flex: primaryButtonText ? '2 1' : '1 1' }}>
              <Flex gap="0.5rem" align="center">
                Check Another Policy
              </Flex>
            </Button.Outline>
          )}

          {primaryButtonText && (
            <Button.Primary onClick={primaryButtonOnClick} isSubmitting={primaryButtonIsSubmitting}>
              <Flex gap="0.5rem" align="center">
                {primaryButtonText} {primaryButtonShowArrow && <Icons.Arrow color="#fff" />}
              </Flex>
            </Button.Primary>
          )}
        </S.ButtonContainer>

        {children}
      </Flex>
    </S.Container>
  ) : null;
};
