import { Flex, Size, Icons, Typography, Button } from '@solace-health/ui';
import * as S from './style';
import { phoneNumberFormat } from '../../../../../utils/general';

export const UserCard = ({
  fullName,
  userType,
  phone,
  email,
  onClickCta,
  ctaText,
}: {
  fullName: string;
  userType: string;
  phone: string;
  email: string;
  onClickCta: () => void;
  ctaText: string;
}) => (
  <S.UserCardContainer>
    <Flex vertical gap="0.5rem" style={{ maxWidth: '13rem', wordWrap: 'break-word' }}>
      <Typography.Body size={Size.LG} bold>
        {fullName}
      </Typography.Body>
      <Typography.Body>{userType}</Typography.Body>
      <Typography.Body>{phoneNumberFormat(phone)}</Typography.Body>
      <Typography.Body>{email}</Typography.Body>
    </Flex>
    <Flex align="flex-end">
      <Button.Unstyled onClick={onClickCta}>
        <Flex align="center">
          <Typography.Body color="#285E50" bold>
            {ctaText}
          </Typography.Body>
          <Icons.Arrow direction="left" color="#285E50" />
        </Flex>
      </Button.Unstyled>
    </Flex>
  </S.UserCardContainer>
);
