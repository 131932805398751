import { Flex, styled } from '@solace-health/ui';

export const Container = styled(Flex)`
  border-radius: var(--Radius-Small, 0.5rem);
  border-width: 1px;
  border-style: solid;
  gap: 0.5rem;
  padding: 1rem;
  justify-content: space-between;
  box-shadow:
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
`;
