import { Button, Flex, Form, Segmented, Typography, useForm } from '@solace-health/ui';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { AdminTable } from '../../../components/shared/Table';
import { Booking, BookingState } from '../../../types/booking';
import { Link } from 'react-router-dom';
import { hasRole, titleCase } from '../../../shared/utils/gen-utils';
import StatusPill from '../../../components/sharedStatusPill/StatusPill';
import { Role, RoleName, TraitId, User } from '../../../types/user';
import { useGetPaginatedVideoCalls } from '../../../hooks/bookings/useGetPaginatedVideoCalls';
import useCurrentUser from '../../../hooks/useCurrentUser';

const TABS = {
  ALL: 'all',
  PHYSICIAN: TraitId.CommunityHealthPhysician,
  ADVOCATE: TraitId.CommunityHealthAdvocate,
};

type Tab = keyof typeof TABS;

const statusPillStyle = (state: BookingState) => {
  switch (state) {
    case BookingState.Accepted:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
      };
    case BookingState.NotaMatch:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      };
    default:
      return {
        borderColor: 'var(--border-color-light, #D9D9D9)',
        backgroundColor: 'var(--surface-primary, #FFF)',
      };
  }
};

export const CallTranscriptsPage = () => {
  const history = useNavigate();
  const { data: user } = useCurrentUser<User & { roles: Role[] }>({ include: ['roles'] });

  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const isPhysician = user && hasRole({ user, roles: [RoleName.Physician] });
  const isAdvocateAdmin = user && hasRole({ user, roles: [RoleName.AdvocateAdmin] });

  let traitId = activeTab === TABS.ALL ? null : (activeTab as TraitId);

  if (isAdvocateAdmin) traitId = TraitId.CommunityHealthAdvocate;
  if (isPhysician) traitId = TraitId.CommunityHealthPhysician;

  const {
    data: bookings,
    isLoading: loading,
    setQueryParams,
    searchParams,
    pagination,
    setSearch,
  } = useGetPaginatedVideoCalls({ traitId });

  const formMethods = useForm<{ with_transcripts: 'true' | 'false' }>({
    defaultValues: {},
  });
  const withTranscripts = formMethods.watch('with_transcripts');

  const { columns, tableData } = useMemo(() => {
    if (loading) return { columns: [], tableData: [] };

    const columns = [
      {
        title: 'Date',
        dataIndex: 'booking',
        render: (booking: Booking) => new Date(booking.start).toLocaleString('en-us', { timeZoneName: 'short' }),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Provider',
        dataIndex: 'booking',
        render: (booking: Booking) => `${booking.advocate?.first_name} ${booking.advocate?.last_name}`,
      },
      {
        title: 'Patient',
        dataIndex: 'booking',
        render: (booking: Booking) => (
          <Link
            to={`/patients/${booking.client?.id}`}
          >{`${booking.client?.first_name} ${booking.client?.last_name} (${booking.client?.internal_id})`}</Link>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'state',
        render: (state: Booking['state']) => {
          const { borderColor, backgroundColor } = statusPillStyle(state);
          return (
            <StatusPill borderColor={borderColor} backgroundColor={backgroundColor}>
              <Typography.Body>{titleCase(state)}</Typography.Body>
            </StatusPill>
          );
        },
      },
      {
        title: 'View',
        dataIndex: 'booking',
        render: (booking: Booking) => (
          <Button.Outline
            onClick={() => history(`/call_transcripts/${booking.video_call_id}`)}
            disabled={booking.call_transcript_items?.length === 0}
          >
            View Transcript
          </Button.Outline>
        ),
      },
    ];

    const filteredBookings =
      withTranscripts === 'true' ? bookings.filter((booking) => booking.call_transcript_items?.length || -1 > 0) : bookings;

    const filteredTableData = filteredBookings.map((booking) => ({
      booking,
      patient: `${booking.client?.first_name} ${booking.client?.last_name} (${booking.client?.internal_id})`,
      physician: booking.advocate.first_name,
      start: new Date(booking.start).toLocaleString(),
      state: booking.state,
    }));

    return { columns, tableData: filteredTableData };
  }, [bookings, loading, withTranscripts]);

  const onChangeTab = (tab: Tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    {
      label: 'All',
      value: TABS.ALL,
    },
    {
      label: 'Physician',
      value: TABS.PHYSICIAN,
    },
    {
      label: 'Advocate',
      value: TABS.ADVOCATE,
    },
  ].filter((t) => {
    if (isAdvocateAdmin) return t.value === TABS.ADVOCATE;
    if (isPhysician) return t.value === TABS.PHYSICIAN;

    return true;
  });

  return (
    <Flex vertical gap="2rem">
      <Typography.Display>Call Transcripts</Typography.Display>
      <div>
        <Segmented defaultValue={activeTab} options={tabs} onChange={(e) => onChangeTab(e as Tab)} />
      </div>
      <Form.Container onSubmit={() => null} formMethods={formMethods}>
        <Form.Checkbox name="with_transcripts" option={{ label: 'With Transcripts', value: 'true' }}></Form.Checkbox>
      </Form.Container>
      <AdminTable
        columns={columns}
        data={tableData}
        showSearch
        onRowClick={() => null}
        onChange={setQueryParams}
        showPagination={true}
        pagination={pagination}
        onTextFilter={setSearch}
        defaultSearch={searchParams.search}
      />
    </Flex>
  );
};
