import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import qs from 'qs';
import useSWR from 'swr';
import { CommunityHealthAppointments } from '../types/listing';
import { User } from '../types/user';
import fetcher from './fetcher';

dayjs.extend(objectSupport);

export const usePhysicianAvailability = ({
  month,
  year,
  user,
  includeOffHours,
  shouldFetch,
}: {
  month: number;
  year: number;
  user: User;
  includeOffHours: boolean;
  shouldFetch: boolean;
}) => {
  const monthOffset = month - 1;
  const startDate = dayjs({ year, month: monthOffset }).toISOString();
  const endDate = dayjs({ year, month }).endOf('month').toISOString();

  const { data, error, mutate } = useSWR<CommunityHealthAppointments>(
    shouldFetch && user?.id
      ? `/v1/api/admin/physician_availability?${qs.stringify(
          {
            start_dt: startDate,
            end_dt: endDate,
            time_zone: user.time_zone,
            user_id: user.id,
            include_off_hours: includeOffHours,
          },
          { skipNulls: true },
        )}`
      : null,
    fetcher,
  );

  return {
    loading: data === undefined,
    data: data || ({} as CommunityHealthAppointments),
    error,
    refresh: mutate,
  };
};
