import { Button, Divider, Flex, Icons, LoadingSpinner, Size, Typography, styled } from '@solace-health/ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { GetUserResponse } from '../../../../../../hooks/useGetUser';
import { UseJitsi } from '../../../../../../hooks/useJitsi';
import { Booking } from '../../../../../../types/booking';
import { CommunityHealthListing } from '../../../../../../types/listing';
import { timeZoneName } from '../../../../../../utils/general';
import { MeetingOptions } from '../SchedulingDrawer';
import { RaisedHands } from './RaisedHands';
import { Top } from './Top';

dayjs.extend(timezone);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
`;

const Section = ({ Icon, label, line1, line2 }: { Icon: React.ReactNode; label: string; line1: string; line2?: string }) => {
  return (
    <Flex gap="var(--Spacing-lg, 1rem)" align="flex-start">
      {Icon}
      <Flex vertical gap="var(--Spacing-xs, 0.25rem)">
        <Typography.Body>{label}</Typography.Body>
        <Typography.Body color="var(--copy-secondary, #555)">{line1}</Typography.Body>
        {line2 && <Typography.Body color="var(--copy-secondary, #555)">{line2}</Typography.Body>}
      </Flex>
    </Flex>
  );
};

type Props = {
  booking: Booking & { listing: CommunityHealthListing };
  onClose: () => void;
  user: GetUserResponse;
  meetingType: MeetingOptions;
  rescheduling?: boolean;
};

export const Confirmation = ({ booking, onClose, user, meetingType, rescheduling = false }: Props) => {
  const { phoneNumber } = UseJitsi();

  const headerTextOptions = {
    [MeetingOptions.Physician]: 'Clinician',
    [MeetingOptions.AdvocateIntro]: 'Advocate Intro',
    [MeetingOptions.AdvocateFollowUp]: 'Advocate Follow-Up',
  };

  if (!user) return <LoadingSpinner />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Top />
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <RaisedHands style={{ zIndex: 9 }} />
          </div>
          <Typography.Display center>{`${headerTextOptions[meetingType]} Visit ${
            rescheduling ? 'Rescheduled' : 'Scheduled'
          }!`}</Typography.Display>
          <Typography.Body size={Size.LG}>Schedule notifications sent.</Typography.Body>
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', gap: '24px' }}>
            <Section
              Icon={<Icons.Person />}
              label="2 Guests"
              line1={booking.listing.advocate_name}
              line2={`${user.fullName} - Patient`}
            />
            <Section
              Icon={<Icons.Calendar color="#000" />}
              label={dayjs(booking.start).format('dddd, MMMM D')}
              line1={`${dayjs.utc(booking.start).tz(user.time_zone).format('h:mma')} - ${dayjs
                .utc(booking.end)
                .tz(user.time_zone)
                .format('h:mma')} (${timeZoneName(user.time_zone)})`}
            />
            <Section
              Icon={<Icons.Video />}
              label="Video Call"
              line1={`Pin: ${booking.video_call?.pin_code || 'Generating...'}`}
            />
            <Section
              Icon={<Icons.Call />}
              label={phoneNumber?.formattedNumber ?? ''}
              line1={`Pin: ${booking.video_call?.pin_code || 'Generating...'}`}
            />
            <Button.Primary onClick={onClose}>Close</Button.Primary>
          </div>
        </div>
      </Content>
    </div>
  );
};
