import { User } from '../../types/user';
import useSWR from 'swr';
import fetcher from '../fetcher';

export type ClaimsSummary = {
  total_patient_responsibility_in_cents: number;
};

const useGetClaimsSummary = ({ patientId }: { patientId: User['id'] }) => {
  const { data, isLoading, mutate } = useSWR<ClaimsSummary>(`/v1/api/patients/${patientId}/claims_summary`, fetcher);

  return {
    data,
    isLoading,
    refresh: mutate,
  };
};

export default useGetClaimsSummary;
