import { Typography } from '@solace-health/ui';
import * as S from './style';

type DrawerHeaderProps = {
  headingText?: string;
  buttonOnClick?: () => void;
};

export const DrawerHeader = ({ headingText, buttonOnClick = () => null }: DrawerHeaderProps) => {
  return (
    <S.HeaderWrapper justify={headingText ? 'space-between' : 'end'} align="center">
      {headingText && <Typography.Header>{headingText}</Typography.Header>}
      <S.HeaderButton onClick={buttonOnClick}>Close</S.HeaderButton>
    </S.HeaderWrapper>
  );
};
