import { Avatar, Collapse, Flex, Size, Typography, styled } from '@solace-health/ui';
import { useState } from 'react';
import dayjs from 'dayjs';
import { AdvocatePatient, ArchiveReason } from '../../../../../types/advocatePatient';

const Container = styled(Flex)<{ isExpanded: boolean; hasContext: boolean }>`
  margin: 0.5rem 0;

  &&&& .ant-collapse {
    border-color: ${({ isExpanded }) => (isExpanded ? '#DA4D54' : '#000')};
  }

  &&&& .ant-collapse-arrow {
    display: ${({ hasContext }) => (!hasContext ? 'none' : 'block')};
  }

  &&&&& .ant-collapse-item {
    border-color: ${({ isExpanded }) => (isExpanded ? '#DA4D54' : '#000')};
    border-radius: 10px;
    ${({ isExpanded }) => !isExpanded && `border-left: 8px solid #E4767C`}
    ${({ isExpanded }) => isExpanded && `padding-left: 8px`}
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `.ant-collapse-item-active, .ant-collapse-content-box {
        background-color: #f1e1e2;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }`}
`;

type Props = {
  advocatePatient: AdvocatePatient;
};

const ArchiveAccordian = ({ advocatePatient }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (key: string | string[]) => {
    setIsExpanded(key.length > 1);
  };

  const getArchiveReason = (reason?: ArchiveReason) => {
    if (!reason) return null;

    const archiveReasonStrings = {
      [ArchiveReason.NeedsMet]: (
        <Typography.Body>
          {' '}
          because <b>patient&apos;s needs have been met</b>
        </Typography.Body>
      ),
      [ArchiveReason.Other]: '',
      [ArchiveReason.Unresponsive]: (
        <Typography.Body>
          {' '}
          because <b>patient is unresponsive</b>
        </Typography.Body>
      ),
    };

    return archiveReasonStrings[reason];
  };

  if (!advocatePatient?.archived_dt) return null;

  const hasContext = !!advocatePatient?.archive_context;
  const advocateName = `${advocatePatient?.advocate?.first_name} ${advocatePatient?.advocate?.last_name}`;

  return (
    <Container vertical isExpanded={isExpanded} hasContext={hasContext}>
      <Collapse
        onChange={handleClick}
        collapsible={hasContext ? 'header' : 'disabled'}
        items={[
          {
            key: advocatePatient.id.toString(),
            label: (
              <Flex justify="space-between" gap="1rem">
                <Typography.Body color="#000000" size={Size.MD}>
                  <Flex gap="4px">
                    Patient marked inactive by <b>{advocateName}</b> since{' '}
                    <b>{dayjs(advocatePatient.archived_dt).format('MM/DD/YYYY')}</b>
                    {getArchiveReason(advocatePatient.archive_reason)}
                  </Flex>
                </Typography.Body>
                {hasContext && <Typography.Body color="#000000">View Reason</Typography.Body>}
              </Flex>
            ),
            children: (
              <Flex vertical gap={10}>
                <Typography.Body color="#000000">{advocatePatient.archive_context}</Typography.Body>
                <Flex gap={8} align="center">
                  <Avatar size={40} src={advocatePatient.advocate?.profile_image_url || ''} />
                  <Typography.Header size={Size.XS} color="#000000">
                    {advocateName}
                  </Typography.Header>
                </Flex>
              </Flex>
            ),
          },
        ]}
      />
    </Container>
  );
};

export default ArchiveAccordian;
