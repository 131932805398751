import axios from 'axios';
import { SolaceApiError } from './errors';

export class SolaceAPI {
  public static async get<T>({
    path,
    params = {},
    config,
  }: {
    path: string;
    params?: Record<string, unknown>;
    config?: Record<string, any>;
  }) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/v1${path}`, {
        params,
        headers: this.headers(),
        ...config,
      });

      return {
        ...response,
        data: response.data as T,
      };
    } catch (e: any) {
      throw new SolaceApiError(e.response.data);
    }
  }

  public static async post<T>({ path, body = {} }: { path: string; body?: Record<string, any> }) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/v1${path}`, body, { headers: this.headers() });
      return {
        ...response,
        data: response.data as T,
      };
    } catch (e: any) {
      throw new SolaceApiError(e.response.data);
    }
  }

  public static async put<T>({ path, body = {} }: { path: string; body?: Record<string, any> }) {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_HOST}/v1${path}`, body, {
        headers: this.headers(),
      });
      return {
        ...response,
        data: response.data as T,
      };
    } catch (e: any) {
      throw new SolaceApiError(e.response.data);
    }
  }

  public static async formPut({ path, body }: { path: string; body: Record<string, any> }) {
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      formData.append(key, body[key]);
    });

    return axios
      .put(`${process.env.REACT_APP_API_HOST}/v1${path}`, formData, {
        headers: { ...this.headers(), 'Content-Type': 'multipart/form-data' },
      })
      .catch((e) => {
        throw new SolaceApiError(e.response.data);
      });
  }

  public static async delete({ path, body = {} }: { path: string; body?: Record<string, any> }) {
    return axios
      .delete(`${process.env.REACT_APP_API_HOST}/v1${path}`, { headers: this.headers(), data: body })
      .catch((e) => {
        throw new SolaceApiError(e);
      });
  }

  private static headers() {
    const token = localStorage.getItem('accessToken');
    return { Authorization: `Bearer ${token}`, 'solace-client': 'solace-health-admin' };
  }
}
