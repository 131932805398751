import { SolaceAPI } from '../../utils/api';
import { User } from '../../types/user';
import { Claim } from '../../types/claim';
import useGetPaginatedResource from '../useGetPaginatedResource';

const useClaims = ({
  patientId,
  include = undefined,
  sort = undefined,
}: {
  patientId: User['id'];
  include?: ('encounter' | 'provider' | 'service_lines' | 'service_lines.cpt_code')[];
  sort?: string[];
}) => {
  const response = useGetPaginatedResource<Claim>(`/v1/api/patients/${patientId}/claims`, {
    include,
    sort,
  });

  const forgiveClaim = async ({ id, reason }: { id: Claim['id']; reason: string }) => {
    return await SolaceAPI.post({ path: `/api/claims/${id}/forgiveness`, body: { reason } });
  };

  const forgiveAllClaims = async ({ reason }: { reason: string }) => {
    return await SolaceAPI.post({ path: `/api/patients/${patientId}/claims_forgiveness`, body: { reason } });
  };

  return {
    ...response,
    refresh: response.mutate,
    forgiveClaim,
    forgiveAllClaims,
  };
};

export default useClaims;
