import { Flex, Form, FormatType, Modal, Typography, useForm } from '@solace-health/ui';
import { SolaceAPI } from '../../../../utils/api';
import { SolaceApiError } from '../../../../utils/errors';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TraitId } from '../../../../types/user';
import { States } from '../../../../shared/constants/general-constants';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const TRAIT_IDS = [
  { label: 'Self Pay', value: TraitId.SelfPayAdvocate },
  { label: 'ICA', value: TraitId.CommunityHealthAdvocate },
  { label: 'Solace ICA', value: TraitId.SolaceHealthAdvocate },
  { label: 'Physician', value: TraitId.CommunityHealthPhysician },
];

export const CreateAdvocateModal = ({ isOpen, setIsOpen }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formMethods = useForm<{
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    alternate_email: string | null;
    phone: string | null;
    state: string | null;
    traitIds: TraitId[];
  }>({
    defaultValues: {
      first_name: null,
      last_name: null,
      email: null,
      alternate_email: null,
      phone: null,
      state: null,
      traitIds: [],
    },
  });

  const firstName = formMethods.watch('first_name');
  const lastName = formMethods.watch('last_name');
  const traitIds = formMethods.watch('traitIds');

  useEffect(() => {
    let email =
      `${firstName?.replace(/[^a-zA-Z]/g, '') || ''}.${lastName?.replace(/[^a-zA-Z]/g, '') || ''}@solace.health`.toLowerCase();
    formMethods.setValue('email', email);
  }, [firstName, lastName]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const { data } = await SolaceAPI.post<{ user: { id: string } }>({
        path: '/api/admin/advocates',
        body: formMethods.getValues(),
      });
      enqueueSnackbar(`Sucessfully created advocate`, { variant: 'success' });

      formMethods.reset();

      history(`/Advocates/${data.user.id}`);
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to create advocate - ${error.message} [${error.requestId}]`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const stateOptions = Object.entries(States).map(([key, value]) => ({ label: value, value: key }));

  const onClose = () => {
    setIsOpen(false);
  };

  const isSICA = traitIds.includes(TraitId.SolaceHealthAdvocate);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form.Container formMethods={formMethods} onSubmit={handleSubmit}>
        <Flex vertical gap="2rem">
          <Typography.Display>Create New Advocate</Typography.Display>
          <Flex vertical gap="1rem">
            <Form.SelectMenu
              formOptions={{ required: true }}
              label="Advocate Type(s)"
              name="traitIds"
              options={TRAIT_IDS}
              mode="multiple"
            />
            <Flex gap="1rem">
              <Form.Text name="first_name" label="First Name" formOptions={{ required: true }} />
              <Form.Text name="last_name" label="Last Name" formOptions={{ required: true }} />
            </Flex>
            <Form.Text name="email" label="Solace Email" formOptions={{ required: true }} />
            {isSICA && (
              <Form.Text
                name="alternate_email"
                label="Alternate Email"
                details="This email will be used to send communications before they have a Solace email"
                formOptions={{ required: true }}
              />
            )}

            {isSICA && (
              <Form.SelectMenu
                options={stateOptions}
                name="state"
                label="State"
                formOptions={{ required: true }}
                details="This is needed to perform the background check"
              />
            )}
            <Form.Text
              name="phone"
              label="Phone"
              format={FormatType.Phone}
              details={isSICA ? 'This is needed to perform the background check' : ''}
              formOptions={{ required: isSICA }}
            />
          </Flex>
          <Form.Submit isSubmitting={isSubmitting} label="Create Advocate" />
        </Flex>
      </Form.Container>
    </Modal>
  );
};
