import { Flex, Form, Typography, useForm } from '@solace-health/ui';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import { DateFormat } from '../../../../../shared/utils/date-utils';
import { Prospect } from '../../../../../types/prospect';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { FormContainer } from '../../shared/FormContainer/FormContainer';
import { WarningCard } from '../../shared/WarningCard/WarningCard';
import { AddPatientEventContext } from '../../stateMachine';

type ConfirmNameAndDobFormValues = {
  first_name: string;
  last_name: string;
  patient_dob: string;
};

type ConfirmNameAndDobProps = {
  prospect: Prospect;
  upsertProspect: (data: UpsertProspectDto) => Promise<{ data: Prospect }>;
  refreshProspect: () => Promise<Prospect | undefined>;
  onNextStep: (context?: AddPatientEventContext) => void;
  onLastStep: () => void;
};

export const ConfirmNameAndDob = ({
  prospect,
  upsertProspect,
  refreshProspect,
  onNextStep,
  onLastStep,
}: ConfirmNameAndDobProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    first_name,
    last_name,
    payload: { patient_dob },
  } = prospect;
  const form = useForm<ConfirmNameAndDobFormValues>({
    defaultValues: {
      first_name,
      last_name,
      patient_dob,
    },
  });
  const { watch } = form;
  const firstNameFormValue = watch('first_name');
  const lastNameFormValue = watch('last_name');
  const patientDobFormValue = watch('patient_dob');

  const formHasBeenUpdated = () => {
    const normalizedPatientDobFormValue = dayjs(patientDobFormValue).format(DateFormat.Short);
    const normalizedPatientDob = dayjs(patient_dob).format(DateFormat.Short);
    return (
      firstNameFormValue !== first_name ||
      lastNameFormValue !== last_name ||
      normalizedPatientDobFormValue !== normalizedPatientDob
    );
  };

  const handleOnSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    if (!prospect) {
      enqueueSnackbar('There was an error saving the patient.', { variant: 'error' });
      return;
    }

    setIsSubmitting(true);
    const data = form.getValues();

    await upsertProspect({ id: prospect.id, ...data });
    await refreshProspect();

    setIsSubmitting(false);

    onNextStep({
      nameOrDobUpdated: formHasBeenUpdated(),
    });
  };

  return (
    <FormContainer formMethods={form} onSubmit={handleOnSubmit}>
      <Flex vertical gap="1.5rem">
        <WarningCard
          title="Does this look right?"
          content="Double-check for spelling and accuracy."
          bgColor="#f4e5ca"
          secondaryColor="#D7A13B"
        />

        <Flex vertical gap="0.5rem">
          <Flex gap="0.5rem">
            <Form.TextInput name="first_name" label="First Name" formOptions={{ required: true }} />
            <Form.TextInput name="last_name" label="Last Name" formOptions={{ required: true }} />
          </Flex>

          <Typography.Body>Please confirm patient&apos;s name. </Typography.Body>
        </Flex>

        <Form.DateTextMaskInput name="patient_dob" label="Date of Birth" formOptions={{ required: true }} />
      </Flex>

      <DrawerFooter
        primaryButtonText="Continue"
        primaryButtonOnClick={handleOnSubmit}
        primaryButtonShowArrow
        backButtonOnClick={onLastStep}
        primaryButtonIsSubmitting={isSubmitting}
      />
    </FormContainer>
  );
};
