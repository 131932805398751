type SortMappings = {
  [sortString: string]: string[];
};

export const textCompare = <T extends Record<string, unknown>>(a: T, b: T, key: keyof T) =>
  (String(a[key]) || '').localeCompare(String(b[key]) || '');

export const dateCompare = <T extends Record<string, string>>(a: T, b: T, key: keyof T) =>
  new Date(a[key]).getTime() - new Date(b[key]).getTime();

/**
 * Helper function to convert an array of sort strings into an array of strings that the server can use.
 * @param sortStrings Ex. `['user,fullName', '-start']`
 * @param sortMappings Ex. `{ 'user,fullName': ['user.first_name', 'user.last_name'] }`
 * @returns An array of strings following the provided mappings. Ex. `['user,fullName', '-start']` -> `['user.first_name', 'user.last_name', '-start']`
 */
export const formatSortStrings = (sortStrings: string[], sortMappings: SortMappings) => {
  const output: string[] = [];
  const processedStrings: Set<string> = new Set();
  const mappingKeys = Object.keys(sortMappings);

  const hasAlreadyProcessed = (value: string) => processedStrings.has(value);
  const getSortDirection = (value: string) => (value[0] === '-' ? '-' : '');

  sortStrings.forEach((sortString) => {
    if (hasAlreadyProcessed(sortString)) {
      return;
    }

    mappingKeys.forEach((key) => {
      if (hasAlreadyProcessed(sortString)) {
        return;
      }

      if (sortString.includes(key)) {
        sortMappings[key].forEach((value) => output.push(`${getSortDirection(sortString)}${value}`));
        processedStrings.add(sortString);
      }
    });

    if (!hasAlreadyProcessed(sortString)) {
      output.push(sortString);
      processedStrings.add(sortString);
    }
  });

  return output;
};
