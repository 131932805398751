import { InsuranceProgramId } from './prospect';
import { User } from './user';

export enum TradingPartnerServiceId {
  // Medicare
  Medicare = 'CMSMED',

  // Medicare Advantage
  Aetna = 'AETNA',
  Cigna = 'CIGNA',
  UnitedHealthCare = 'UHC',
  Humana = 'HUMANA',
  Wellcare = 'WELLCR',

  // Medicare Advantage Blue Cross Blue Shield
  BlueCrossBlueShieldAlabama = 'ALBCBS',
  BlueCrossBlueShieldAlaska = 'AKBCBS',
  BlueCrossBlueShieldArizona = 'AZBCBS',
  BlueCrossBlueShieldArkansas = 'ARBCBS',
  BlueCrossBlueShieldCalifornia = 'CABCBS',
  BlueCrossBlueShieldColorado = 'COBCBS',
  BlueCrossBlueShieldConnecticut = 'CTBCBS',
  BlueCrossBlueShieldDelaware = 'DEBCBS',
  BlueCrossBlueShieldDc = 'DCBCBS',
  BlueCrossBlueShieldFlorida = 'FLBCBS',
  BlueCrossBlueShieldGeorgia = 'GABCBS',
  BlueCrossBlueShieldHawaii = 'HIBCBS',
  BlueCrossBlueShieldIdaho = 'IDBCBS',
  BlueCrossBlueShieldIllinois = 'ILBCBS',
  BlueCrossBlueShieldIndiana = 'INBCBS',
  BlueCrossBlueShieldIowa = 'IABCBS',
  BlueCrossBlueShieldKansas = 'KSBCBS',
  BlueCrossBlueShieldKentucky = 'KYBCBS',
  BlueCrossBlueShieldLouisiana = 'LABCBS',
  BlueCrossBlueShieldMaine = 'MEBCBS',
  BlueCrossBlueShieldMaryland = 'MDBCBS',
  BlueCrossBlueShieldMassachusetts = 'MABCBS',
  BlueCrossBlueShieldMichigan = 'MIBCBS',
  BlueCrossBlueShieldMinnesota = 'MNBCBS',
  BlueCrossBlueShieldMississippi = 'MSBCBS',
  BlueCrossBlueShieldMissouri = 'MOBCBS',
  BlueCrossBlueShieldMontana = 'MTBCBS',
  BlueCrossBlueShieldNebraska = 'NEBCBS',
  BlueCrossBlueShieldNevada = 'NVBCBS',
  BlueCrossBlueShieldNewHampshire = 'NHBCBS',
  BlueCrossBlueShieldNewJersey = 'NJBCBS',
  BlueCrossBlueShieldNewMexico = 'NMBCBS',
  BlueCrossBlueShieldNorthCarolina = 'NCBCBS',
  BlueCrossBlueShieldNorthDakota = 'NDBCBS',
  BlueCrossBlueShieldOhio = 'OHBCBS',
  BlueCrossBlueShieldOklahoma = 'OKBCBS',
  BlueCrossBlueShieldOregon = 'ORBCBS',
  BlueCrossBlueShieldPennsylvania = 'PABCBS',
  BlueCrossBlueShieldRhodeIsland = 'RIBCBS',
  BlueCrossBlueShieldSouthCarolina = 'SCBCBS',
  BlueCrossBlueShieldSouthDakota = 'SDBCBS',
  BlueCrossBlueShieldTennessee = 'TNBCBS',
  BlueCrossBlueShieldTexas = 'TXBCBS',
  BlueCrossBlueShieldUtah = 'UTBCBS',
  BlueCrossBlueShieldVermont = 'VTBCBS',
  BlueCrossBlueShieldVirginia = 'VABCBS',
  BlueCrossBlueShieldWashington = 'WABCBS',
  BlueCrossBlueShieldWestVirginia = 'WVBCBS',
  BlueCrossBlueShieldWisconsin = 'WIBCBS',
  BlueCrossBlueShieldWyoming = 'WYBCBS',

  BlueCrossBlueShieldNewYork = 'NYEXCL',
  BlueCrossBlueShieldNewYorkWestern = 'NYBCBS',
  BlueCrossBlueShieldNortheastern = 'NYBCNE',
  BlueCrossBlueShieldHealthNow = 'HLTNOW',
}

export enum InsuranceCompanyId {
  Aetna = 'aetna',
  BlueCrossBlueShield = 'bcbs',
  Cigna = 'cigna',
  UnitedHealthCare = 'uhc',
  Humana = 'humana',
  Wellcare = 'wellcr',
}

export type Payor = {
  id: string;
  trading_partner_service_id: TradingPartnerServiceId;
  name: string;
  form_template_id: string;
  insurance_program_id: InsuranceProgramId;
  insurance_company_id: InsuranceCompanyId | null;
  candid_payer_uuid: string;
  candid_payer_id: string;
  candid_payer_name: string;
  pverify_payer_code: string;
  state: string;
  created_at: string;
  updated_at: string;
  insurance_program?: {
    id: InsuranceProgramId;
    name: string;
  };
  insurance_company?: {
    id: InsuranceCompanyId;
    name: string;
  };
  is_enabled: boolean;
  physicians?: User[];
};
