import { Flex, LoadingSpinner, Typography } from '@solace-health/ui';
import { TraitId, User } from '../../../../../types/user';
import { UserCard } from '../../shared/UserCard/UserCard';
import { useNavigate } from 'react-router-dom';
import useGetUser from '../../../../../hooks/useGetUser';
import { hasAtLeastOneTrait, hasTrait } from '../../../../../utils/user';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { GenericError } from '../../shared/GenericError/GenericError';

export const EmailAlreadyInUse = ({
  existingUserId,
  onLastStep,
}: {
  existingUserId: User['id'];
  onLastStep: () => void;
}) => {
  const { data: user, loading: isLoadingUser } = useGetUser<{ traits: true }>({
    id: existingUserId,
    includes: ['traits'],
  });

  const navigate = useNavigate();

  if (isLoadingUser) return <LoadingSpinner />;
  if (!user) return <GenericError />;

  const onClickCta = () => {
    if (hasAtLeastOneTrait({ user, traitIds: [TraitId.CommunityHealthPatient, TraitId.CommunityHealthLovedOne] })) {
      navigate(`/Patients/${user.id}`);
      return;
    }

    if (user.has_advocate_profile) {
      navigate(`/Advocates/${user.id}`);
      return;
    }

    navigate(`/Users/${user.id}`);
  };

  const userType = (() => {
    if (hasAtLeastOneTrait({ user, traitIds: [TraitId.CommunityHealthPatient, TraitId.CommunityHealthLovedOne] })) {
      return 'Patient';
    }

    if (hasTrait({ user, traitId: TraitId.CommunityHealthPhysician })) {
      return 'Physician';
    }

    if (user.has_advocate_profile) {
      return 'Advocate';
    }

    return 'Client';
  })();

  return (
    <>
      <Flex vertical align="center" justify="center" gap="1.5rem" style={{ padding: '1.5rem', height: '100%' }}>
        <Flex vertical align="center" gap="0.5rem">
          <Typography.Display center>This email is already in use.</Typography.Display>
          <Typography.Body center color="#555">
            A {userType.toLowerCase()} already exists with this email. Use the back button to switch to a new email address
            or view the existing {userType.toLowerCase()}'s file{userType === 'Patient' ? ' to schedule from there.' : ''}.
          </Typography.Body>
        </Flex>
        <UserCard
          fullName={user.fullName}
          userType={userType}
          phone={user.phone}
          email={user.email}
          onClickCta={onClickCta}
          ctaText={`View ${userType} File`}
        />
      </Flex>
      <DrawerFooter showBackButton backButtonOnClick={onLastStep} />
    </>
  );
};
