import { Button, Divider, Flex, Icons, LoadingSpinner, Size, Typography } from '@solace-health/ui';
import { useCheckInsuranceEligibility } from '../../../../../hooks/prospects/useCheckInsuranceEligibility';
import { Prospect } from '../../../../../types/prospect';
import * as S from './style';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { User } from '../../../../../types/user';
import { useNavigate } from 'react-router-dom';
import { EligibleStatusReason, ReferralEligibilityCheckStatus } from '../../../../../types/referrals';
import { WarningCard } from '../../shared/WarningCard/WarningCard';
import { EligibilityResultPatientInformation } from '../../shared/EligibilityResultPatientInformation/EligibilityResultPatientInformation';
import { EligibilityResultItem } from '../../shared/EligibilityResultItem/EligibilityResultItem';

export const EligibilityStatusEligible = ({
  prospect,
  onNextStep,
  userId,
}: {
  prospect: Prospect;
  onNextStep: () => void;
  userId: User['id'];
}) => {
  const navigate = useNavigate();
  const { data: lastEligibilityCheck, isLoading } = useCheckInsuranceEligibility({ prospectId: prospect.id });

  if (isLoading) return <LoadingSpinner />;
  if (!lastEligibilityCheck || lastEligibilityCheck.status === ReferralEligibilityCheckStatus.Failed)
    return <>Something went wrong...</>;

  const onClickViewPatientFile = () => {
    navigate(`/Patients/${userId}`);
  };

  const medicareIsSecondaryPayer =
    lastEligibilityCheck.status === ReferralEligibilityCheckStatus.Eligible &&
    lastEligibilityCheck.status_reason === EligibleStatusReason.SupplementalInsurance;

  const medicareInsuranceInformationHeader = medicareIsSecondaryPayer ? 'Secondary Payer' : 'Primary Payer';

  return (
    <>
      <Flex vertical style={{ width: '100%' }} gap="1.5rem">
        <S.EligibleStatusCard justify="space-between" align="center">
          <Typography.Header size={Size.SM}>Huzzah! 🥳 Patient Is Eligible.</Typography.Header>
          <Icons.CircleCheckAlt size={32} />
        </S.EligibleStatusCard>

        <Flex vertical gap="1.5rem">
          <EligibilityResultPatientInformation prospect={prospect} />

          <Divider style={{ margin: 0 }} />

          {medicareIsSecondaryPayer && (
            <WarningCard
              bgColor="#F4E5CA"
              secondaryColor="#D7A13B"
              title="Medicare is Secondary Payer"
              content={
                <Flex vertical gap="0.5rem">
                  <Typography.Body style={{ lineHeight: '150%' }}>
                    This patient is also covered by{' '}
                    <strong>{lastEligibilityCheck.medicare_supplemental_to_insurance?.payer_name}</strong>. If they have
                    their insurance card handy, you can collect this information now.
                  </Typography.Body>
                  <Typography.Body style={{ lineHeight: '150%' }}>
                    If not, no worries! You can continue to schedule.
                  </Typography.Body>

                  <Button.Unstyled style={{ justifyContent: 'left' }} onClick={onClickViewPatientFile}>
                    <Flex align="center" justify="flex-start" gap="0.25rem">
                      <Typography.Body color="#285E50">View Patient File</Typography.Body>
                      <Icons.Arrow size={20} color="#D7A13B" />
                    </Flex>
                  </Button.Unstyled>
                </Flex>
              }
            />
          )}

          <Flex vertical gap="1.5rem">
            <Typography.Header>Insurance Information</Typography.Header>

            {medicareIsSecondaryPayer && (
              <Flex vertical gap="1.5rem">
                <Typography.Header size={Size.SM} color="#3F937C">
                  Primary Payer
                </Typography.Header>
                <Flex>
                  <EligibilityResultItem
                    field="Payer"
                    value={lastEligibilityCheck.medicare_supplemental_to_insurance?.payer_name}
                  />
                  <EligibilityResultItem
                    field="Member ID"
                    value={lastEligibilityCheck.medicare_supplemental_to_insurance?.member_id}
                  />
                </Flex>
                <Flex>
                  <EligibilityResultItem
                    field="Effective Date"
                    value={lastEligibilityCheck.medicare_supplemental_to_insurance?.effective_date}
                  />
                  <EligibilityResultItem
                    field="Type"
                    value={lastEligibilityCheck.medicare_supplemental_to_insurance?.type}
                  />
                </Flex>
              </Flex>
            )}

            <Flex vertical gap="1.5rem">
              <Typography.Header size={Size.SM} color="#3F937C">
                {medicareInsuranceInformationHeader}
              </Typography.Header>
              <Flex>
                <EligibilityResultItem field="Payer" value={lastEligibilityCheck.payer_name} />
                <EligibilityResultItem field={lastEligibilityCheck.member_id_field} value={lastEligibilityCheck.member_id} />
              </Flex>
              <Flex>
                <EligibilityResultItem
                  field={lastEligibilityCheck.insurance_status_field}
                  value={lastEligibilityCheck.insurance_status}
                />
                <EligibilityResultItem
                  field="Remaining Deductible"
                  value={lastEligibilityCheck.remaining_deductible}
                  valueColor={lastEligibilityCheck.has_remaining_deductible ? undefined : '#3F937C'}
                />
              </Flex>
              <Flex>
                <EligibilityResultItem field="Effective Date" value={lastEligibilityCheck.coverage_effective_date} />
                <EligibilityResultItem
                  field="Co-Insurance"
                  value={lastEligibilityCheck.co_insurance}
                  valueColor={lastEligibilityCheck.has_co_insurance ? undefined : '#3F937C'}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <DrawerFooter
        primaryButtonOnClick={onNextStep}
        primaryButtonText="Schedule Visit"
        primaryButtonShowArrow
        showBackButton={false}
      >
        <Flex justify="center" align="center" style={{ paddingBottom: '2rem', paddingTop: '0.5rem' }}>
          <Button.Link onClick={onClickViewPatientFile} color="#285E50">
            View Patient File
          </Button.Link>
          <Icons.Arrow color="#285E50" size={20} />
        </Flex>
      </DrawerFooter>
    </>
  );
};
