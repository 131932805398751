import { Flex, Form, FormatType, useForm } from '@solace-health/ui';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import useProspect, { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import { USA_STATES } from '../../../../../shared/constants/general-constants';
import { HereFor, Prospect } from '../../../../../types/prospect';
import { User } from '../../../../../types/user';
import { SolaceAPI } from '../../../../../utils/api';
import { MAX_SCHEDULING_DAYS } from '../../const';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { FormContainer } from '../../shared/FormContainer/FormContainer';
import { AddPatientEventContext } from '../../stateMachine';
import { LovedOneInfoForm } from './LovedOneInfoForm';
import * as S from './style';
import { getPhysicianAvailabilityForProspect } from './util';

const defaultFormValues: UpsertProspectDto = {
  first_name: '',
  last_name: '',
  state: '',
  phone: '',
  email: '',
  here_for: '',
  payload: {
    loved_one_first_name: '',
    loved_one_last_name: '',
    loved_one_relationship: '',
    loved_one_phone: '',
    patient_dob: '',
    reason: '',
  },
} as const;

const getExistingUserForEmail = async (email: string) => {
  const { data: user } = await SolaceAPI.get<User>({
    path: '/api/admin/users/existing',
    params: { email },
  });

  return user;
};

const start_dt = dayjs.utc().startOf('day').toDate();
const end_dt = dayjs.utc(start_dt).endOf('day').add(MAX_SCHEDULING_DAYS, 'days').toDate();

type PatientInfoFormProps = {
  prospect: Prospect;
  onNextStep: (context?: AddPatientEventContext) => void;
};

const PatientInfoForm = ({ prospect, onNextStep }: PatientInfoFormProps) => {
  const { upsertProspect, refresh: refreshProspect, searchProspectsWithoutUser } = useProspect({ id: prospect.id });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<UpsertProspectDto>({
    defaultValues: defaultFormValues,
  });

  const { watch } = form;
  const hereFor = watch('here_for');
  const hereForLovedOne = hereFor === HereFor.LovedOne;

  // Initialize form when component mounts
  useEffect(() => {
    if (prospect) {
      form.reset({
        id: prospect.id,
        first_name: prospect.first_name || '',
        last_name: prospect.last_name || '',
        state: prospect.state || '',
        phone: prospect.phone || '',
        email: prospect.email || '',
        here_for: prospect.here_for,
        payload: {
          loved_one_first_name: prospect.payload.loved_one_first_name || '',
          loved_one_last_name: prospect.payload.loved_one_last_name || '',
          loved_one_relationship: prospect.payload.loved_one_relationship || '',
          loved_one_phone: prospect.payload.loved_one_phone || '',
          patient_dob: prospect.payload.patient_dob || '',
          reason: prospect.payload.reason || '',
        },
      });
    }
  }, [prospect.id]);

  // Reset form on unmount
  useEffect(() => {
    return () => form.reset(defaultFormValues);
  }, []);

  const onHandleSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    setIsSubmitting(true);
    const data = form.getValues();

    const existingUser = data.email ? await getExistingUserForEmail(data.email) : undefined;

    const { email, state, ...dataToUpsert } = data;

    await upsertProspect({ id: prospect.id, state: state || '', ...dataToUpsert, ...(existingUser ? {} : { email }) });
    await refreshProspect();

    if (existingUser) {
      setIsSubmitting(false);
      onNextStep({ existingUserId: existingUser.id });
      return;
    }

    const { noPhysiciansAvailable, noPhysiciansAvailableForPayor } = await getPhysicianAvailabilityForProspect({
      prospect_id: prospect.id,
      start_dt,
      end_dt,
    });

    if (noPhysiciansAvailable || noPhysiciansAvailableForPayor) {
      setIsSubmitting(false);
      onNextStep({ noPhysiciansAvailableForPayor, noPhysiciansAvailable });
      return;
    }

    const possibleDuplicateProspects = await searchProspectsWithoutUser({
      phone: data.phone as string,
      firstName: data.first_name as string,
      lastName: data.last_name as string,
      dob: data.payload?.patient_dob as string,
    }).then(({ data }) => {
      // Remove the current prospect from the results
      return data.filter((searchResult) => searchResult.prospect_id !== prospect.id);
    });

    setIsSubmitting(false);

    if (!isEmpty(possibleDuplicateProspects)) {
      onNextStep({ possibleDuplicateProspects });
      return;
    }

    onNextStep();
  };

  return (
    <FormContainer formMethods={form} onSubmit={onHandleSubmit}>
      <S.FormInnerWrapper vertical gap="1.5rem">
        <Flex vertical gap="1.5rem">
          <Form.Text name="first_name" label="First Name" formOptions={{ required: true }} />
          <Form.Text name="last_name" label="Last Name" formOptions={{ required: true }} />
          <Form.DateTextMaskInput name="payload.patient_dob" label="Date of Birth" formOptions={{ required: true }} />
          <Form.SelectMenu
            name="state"
            label="Where is the patient located?"
            options={USA_STATES.map(({ name, abbreviation }) => ({ label: name, value: abbreviation }))}
            formOptions={{ required: true }}
          />
          <Form.Text
            name="phone"
            label="Phone"
            formOptions={{ required: true }}
            format={FormatType.Phone}
            pattern={FormatType.Phone}
            placeholder="(123) 456-7890"
          />
          <Form.Text name="email" label="Email" pattern={FormatType.Email} />
          <Form.RadioGroup
            name="here_for"
            label="Who is initiating services?"
            options={[
              { label: 'Self', value: HereFor.Self },
              { label: 'Loved One', value: HereFor.LovedOne },
            ]}
            formOptions={{ required: true }}
          />
          {hereForLovedOne && <LovedOneInfoForm />}
          <Form.Text name="payload.reason" label="Reason for Visit" textArea autoSize={{ minRows: 6, maxRows: 10 }} />
        </Flex>

        <DrawerFooter
          primaryButtonText="Check Eligibility"
          primaryButtonOnClick={onHandleSubmit}
          primaryButtonIsSubmitting={isSubmitting}
          primaryButtonShowArrow
          showBackButton={false}
        />
      </S.FormInnerWrapper>
    </FormContainer>
  );
};

export default PatientInfoForm;
