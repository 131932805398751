import { Button, Flex, Typography } from '@solace-health/ui';

import { AdminTable } from '../../../../components/shared/Table';
import { TraitId, User } from '../../../../types/user';
import { copyToClipBoard, getFullStateName, phoneNumberFormat } from '../../../../utils/general';

import { hasAtLeastOneTrait, hasTrait } from '../../../../utils/user';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useGetPaginatedUsers from '../../../../hooks/users/useGetPaginatedUsers';
import { HighlightText } from '../../../../shared/components/HighlightText';
import { useQuery } from '../../../../hooks/useQuery';

type AllUsersTableType = User & { location: string; userType: 'Patient' | 'Client' | 'Advocate' | 'ICA' | 'Clinician' };

const getUserType = (user: User) => {
  if (hasTrait({ user, traitId: TraitId.CommunityHealthAdvocate })) return 'ICA';
  if (hasTrait({ user, traitId: TraitId.CommunityHealthPhysician })) return 'Clinician';
  if (hasTrait({ user, traitId: TraitId.CommunityHealthPatient })) return 'Patient';
  if (hasTrait({ user, traitId: TraitId.CommunityHealthLovedOne })) return 'Loved One';
  if (user.has_advocate_profile) return 'Advocate';
  return 'Client';
};

// TODO: text filtering
export default function AllUsersPage() {
  const { data, pagination, setQueryParams, searchParams, setSearch, isLoading } = useGetPaginatedUsers({
    include: ['address', 'traits'],
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const query = useQuery();
  const search = query.get('search');

  const handleCopyToClipboard = (text: string) => {
    copyToClipBoard({ text });
    enqueueSnackbar(`Successfully copied ${text} to clipboard`, { variant: 'success' });
  };

  const handleOnRowClick = (user: AllUsersTableType) => {
    if (hasAtLeastOneTrait({ user, traitIds: [TraitId.CommunityHealthPatient, TraitId.CommunityHealthLovedOne] })) {
      navigate(`/Patients/${user?.id}`);
      return;
    }

    if (user.has_advocate_profile) {
      navigate(`/Advocates/${user?.id}`);
      return;
    }

    navigate(`/Users/${user?.id}`);
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'internal_id',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      render: (text: string) => <HighlightText text={text} searchTerm={search} />,
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (email: string) => (
        <Button.Link
          onClick={(e: any) => {
            e.stopPropagation();
            handleCopyToClipboard(email);
          }}
          color="#285E50"
          style={{ maxWidth: 260 }}
        >
          <HighlightText text={email} searchTerm={search} />
        </Button.Link>
      ),
      sorter: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      render: (phone: string) => (
        <Button.Link
          onClick={(e: any) => {
            e.stopPropagation();
            handleCopyToClipboard(phone);
          }}
          color="#285E50"
        >
          <HighlightText text={phoneNumberFormat(phone)} searchTerm={search} />
        </Button.Link>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
    },
  ];

  const formattedData = data.map((user) => ({
    ...user,
    location: getFullStateName(user.address?.state),
    key: user.sharetribe_uuid,
    fullName: `${user.first_name} ${user.last_name}`,
    userType: getUserType(user),
  }));

  return (
    <Flex vertical gap="1rem">
      <Typography.Display>All Users</Typography.Display>

      <AdminTable
        showPagination={true}
        pagination={pagination}
        onChange={setQueryParams}
        columns={columns}
        data={formattedData}
        loading={isLoading}
        onRowClick={handleOnRowClick}
        onTextFilter={setSearch}
        defaultSearch={searchParams.search}
      />
    </Flex>
  );
}
