import { Flex, styled } from '@solace-health/ui';

export const EligibleStatusCard = styled(Flex)`
  border-radius: var(--Radius-Small, 0.5rem);
  border: 1px solid var(--border-color-dark-green, #285e50);
  background: var(--green-200, #e9f0ee);
  padding: 1rem;
  box-shadow:
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
`;
