import useSWR from 'swr';
import { User } from '../types/user';
import fetcher from './fetcher';
import qs from 'qs';

type GetTimeSpansProps = {
  advocateId?: User['id'];
  includes: string[];
  search?: string | null;
};

export default function useGetTimeSpans({ advocateId = undefined, includes, search = null }: GetTimeSpansProps) {
  const query = qs.stringify({
    filter: { 'advocate_patient.advocate_id': advocateId, notNull: 'submitted_dt' },
    includes,
    search,
  });
  return useSWR(`/v1/api/time_span?${query}`, fetcher);
}
