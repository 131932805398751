import { Flex, Form, FormatType, Typography, useForm } from '@solace-health/ui';
import { useEffect, useState } from 'react';
import { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import usePayors from '../../../../../hooks/usePayors';
import { InsuranceProgramId, Prospect } from '../../../../../types/prospect';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { FormContainer } from '../../shared/FormContainer/FormContainer';
import { WarningCard } from '../../shared/WarningCard/WarningCard';
import { AddPatientEventContext } from '../../stateMachine';

type ConfirmMemberIdFormValues = {
  payor_id: string;
  medicare_number: string;
};

type ConfirmMemberIdProps = {
  prospect: Prospect;
  upsertProspect: (data: UpsertProspectDto) => Promise<{ data: Prospect }>;
  refreshProspect: () => Promise<Prospect | undefined>;
  onNextStep: (context?: AddPatientEventContext) => void;
  onLastStep: () => void;
};

export const ConfirmMemberId = ({
  prospect,
  upsertProspect,
  refreshProspect,
  onNextStep,
  onLastStep,
}: ConfirmMemberIdProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<ConfirmMemberIdFormValues>({
    defaultValues: {
      payor_id: '',
      medicare_number: '',
    },
  });

  const payorId = form.watch('payor_id');

  const { payors, isLoading: isLoadingPayors } = usePayors({});

  // Initializes form state with prospect data
  useEffect(() => {
    form.reset({
      payor_id: prospect.payload.payor_id || '',
      medicare_number: prospect.payload.medicare_number || '',
    });
  }, [prospect]);

  const isMedicare = (() => {
    if (!payorId) return false;

    const selectedPayor = payors.find((payor) => payor.id === payorId);
    if (!selectedPayor) return false;

    return !selectedPayor.insurance_company_id && selectedPayor.insurance_program_id === InsuranceProgramId.Medicare;
  })();

  const handleOnSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    setIsSubmitting(true);
    const formValues = form.getValues();

    const data: UpsertProspectDto = {
      payload: {
        medicare_number: formValues.medicare_number || '',
        payor_id: formValues.payor_id || '',
      },
    };

    await upsertProspect({ id: prospect.id, ...data });
    await refreshProspect();

    setIsSubmitting(false);

    onNextStep();
  };

  return (
    <FormContainer formMethods={form} onSubmit={handleOnSubmit}>
      <Flex vertical gap="1.5rem">
        <WarningCard
          title="Let's collect some more details."
          content="More information is needed to find the patient."
          bgColor="#f4e5ca"
          secondaryColor="#D7A13B"
        />

        <Form.SelectMenu
          name="payor_id"
          label="Payor"
          options={payors.map((payor) => ({
            label: `${payor.name} (${payor.state})`,
            value: payor.id,
          }))}
          loading={isLoadingPayors}
          formOptions={{ required: true }}
          onChange={() => {
            form.resetField('medicare_number');
          }}
        />

        <Form.Text
          name="medicare_number"
          label={isMedicare ? 'MBI' : 'Member ID'}
          formOptions={{
            required: true,
            ...(isMedicare
              ? {
                  maxLength: { value: 11, message: 'Must be 11 characters' },
                  minLength: { value: 11, message: 'Must be 11 characters' },
                }
              : // Need to set some values below otherwise above restrictions persist when switching from medicare -> non medicare
                {
                  maxLength: { value: 30, message: 'Invalid member id' },
                  minLength: { value: 1, message: 'Invalid member id' },
                }),
          }}
          format={isMedicare ? FormatType.MBI : undefined}
        />

        <Typography.Body color="#DA4D54">
          Please double check the patient&apos;s Member ID and confirm it&apos;s correct.
        </Typography.Body>
      </Flex>

      <DrawerFooter
        primaryButtonText="Check Eligibility"
        primaryButtonOnClick={handleOnSubmit}
        primaryButtonShowArrow
        backButtonOnClick={onLastStep}
        primaryButtonIsSubmitting={isSubmitting}
      />
    </FormContainer>
  );
};
