import { groupBy } from 'lodash';
import useProspect, { ProspectSearchResult } from '../../../../../hooks/prospects/useProspect';
import { Flex, Size, Typography } from '@solace-health/ui';
import { BookingHelpRequestType } from '../../../../../hooks/prospects/useGetBookingHelpRequests';
import { Prospect } from '../../../../../types/prospect';
import { AddPatientEventContext } from '../../stateMachine';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { UserCard } from '../../shared/UserCard/UserCard';
import { SolaceAPI } from '../../../../../utils/api';
import { User } from '../../../../../types/user';
import { WarningCard } from '../../shared/WarningCard/WarningCard';

enum DuplicateType {
  Prospect = 'Prospect',
  ProviderReferral = 'Referral',
}

const duplicateType = (possibleDuplicate: ProspectSearchResult) => {
  return possibleDuplicate.booking_help_request_type === BookingHelpRequestType.PatientReferral
    ? DuplicateType.ProviderReferral
    : DuplicateType.Prospect;
};

const getExistingUserForEmail = async (email: string) => {
  const { data: user } = await SolaceAPI.get<User>({
    path: '/api/admin/users/existing',
    params: { email },
  });

  return user;
};

export const PossibleDuplicate = ({
  possibleDuplicates,
  prospect,
  onNextStep,
  onLastStep,
}: {
  possibleDuplicates: ProspectSearchResult[];
  prospect: Prospect;
  onNextStep: (context?: AddPatientEventContext) => void;
  onLastStep: () => void;
}) => {
  const { upsertProspect } = useProspect();

  const groupedByType = groupBy(possibleDuplicates, duplicateType);
  const hasProspects = !!groupedByType[DuplicateType.Prospect];
  const hasProviderReferrals = !!groupedByType[DuplicateType.ProviderReferral];

  const onMergeAndContinue = async (prospectId: string) => {
    const { data: oldUpdatedProspect } = await upsertProspect({
      id: prospectId,
      first_name: prospect.first_name,
      last_name: prospect.last_name,
      state: prospect.state,
      phone: prospect.phone,
      email: prospect.email,
      loved_one_name: prospect.loved_one_name,
      loved_one_relationship: prospect.loved_one_relationship,
      here_for: prospect.here_for,
      payload: { ...prospect.payload, consent_check: null },
    });

    if (oldUpdatedProspect.email) {
      const existingUser = await getExistingUserForEmail(oldUpdatedProspect.email);

      if (existingUser) {
        onNextStep({ existingUserId: existingUser.id });
        return;
      }
    }

    onNextStep({ prospectId });
  };

  return (
    <>
      <Flex vertical gap="1.5rem">
        <WarningCard
          title="Are these the same patient?"
          content="The results below look similar to the patient."
          bgColor="#f4e5ca"
          secondaryColor="#D7A13B"
        />
        <Flex vertical gap="2rem">
          {hasProspects && (
            <>
              <Typography.Body size={Size.XL} bold>
                Prospects
              </Typography.Body>
              {groupedByType[DuplicateType.Prospect].map((possibleDuplicate) => (
                <UserCard
                  fullName={`${possibleDuplicate.first_name} ${possibleDuplicate.last_name}`}
                  userType={DuplicateType.Prospect}
                  phone={possibleDuplicate.phone}
                  email={possibleDuplicate.email}
                  onClickCta={() => onMergeAndContinue(possibleDuplicate.prospect_id)}
                  ctaText="Merge & Continue"
                />
              ))}
            </>
          )}
          {hasProviderReferrals && (
            <>
              <Typography.Body size={Size.XL} bold>
                Referrals
              </Typography.Body>
              {groupedByType[DuplicateType.ProviderReferral].map((possibleDuplicate) => (
                <UserCard
                  fullName={`${possibleDuplicate.first_name} ${possibleDuplicate.last_name}`}
                  userType={DuplicateType.ProviderReferral}
                  phone={possibleDuplicate.phone}
                  email={possibleDuplicate.email}
                  onClickCta={() => onMergeAndContinue(possibleDuplicate.prospect_id)}
                  ctaText="Merge & Continue"
                />
              ))}
            </>
          )}
        </Flex>
      </Flex>
      <DrawerFooter
        primaryButtonText="Check Eligibility"
        showBackButton
        primaryButtonShowArrow
        primaryButtonOnClick={onNextStep}
        backButtonOnClick={onLastStep}
      />
    </>
  );
};
