import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Flex, Form, FormatType, Typography, useForm } from '@solace-health/ui';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import usePatient from '../../../../../hooks/usePatient';
import { Referral } from '../../../../../types/referrals';
import { phoneNumberFormat } from '../../../../../utils/general';
import { PatientSchema, UserSchema } from './schema';
import * as S from './style';

export type PatientInfoSectionProps = {
  patient: GetUserResponse<{ referral: Referral }>;
  isLovedOne?: boolean;
  refresh: () => void;
};

export default function PatientInfoSection({ patient, refresh, isLovedOne = false }: PatientInfoSectionProps) {
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const { updatePatient } = usePatient();

  const userSchema = isLovedOne ? UserSchema : PatientSchema;
  type FormUserSchemaType = z.infer<typeof userSchema>;
  const formMethods = useForm<FormUserSchemaType>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      patient_sex: '',
      patient_dob: '',
      address: {},
    },
    reValidateMode: 'onChange',
  });

  const {
    formState: { dirtyFields, errors },
    reset,
  } = formMethods;

  useEffect(() => {
    reset({
      first_name: patient.first_name,
      last_name: patient.last_name,
      email: patient.email,
      phone: phoneNumberFormat(patient.phone),
      patient_sex: patient.referral?.sex,
      patient_dob: patient.dob ? dayjs.utc(patient?.dob).format('MM/DD/YYYY') : '',
      ...(patient?.address ? { address: patient.address } : {}),
    });
  }, [patient, reset]);

  const handleSaveChanges = async (formData: FormUserSchemaType) => {
    setIsSavingChanges(true);

    const { first_name, last_name, email, phone, patient_sex, patient_dob, address } = formData;

    // Create an object to store only changed fields
    const updatedFields: Partial<typeof formData> = {};

    if (patient.first_name !== first_name) updatedFields.first_name = first_name;
    if (patient.last_name !== last_name) updatedFields.last_name = last_name;
    if (patient.email !== email) updatedFields.email = email;
    if (phoneNumberFormat(patient.phone) !== phone) updatedFields.phone = phone;
    if (patient_dob) updatedFields.patient_dob = patient_dob;
    if (patient_sex) updatedFields.patient_sex = patient_sex;

    // Check if any address fields have changed
    if (
      address &&
      (patient.address?.street !== address.street ||
        patient.address?.city !== address.city ||
        patient.address?.state !== address.state ||
        patient.address?.zip !== address.zip ||
        patient.address?.unit !== address.unit)
    ) {
      updatedFields.address = address;
    }

    try {
      // Only make the API call if there are changes
      if (Object.keys(updatedFields).length > 0) {
        await updatePatient(patient.id, updatedFields);
        refresh();
      }

      const referral = patient?.referral;

      if (referral) {
        refresh();
        reset({ ...formData });
      }
    } finally {
      setIsSavingChanges(false);
    }
  };

  const valuesHaveChanges = Object.keys(dirtyFields).length > 0;

  return (
    <Form.Container formMethods={formMethods} onSubmit={handleSaveChanges}>
      <S.Container>
        <Typography.Header>{isLovedOne ? 'Loved One Information' : 'Patient Information'}</Typography.Header>
        <S.FormWrapper>
          <Flex justify="space-between" gap="1rem">
            <Form.TextInput name="first_name" label="First Name" formOptions={{ required: true }} />
            <Form.TextInput name="last_name" label="Last Name" formOptions={{ required: true }} />
          </Flex>
          {!isLovedOne && (
            <>
              <Form.TextInput name="patient_dob" label="Date of Birth" formOptions={{ required: true }} />
              <Form.RadioGroup
                name="patient_sex"
                label="Sex"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                formOptions={{
                  value: patient.referral?.sex,
                }}
              />
              <Flex vertical gap={8}>
                <Form.LocationSelect
                  api_key={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
                  label="Home Address"
                  placeholder="Address Line 1"
                  locationType="full"
                  streetFieldName="address.street"
                  cityFieldName="address.city"
                  stateFieldName="address.state"
                  zipFieldName="address.zip"
                  formOptions={{ required: true }}
                  hasError={!!errors.address}
                />
                <Flex gap={8}>
                  <S.Address name="address.city" placeholder="City" readOnly />
                  <S.Address name="address.state" placeholder="State" readOnly />
                  <S.Address name="address.zip" placeholder="Zip" readOnly />
                </Flex>
              </Flex>
              <Divider />
            </>
          )}
          <Typography.Header>Contact Information</Typography.Header>
          <Form.TextInput name="email" label="Email" formOptions={{ required: true }} />
          <Form.TextInput name="phone" label="Phone" format={FormatType.Phone} formOptions={{ required: true }} />
        </S.FormWrapper>
      </S.Container>

      <S.StickyButtonContainer show={valuesHaveChanges}>
        <Form.Submit label="Save Changes" isSubmitting={isSavingChanges} />
      </S.StickyButtonContainer>
    </Form.Container>
  );
}
