import { Flex, Icons, Typography } from '@solace-health/ui';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';

export const AddedToWaitingList = ({ subText, onClose }: { subText: string; onClose: () => void }) => {
  return (
    <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
      <Flex vertical gap="1.5rem" align="center">
        <Flex vertical gap="0.5rem" align="center">
          <Typography.Display>Success</Typography.Display>
          <Typography.Body color="#555" center>
            {subText}
          </Typography.Body>
        </Flex>
        <Icons.CircleCheck color="#347866" size={190} />
      </Flex>
      <DrawerFooter primaryButtonText="Close" primaryButtonOnClick={onClose} showBackButton={false} />
    </Flex>
  );
};
