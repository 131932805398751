import { ProspectSearchResult } from '../../../hooks/prospects/useProspect';
import { User } from '../../../types/user';
import { assign, createMachine } from 'xstate';
import { BadEligibilityCheckRequestInvalidField } from '../../../utils/errors';
import { isEmpty } from 'lodash';
import { Prospect } from '../../../types/prospect';

export enum AddPatientState {
  Initialization = 'initialization',

  PreviousStep = 'previousStep',

  PatientInfo = 'patientInfo',

  PossibleDuplicateFound = 'possibleDuplicateFound',
  EmailAlreadyInUse = 'emailAlreadyInUse',

  CheckingCoverage = 'checkingCoverage',

  NoMbiFoundConfirmNameAndDob = 'noMbiFoundConfirmNameAndDob',
  NoMbiFoundEnterPayorAndMbi = 'noMbiFoundEnterPayorAndMbi',

  NoPhysiciansAvailable = 'noPhysiciansAvailable',
  SuccessfullyAddedToWaitlist = 'successfullyAddedToWaitlist',

  EligibilityStatusFailed = 'eligibilityStatusFailed',
  EligibilityStatusIneligible = 'eligibilityStatusIneligible',
  EligibilityStatusEligible = 'eligibilityStatusEligible',

  Schedule = 'schedule',

  CheckAnotherPolicy = 'checkAnotherPolicy',
}

export enum AddPatientEvent {
  Next = 'next',
  Back = 'back',
  Reset = 'reset',
  SetProspectId = 'setProspectId',
  SetUserId = 'setUserId',
}

export type AddPatientContext = {
  waitlistMessage: string | undefined;
  isEligible: boolean;
  invalidField: BadEligibilityCheckRequestInvalidField | undefined;
  failedAttempts: number;
  possibleDuplicateProspects: ProspectSearchResult[];
  existingUserId: User['id'] | undefined;
  prospectId: Prospect['id'] | undefined;
  userId: User['id'] | undefined;
};

export type AddPatientEventContext = Partial<AddPatientContext> & {
  nameOrDobUpdated?: boolean;
  noPhysiciansAvailableForPayor?: boolean;
  noPhysiciansAvailable?: boolean;
};

export enum AddPatientAction {
  ResetContext = 'resetContext',
  IncrementFailedAttempts = 'incrementFailedAttempts',
  ResetFailedAttempts = 'resetFailedAttempts',
  SetExistingUserId = 'setExistingUserId',
  SetUserId = 'setUserId',
  SetInvalidField = 'setInvalidField',
}

enum AddPatientGuard {
  EligibilityCheckEligible = 'eligibilityCheckEligible',
  EligibilityCheckIneligible = 'eligibilityCheckIneligible',
  EligibilityCheckFailedFirstAttempt = 'eligibilityCheckFailedFirstAttempt',
  EligibilityCheckFailedSecondAttempt = 'eligibilityCheckFailedSecondAttempt',
  UserExistsForEmail = 'userExistsForEmail',
  NoPhysiciansAvailable = 'noPhysiciansAvailable',
  HasPossibleDuplicateProspects = 'hasPossibleDuplicateProspects',
  EligibilityCheckFailedNameOrDobUpdated = 'eligibilityCheckFailedNameOrDobUpdated',
  AddedToWaitlist = 'addedToWaitlist',
  InitializedWithUser = 'initializedWithUser',
}

const initialContext: Required<AddPatientContext> = {
  waitlistMessage: undefined,
  isEligible: false,
  invalidField: undefined,
  failedAttempts: 0,
  possibleDuplicateProspects: [],
  existingUserId: undefined,
  prospectId: undefined,
  /** The newly created userId, or the userId for adding new insurance */
  userId: undefined,
};

export const stateMachine = createMachine(
  {
    id: 'addPatient',
    initial: AddPatientState.Initialization,
    on: {
      [AddPatientEvent.Reset]: [{ target: `.${AddPatientState.Initialization}`, actions: AddPatientAction.ResetContext }],
      [AddPatientEvent.SetProspectId]: {
        actions: assign({
          prospectId: ({ event }) => event.context?.prospectId,
        }),
      },
      [AddPatientEvent.SetUserId]: {
        actions: assign({
          userId: ({ event }) => event.context?.userId,
        }),
      },
    },
    types: {
      events: {} as { type: AddPatientEvent; context?: AddPatientEventContext },
      context: {} as AddPatientContext,
    },
    context: initialContext,
    states: {
      [AddPatientState.Initialization]: {
        on: {
          [AddPatientEvent.Next]: [
            { guard: AddPatientGuard.InitializedWithUser, target: AddPatientState.CheckAnotherPolicy },
            { target: AddPatientState.PatientInfo },
          ],
        },
      },
      [AddPatientState.PatientInfo]: {
        on: {
          [AddPatientEvent.Next]: [
            {
              guard: AddPatientGuard.NoPhysiciansAvailable,
              target: AddPatientState.NoPhysiciansAvailable,
            },
            {
              guard: AddPatientGuard.UserExistsForEmail,
              target: AddPatientState.EmailAlreadyInUse,
              actions: AddPatientAction.SetExistingUserId,
            },
            {
              guard: AddPatientGuard.HasPossibleDuplicateProspects,
              target: AddPatientState.PossibleDuplicateFound,
              actions: assign({
                possibleDuplicateProspects: ({ event }) =>
                  event.context?.possibleDuplicateProspects as ProspectSearchResult[],
              }),
            },
            { target: AddPatientState.CheckingCoverage },
          ],
        },
      },
      [AddPatientState.PossibleDuplicateFound]: {
        on: {
          [AddPatientEvent.Next]: [
            {
              guard: AddPatientGuard.UserExistsForEmail,
              target: AddPatientState.EmailAlreadyInUse,
              actions: AddPatientAction.SetExistingUserId,
            },
            {
              target: AddPatientState.CheckingCoverage,
              actions: assign({
                prospectId: ({ event, context }) =>
                  event.context?.prospectId ? event.context?.prospectId : context.prospectId,
              }),
            },
          ],
          [AddPatientEvent.Back]: {
            target: AddPatientState.PreviousStep,
          },
        },
        exit: assign({
          possibleDuplicateProspects: [],
        }),
      },
      [AddPatientState.EmailAlreadyInUse]: {
        on: {
          [AddPatientEvent.Back]: {
            target: AddPatientState.PreviousStep,
          },
        },
      },
      [AddPatientState.CheckingCoverage]: {
        on: {
          [AddPatientEvent.Next]: [
            {
              target: AddPatientState.EligibilityStatusEligible,
              guard: AddPatientGuard.EligibilityCheckEligible,
              actions: AddPatientAction.SetUserId,
            },
            {
              target: AddPatientState.EligibilityStatusIneligible,
              guard: AddPatientGuard.EligibilityCheckIneligible,
            },
            {
              target: AddPatientState.NoMbiFoundConfirmNameAndDob,
              guard: AddPatientGuard.EligibilityCheckFailedFirstAttempt,
              actions: AddPatientAction.IncrementFailedAttempts,
            },
            {
              target: AddPatientState.NoMbiFoundEnterPayorAndMbi,
              guard: AddPatientGuard.EligibilityCheckFailedSecondAttempt,
              actions: AddPatientAction.IncrementFailedAttempts,
            },
            {
              target: AddPatientState.EligibilityStatusFailed,
              // Either invalid field or existing user id could be undefined
              actions: [AddPatientAction.SetInvalidField, AddPatientAction.SetExistingUserId],
            },
          ],
          [AddPatientEvent.Back]: { target: AddPatientState.PreviousStep },
        },
      },
      [AddPatientState.NoMbiFoundConfirmNameAndDob]: {
        on: {
          [AddPatientEvent.Next]: [
            {
              target: AddPatientState.CheckingCoverage,
              guard: AddPatientGuard.EligibilityCheckFailedNameOrDobUpdated,
            },
            {
              target: AddPatientState.NoMbiFoundEnterPayorAndMbi,
            },
          ],
          // Reset failed attempts when using back button, since they could update patient info
          [AddPatientEvent.Back]: [
            {
              guard: AddPatientGuard.InitializedWithUser,
              target: AddPatientState.CheckAnotherPolicy,
              actions: AddPatientAction.ResetFailedAttempts,
            },
            { target: AddPatientState.PatientInfo, actions: AddPatientAction.ResetFailedAttempts },
          ],
        },
      },
      [AddPatientState.NoMbiFoundEnterPayorAndMbi]: {
        on: {
          [AddPatientEvent.Next]: { target: AddPatientState.CheckingCoverage },
          [AddPatientEvent.Back]: { target: AddPatientState.NoMbiFoundConfirmNameAndDob },
        },
      },
      [AddPatientState.NoPhysiciansAvailable]: {
        on: {
          [AddPatientEvent.Next]: { target: AddPatientState.SuccessfullyAddedToWaitlist },
          [AddPatientEvent.Back]: { target: AddPatientState.PreviousStep },
        },
      },
      [AddPatientState.SuccessfullyAddedToWaitlist]: {
        entry: assign({
          // Falls back to current context incase someone goes back to this state
          waitlistMessage: ({ context, event }) => event.context?.waitlistMessage || context.waitlistMessage,
        }),
      },
      [AddPatientState.EligibilityStatusFailed]: {
        on: {
          [AddPatientEvent.Next]: { target: AddPatientState.CheckingCoverage },
          [AddPatientEvent.Back]: {
            target: AddPatientState.PatientInfo,
          },
        },
      },
      [AddPatientState.EligibilityStatusIneligible]: {
        entry: AddPatientAction.ResetFailedAttempts,
        on: {
          [AddPatientEvent.Next]: [
            { guard: AddPatientGuard.AddedToWaitlist, target: AddPatientState.SuccessfullyAddedToWaitlist },
            { target: AddPatientState.CheckAnotherPolicy },
          ],
        },
      },
      [AddPatientState.EligibilityStatusEligible]: {
        entry: AddPatientAction.ResetFailedAttempts,
        on: {
          [AddPatientEvent.Next]: { target: AddPatientState.Schedule },
        },
      },
      [AddPatientState.Schedule]: {},
      [AddPatientState.CheckAnotherPolicy]: {
        entry: AddPatientAction.ResetFailedAttempts,
        on: {
          [AddPatientEvent.Next]: { target: AddPatientState.CheckingCoverage },
          [AddPatientEvent.Back]: {
            target: AddPatientState.EligibilityStatusIneligible,
          },
        },
      },
      // Target this state to return to previous state
      [AddPatientState.PreviousStep]: { type: 'history' },
    },
  },
  {
    actions: {
      [AddPatientAction.SetExistingUserId]: assign({
        existingUserId: ({ event }) => event.context?.existingUserId as User['id'],
      }),
      [AddPatientAction.ResetContext]: assign(initialContext),
      [AddPatientAction.IncrementFailedAttempts]: assign({
        failedAttempts: ({ context }) => context.failedAttempts + 1,
      }),
      [AddPatientAction.ResetFailedAttempts]: assign({
        failedAttempts: 0,
      }),
      [AddPatientAction.SetUserId]: assign({
        userId: ({ event }) => event.context?.userId,
      }),
      [AddPatientAction.SetInvalidField]: assign({
        invalidField: ({ event }) => event.context?.invalidField,
      }),
    },
    guards: {
      [AddPatientGuard.EligibilityCheckEligible]: ({ event }) => event.context?.isEligible === true,
      [AddPatientGuard.EligibilityCheckIneligible]: ({ event }) => event.context?.isEligible === false,
      [AddPatientGuard.EligibilityCheckFailedFirstAttempt]: ({ context, event }) =>
        event.context?.invalidField === BadEligibilityCheckRequestInvalidField.MemberId && context?.failedAttempts === 0,
      [AddPatientGuard.EligibilityCheckFailedSecondAttempt]: ({ context, event }) =>
        event.context?.invalidField === BadEligibilityCheckRequestInvalidField.MemberId && context?.failedAttempts === 1,
      [AddPatientGuard.HasPossibleDuplicateProspects]: ({ event }) => !isEmpty(event.context?.possibleDuplicateProspects),
      [AddPatientGuard.UserExistsForEmail]: ({ event }) => !!event.context?.existingUserId,
      [AddPatientGuard.NoPhysiciansAvailable]: ({ event }) =>
        event.context?.noPhysiciansAvailable === true || event.context?.noPhysiciansAvailableForPayor === true,
      [AddPatientGuard.EligibilityCheckFailedNameOrDobUpdated]: ({ event }) => event.context?.nameOrDobUpdated === true,
      [AddPatientGuard.AddedToWaitlist]: ({ event }) => !!event.context?.waitlistMessage,
      [AddPatientGuard.InitializedWithUser]: ({ context }) => context?.userId !== undefined,
    },
  },
);
