import { UseFormReturn } from '@solace-health/ui';
import * as S from './style';

export const FormContainer = ({
  formMethods = null,
  onSubmit,
  children,
}: {
  formMethods?: UseFormReturn<any> | null;
  onSubmit: (data: any) => void;
  children: React.ReactNode;
}) => (
  <S.StyledFormContainer formMethods={formMethods} onSubmit={onSubmit}>
    {children}
  </S.StyledFormContainer>
);
