import { Avatar, Size, Typography } from '@solace-health/ui';
import { HighlightButton } from './style';
import { CommunityHealthListing } from '../../../../../types/listing';

type Props = {
  listing: CommunityHealthListing;
  selected: boolean;
  onClick: (listingId: string) => void;
};

export const ListingItem = ({ listing, selected, onClick }: Props) => {
  return (
    <HighlightButton selected={selected} onClick={onClick} style={{ justifyContent: 'flex-start', height: '64px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Avatar alt={listing.advocate_name} src={listing.user.profile_image_url || ''} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography.Header size={Size.XS}>{listing.advocate_name}</Typography.Header>
          {listing.user?.address && (
            <Typography.Body>
              {listing.user.address.city}, {listing.user.address.state}
            </Typography.Body>
          )}
        </div>
      </div>
    </HighlightButton>
  );
};
