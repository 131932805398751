import useSWR, { SWRResponse } from 'swr';
import fetcher from './fetcher';
import { useState } from 'react';
import qs from 'qs';
import { QueryParamInputs } from '../components/shared/Table/antdOnChangeToQueryParams';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

export type PaginatedResponse<T> = {
  data: T[];
  meta: {
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
  };
};

type PaginatedResourceHookReturn<T> = Omit<SWRResponse<PaginatedResponse<T>, any, any>, 'data'> & {
  data: T[];
  pagination: {
    current: number;
    total: number;
    pageSize: number;
    defaultPageSize: number;
  };
  setQueryParams: (input: Partial<QueryParamInputs & Record<string, unknown>>) => void;
  resetQueryParams: () => void;
  searchParams: Partial<QueryParamInputs & Record<string, unknown>>;
  setSearch: (value: string) => void;
};

const DEFAULT_QUERY_PARAMS: QueryParamInputs = {
  page: 1,
};

export default function useGetPaginatedResource<DataType>(
  queryKey: string,
  startingQueryParams?: Partial<QueryParamInputs> & Record<string, unknown>,
): PaginatedResourceHookReturn<DataType> {
  const history = useNavigate();
  const location = useLocation();

  const searchParams = qs.parse(location.search.split('?')[1]);

  const [queryParams, setQueryParams] = useState<QueryParamInputs & Record<string, unknown>>({
    page: 1,
    ...searchParams,
    ...startingQueryParams,
  });

  /** If we're managing the filter outside this hook, then we should ignore the internal queryParams.filter */
  const filter = startingQueryParams?.filter ? startingQueryParams.filter : queryParams?.filter;

  const urlParams = qs.stringify({
    ...queryParams,
    include: startingQueryParams?.include,
    filter,
    page: { number: queryParams.page, size: 10 },
  });

  const { data: responseBody, ...rest } = useSWR<PaginatedResponse<DataType>>(`${queryKey}?${urlParams}`, fetcher, {
    keepPreviousData: true,
  });

  const { data, meta } = responseBody ?? {};

  const resetQueryParams = () => {
    setQueryParams(DEFAULT_QUERY_PARAMS);
  };

  const baseSetQueryParams = ({
    prevState,
    newState,
  }: {
    prevState: Partial<QueryParamInputs & Record<string, unknown>>;
    newState: Partial<QueryParamInputs & Record<string, unknown>>;
  }) => {
    const updatedState = { ...prevState, ...newState } as Partial<QueryParamInputs & Record<string, unknown>>;
    const params = qs.stringify(updatedState);
    history({ pathname: location.pathname, search: params }, { replace: true });
    return updatedState;
  };

  const setSearch = (input: string) => {
    setQueryParams((prev) => {
      return baseSetQueryParams({ prevState: prev, newState: { search: input, page: 1 } });
    });
  };

  return {
    data: data ?? [],
    setQueryParams: (newState: Partial<QueryParamInputs & Record<string, unknown>>) => {
      setQueryParams((prevState) => {
        return baseSetQueryParams({ prevState, newState });
      });
    },
    resetQueryParams,
    searchParams,
    setSearch: debounce(setSearch, 300),
    pagination: {
      current: queryParams.page || 1,
      pageSize: meta?.perPage || 0,
      defaultPageSize: meta?.perPage || 10,
      total: meta?.totalItems || 0,
    },
    ...rest,
  };
}
