import { Size, Typography, Button, Flex } from '@solace-health/ui';

import { useState } from 'react';
import { filter, orderBy } from 'lodash';
import NoteItem from './NoteItem/NoteItem';
import { User, UserNote } from '../../../types/user';
import AddNoteModal from './AddNoteModal/AddNoteModal';

interface NotesProps {
  refresh: () => void;
  user: User;
  modalTitle: string;
}

export const Notes = ({ refresh, user, modalTitle }: NotesProps) => {
  const userNotes = filter(user.user_notes, ['deleted', false]);

  const [isOpen, setIsOpen] = useState(false);
  const [currentUserNote, setCurrentUserNote] = useState<UserNote | null>(null);

  const onClick = (userNote: UserNote | null) => {
    setCurrentUserNote(userNote);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentUserNote(null);
  };

  return (
    <>
      <Flex vertical gap="1rem">
        <Flex justify="space-between">
          <Flex align="center" gap="2rem" justify="between">
            <Typography.Header size={Size.LG}>Notes</Typography.Header>
          </Flex>
          <div style={{ width: '12rem' }}>
            <Button.Outline onClick={() => onClick(null)}>Add New Note</Button.Outline>
          </div>
        </Flex>
        {orderBy(userNotes, ['created_at'], ['desc'])?.map((userNote) => (
          <NoteItem key={userNote.id} note={userNote} onEditNote={onClick} refresh={refresh} />
        ))}
      </Flex>
      <AddNoteModal
        isOpen={isOpen}
        handleClose={handleClose}
        existingUserNote={currentUserNote}
        user={user}
        refresh={refresh}
        title={modalTitle}
      />
    </>
  );
};
