import { ContentfulLottieFileSlug, Flex, LottieAnimation, Typography } from '@solace-health/ui';
import { useEffect, useRef } from 'react';
import { useCheckInsuranceEligibility } from '../../../../../hooks/prospects/useCheckInsuranceEligibility';
import { AddPatientEventContext } from '../../stateMachine';
import { ErrorCode, SolaceApiError } from '../../../../../utils/errors';
import { Prospect } from '../../../../../types/prospect';

type CheckingEligibilityProps = {
  prospectId: string | undefined;
  onNextStep: (context?: AddPatientEventContext) => void;
  refreshProspect: () => Promise<Prospect>;
};

export const CheckingEligibility = ({ prospectId, onNextStep, refreshProspect }: CheckingEligibilityProps) => {
  const { checkInsuranceEligibility } = useCheckInsuranceEligibility({ prospectId, shouldFetch: false });
  const isMountedRef = useRef(false);

  // Using ref to fix local dev issue with useEffect running twice
  useEffect(() => {
    if (isMountedRef.current) return;

    isMountedRef.current = true;

    const checkEligibility = async () => {
      await refreshProspect();

      await checkInsuranceEligibility()
        .then(({ data }) => {
          onNextStep({ isEligible: data.is_eligible, userId: data.user_id });
        })
        .catch((error) => {
          const eligibilityCheckError = error as SolaceApiError;

          if (eligibilityCheckError.errorCode === ErrorCode.IncorrectEligibilityInfo) {
            onNextStep({
              invalidField: eligibilityCheckError.invalidField,
              existingUserId: eligibilityCheckError.existingUserId,
            });
          } else {
            onNextStep({ isEligible: false });
          }
        });
    };

    checkEligibility();
  }, [isMountedRef]);

  return (
    <Flex vertical align="center">
      <Flex vertical gap="1rem">
        <Typography.Display center>Checking Patient&apos;s Coverage</Typography.Display>

        <Typography.Body center>
          We&apos;re checking with the patient&apos;s payer. This will take just a few seconds.
        </Typography.Body>
      </Flex>

      <LottieAnimation
        contentfulConfig={{
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
        }}
        width={300}
        height={300}
        loop
        slug={ContentfulLottieFileSlug.LOADING_ACCOUNT}
      />
    </Flex>
  );
};
