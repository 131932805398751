import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import fetcher from './fetcher';
import { Provider } from '../types/providers';
import { SolaceAPI } from '../utils/api';
import { SolaceApiError } from '../utils/errors';

const useProviders = ({ includes }: { includes?: string[] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = qs.stringify({ includes, filter: { notNull: 'physician_id' } });

  const { data, error, mutate } = useSWR<Provider[]>(`/v1/api/providers?${urlParams}`, fetcher);

  const updateProvider = async (
    id: Provider['id'],
    body: Partial<{ locum_provider_id: string | null }>,
  ): Promise<Provider> => {
    try {
      const { data: physicianPayor } = await SolaceAPI.put<Provider>({
        path: `/api/providers/${id}`,
        body,
      });
      mutate();
      return physicianPayor;
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      throw new SolaceApiError(error);
    }
  };

  return {
    providers: data,
    error,
    refresh: mutate,
    updateProvider,
  };
};

export default useProviders;
