export enum States {
  'AL' = 'AL',
  'AK' = 'AK',
  'AS' = 'AS',
  'AZ' = 'AZ',
  'AR' = 'AR',
  'CA' = 'CA',
  'CO' = 'CO',
  'CT' = 'CT',
  'DE' = 'DE',
  'DC' = 'DC',
  'FM' = 'FM',
  'FL' = 'FL',
  'GA' = 'GA',
  'GU' = 'GU',
  'HI' = 'HI',
  'ID' = 'ID',
  'IL' = 'IL',
  'IN' = 'IN',
  'IA' = 'IA',
  'KS' = 'KS',
  'KY' = 'KY',
  'LA' = 'LA',
  'ME' = 'ME',
  'MH' = 'MH',
  'MD' = 'MD',
  'MA' = 'MA',
  'MI' = 'MI',
  'MN' = 'MN',
  'MS' = 'MS',
  'MO' = 'MO',
  'MT' = 'MT',
  'NE' = 'NE',
  'NV' = 'NV',
  'NH' = 'NH',
  'NJ' = 'NJ',
  'NM' = 'NM',
  'NY' = 'NY',
  'NC' = 'NC',
  'ND' = 'ND',
  'MP' = 'MP',
  'OH' = 'OH',
  'OK' = 'OK',
  'OR' = 'OR',
  'PW' = 'PW',
  'PA' = 'PA',
  'PR' = 'PR',
  'RI' = 'RI',
  'SC' = 'SC',
  'SD' = 'SD',
  'TN' = 'TN',
  'TX' = 'TX',
  'UT' = 'UT',
  'VT' = 'VT',
  'VI' = 'VI',
  'VA' = 'VA',
  'WA' = 'WA',
  'WV' = 'WV',
  'WI' = 'WI',
  'WY' = 'WY',
}

export const USA_STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export enum UsaState {
  Al = 'AL',
  Ak = 'AK',
  As = 'AS',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fm = 'FM',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Mh = 'MH',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Mp = 'MP',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pw = 'PW',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Vi = 'VI',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}
