import { Flex, styled } from '@solace-health/ui';

export const UserCardContainer = styled(Flex)`
  border-radius: var(--Radius-Small, 0.5rem);
  border: 1px solid var(--border-color-light-green, #afc8bf);
  background: var(--surface-primary, #fff);
  padding: 1rem;
  box-shadow:
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
`;
