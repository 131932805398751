import { Button, styled } from '@solace-health/ui';

export const StyledButton = styled(Button.Primary)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  max-width: 260px;

  path {
    fill: #fff;
  }
`;

export const NameButton = styled(Button.Link)`
  color: var(--green-700);
  text-decoration: none;

  &:hover {
    color: var(--green-700);
    text-decoration: underline;
  }
`;
