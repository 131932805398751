import qs from 'qs';
import useSWR from 'swr';
import fetcher from '../fetcher';

export enum AccountSetupUrlReason {
  TokenIsInvalid = 'token_is_invalid',
}

export const useAccountSetupUrl = ({ userId }: { userId: string }) => {
  const urlParams = qs.stringify({ filter: { user_id: userId } });
  const { data, isLoading } = useSWR<{ url: string; reason?: AccountSetupUrlReason }>(
    userId ? `/v1/api/account_setup_url?${urlParams}` : null,
    fetcher,
  );

  return {
    data,
    isLoading,
  };
};
