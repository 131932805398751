import { Referral, ReferralEligibilityCheck } from '../../types/referrals';
import useGetPaginatedResource from '../useGetPaginatedResource';

type ReferralEligibilityChecksApiResponse = ReferralEligibilityCheck & {
  referral: Referral & {
    user?: { first_name?: string; last_name?: string };
    prospect?: { first_name?: string; last_name?: string };
  };
};

const useEligibilityChecks = () => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<ReferralEligibilityChecksApiResponse>(
    '/v1/api/referrals/eligibility_checks',
    {
      include: ['referral.prospect', 'referral.user'],
      sort: ['-submitted_dt'],
    },
  );

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};

export default useEligibilityChecks;
