import qs from 'qs';
import useSWR from 'swr';
import { Payor } from '../types/payor';
import fetcher from './fetcher';

type Props = {
  include?: ('insurance_company' | 'physicians' | 'physicians.listings')[];
  filter?: {
    state?: string;
    is_enabled?: boolean;
    notNull?: Record<string, string | string[]>;
  };
  shouldFetch?: boolean;
};

const usePayors = ({ include = [], filter = {}, shouldFetch = true }: Props = {}) => {
  const urlParams = qs.stringify({ include, filter });
  const { data, error, mutate, isLoading } = useSWR<Payor[]>(shouldFetch ? `/v1/api/payors?${urlParams}` : null, fetcher);

  return {
    payors: data || [],
    error,
    isLoading,
    refresh: mutate,
  };
};

export default usePayors;
