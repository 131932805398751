import styled from '@emotion/styled';
import { Drawer, Flex } from '@solace-health/ui';

export const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }
`;

export const DrawerInnerWrapper = styled(Flex, {
  shouldForwardProp: (prop) => !['hideFooter', 'extendedFooter'].includes(prop),
})<{
  hideFooter?: boolean;
  extendedFooter?: boolean;
}>`
  /* The - 7rem accounts for the footer section, 11 accounts for stacked content in the footer  */
  height: calc(100% - ${({ hideFooter, extendedFooter }) => (hideFooter ? 0 : extendedFooter ? 11 : 7)}rem);
`;

export const DrawerContentWrapper = styled(Flex)`
  padding: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

export const PatientCard = styled(Flex)`
  padding: 1rem;
  border-radius: var(--border-radius-lg, 0.5rem);
  border: 1px solid #afc8bf;
  background: var(--green-100, #fff);

  box-shadow:
    0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);
`;
