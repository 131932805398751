import { styled } from '@solace-health/ui';

const Span = styled.span`
  background-color: var(--green-400);
`;

/**
 * Renders the text with the search term highlighted in yellow
 * @param text - The text to render
 * @param searchTerm - The search term to highlight
 * @returns highlighted text in JSX
 */
export const HighlightText = ({ text, searchTerm }: { text?: string; searchTerm?: string | null }) => {
  if (!text) {
    return null;
  }
  if (!searchTerm) {
    return <>{text}</>;
  }
  const escapedSearch = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearch})`, 'gi');
  const parts = text.split(regex);
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? <Span key={`user-search-${index}`}>{part}</Span> : part,
      )}
    </>
  );
};
