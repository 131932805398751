import { Flex, Form, Typography, useForm } from '@solace-health/ui';
import { useSnackbar } from 'notistack';

import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { User } from '../../../../../../types/user';
import useGetListing from '../../../../../../hooks/useGetListing';
import { useEffect } from 'react';

type Props = {
  advocate: User;
};

export const SetMaxPatientHoursPerWeek = ({ advocate }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm<{ max_patient_hours_per_week: number | null }>({
    defaultValues: {
      max_patient_hours_per_week: null,
    },
  });

  const {
    data: { listing },
  } = useGetListing({ id: advocate?.sharetribe_default_listing_uuid || '' });

  useEffect(() => {
    if (!listing?.availability_setting) return;

    formMethods.setValue('max_patient_hours_per_week', listing.availability_setting.max_patient_hours_per_week);
  }, [listing]);

  const onSubmit = async (values: { max_patient_hours_per_week: number }) => {
    try {
      await SolaceAPI.post({
        path: `/api/availability_settings`,
        body: {
          user_id: advocate.id,
          max_patient_hours_per_week: values.max_patient_hours_per_week,
        },
      });
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to set max patient hours per week - [${error.requestId}]`, { variant: 'error' });
      return;
    }

    enqueueSnackbar(`Successfully set max patient hours per week`, { variant: 'success' });
  };

  return (
    <Form.Container onSubmit={onSubmit} formMethods={formMethods}>
      <Flex vertical gap="1rem" style={{ minWidth: '1200px', width: '100%' }}>
        <Typography.Header>Max Patient Hours Per Week</Typography.Header>
        <Flex gap="1rem" style={{ width: '100%' }}>
          <Form.Number name="max_patient_hours_per_week" />
          <Form.Submit label="Save" />
        </Flex>
      </Flex>
    </Form.Container>
  );
};
