import qs from 'qs';
import { useSnackbar } from 'notistack';
import useSWR from 'swr';
import fetcher from './fetcher';
import { User } from '../types/user';
import { PhysicianPayor } from '../types/physcianPayor';
import { SolaceApiError } from '../utils/errors';
import { SolaceAPI } from '../utils/api';
import { Payor } from '../types/payor';

type Props = {
  include?: ('payor.insurance_company' | 'physician')[];
  filter?: {
    physician_id?: User['id'];
  };
};

export type UpsertPhysicianPayorDto = {
  physician_id: User['id'];
  payor_id: Payor['id'];
  is_locum?: boolean;
};

export type DeletePhysicianPayorDto = {
  physician_id: User['id'];
  payor_id: Payor['id'];
};

const usePhysicianPayors = (jsonApiQuery: Props = {}) => {
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = qs.stringify(jsonApiQuery);
  const { data, error, mutate, isLoading } = useSWR<PhysicianPayor[]>(`/v1/api/physician_payors?${urlParams}`, fetcher);

  const upsertPhysicianPayor = async (body: Partial<UpsertPhysicianPayorDto>): Promise<PhysicianPayor> => {
    try {
      const { data: physicianPayor } = await SolaceAPI.put<PhysicianPayor>({
        path: `/api/physician_payors`,
        body,
      });
      mutate();
      enqueueSnackbar(`Successfully updated physician payor`, { variant: 'success' });
      return physicianPayor;
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      throw new SolaceApiError(error);
    }
  };

  const deletePhysicianPayor = async (body: Partial<DeletePhysicianPayorDto>): Promise<PhysicianPayor> => {
    try {
      const { data: physicianPayor } = await SolaceAPI.delete({
        path: `/api/physician_payors`,
        body,
      });
      mutate();
      enqueueSnackbar(`Successfully deleted physician payor`, { variant: 'success' });
      return physicianPayor;
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      throw new SolaceApiError(error);
    }
  };

  return {
    data: data || [],
    error,
    isLoading,
    refresh: mutate,
    upsertPhysicianPayor,
    deletePhysicianPayor,
  };
};

export default usePhysicianPayors;
