import { Button, LoadingSpinner, Tabs } from '@solace-health/ui';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Notes } from '../../../../components/shared/Notes/Notes';
import useGetBookingsForPatient from '../../../../hooks/bookings/useGetBookings';
import { useCheckInsuranceEligibility } from '../../../../hooks/prospects/useCheckInsuranceEligibility';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useGetUser from '../../../../hooks/useGetUser';
import useGetUserCareTimeline, { CareTimelineTypes } from '../../../../hooks/useGetUserCareTimeline';
import { useQuery } from '../../../../hooks/useQuery';
import BackButton from '../../../../shared/components/BackButton/BackButton';
import { Booking } from '../../../../types/booking';
import { CareRelationship } from '../../../../types/careRelationship';
import { Referral } from '../../../../types/referrals';
import { Role, RoleName, TraitId, User } from '../../../../types/user';
import { SolaceAPI } from '../../../../utils/api';
import { hasTrait } from '../../../../utils/user';
import CarePlanSection from './CarePlanSection/CarePlanSection';
import CareTeamSection from './CareTeamSection/CareTeamSection';
import EligibilityCheckSection from './EligibilityCheckSection/EligibilityCheckSection';
import InsuranceSection from './InsuranceSection/InsuranceSection';
import { LovedOneInfo } from './LovedOneInfo/LovedOneInfo';
import PatientDetails from './PatientDetails/PatientDetails';
import { PatientDetailsDrawer } from './PatientDetailsDrawer/PatientDetailsDrawer';
import PatientHeader from './PatientHeader/PatientHeader';
import ArchiveAccordian from './PatientInfoSection/ArchiveAccordian';
import LovedOneFlag from './PatientInfoSection/LovedOneFlag';
import PatientInfoSection from './PatientInfoSection/PatientInfoSection';
import { SchedulingDrawer } from './SchedulingDrawer/SchedulingDrawer';
import { StatementsSection } from './StatementsSection/StatementsSection';
import * as S from './style';
import VisitsAndEncounters from './VisitsAndEncounters/VisitsAndEncounter';

export enum Tab {
  VisitEncounters = 'visit-encounters',
  PatientInfo = 'patient-info',
  EligibilityChecks = 'eligibility-checks',
  Insurance = 'insurance',
  CareTeam = 'care-team',
  CarePlans = 'care-plans',
  Notes = 'notes',
  Statements = 'statements',
  LovedOne = 'loved-one',
}

const Patient = () => {
  const params = useParams<{ id: string }>();
  const query = useQuery();
  const openSchedule = query.get('schedule');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState<Tab>(Tab.VisitEncounters);

  const [inOmniscientMode, setInOmniscientMode] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);

  const [selectedAppointment, setSelectedAppointment] = useState<Booking | null>(null);

  const { userHasRole } = useCurrentUser<User & { roles: Role[] }>({
    include: ['roles'],
  });

  const [isCustomerExperience, isOutreach, isOmniscient] = [
    userHasRole(RoleName.CustomerExperience),
    userHasRole(RoleName.Outreach),
    userHasRole(RoleName.Omniscient),
  ];
  const canScheduleAppointment = isCustomerExperience || isOutreach;

  useEffect(() => {
    if (isOutreach) setActiveTab(Tab.PatientInfo);
  }, [isOutreach]);

  const {
    data: user,
    refresh,
    loginAs,
  } = useGetUser<{
    referral: Referral;
    client_care_relationships: (CareRelationship & { sharee: User })[];
    sharee_care_relationships: (CareRelationship & { client: User })[];
    identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[];
  }>({
    id: params.id,
    includes: [
      'address',
      'client_bookings.advocate.traits',
      'identities',
      'patient_care_plans',
      'physician_visits',
      'referral.insurance_address',
      'referral.prospect',
      'referral.user.client_bookings',
      'referral.user.patient_care_plans',
      'referral.user.physician_visits',
      'traits',
      'user_notes',
      'advocate_relationships.advocate.traits',
      'sharee_care_relationships.client',
      'client_care_relationships.sharee',
    ],
  });

  const { data: lastEligibilityCheck } = useCheckInsuranceEligibility({
    userId: user?.id,
    shouldFetch: !!user?.id,
  });

  useEffect(() => {
    if (openSchedule) {
      setDrawerOpen(true);
    }
    if (user) {
      if (hasTrait({ user, traitId: TraitId.CommunityHealthLovedOne })) {
        setActiveTab(Tab.PatientInfo);
      }
    }
  }, [openSchedule, user?.traits]);

  const { data: careTimelineData, refresh: refreshCareTimeline } = useGetUserCareTimeline({
    userId: isCustomerExperience ? params.id : undefined,
    includes: ['advocate.traits', 'client', 'video_call'],
  });

  const { refresh: refreshAppointments } = useGetBookingsForPatient({
    id: isCustomerExperience ? user?.id : undefined,
    includes: ['client.referral', 'advocate.traits', 'video_call'],
  });

  const onLoginAs = () => {
    if (!user?.sharetribe_uuid) return;
    loginAs({ id: user.sharetribe_uuid as string })
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        setInOmniscientMode(true);
      })
      .catch(() => {
        enqueueSnackbar('Failed to login as user. Reach out to engineering for help.', { variant: 'error' });
      });
  };

  if (!user) return <LoadingSpinner />;

  const onArchiveUser = async () => {
    if (!user?.id) return;

    await SolaceAPI.put({
      path: `/api/user/${user.id}`,
      body: { archived_dt: dayjs.utc().toISOString() },
    });

    refresh();

    enqueueSnackbar(`Successfully archived patient`, { variant: 'success' });
  };

  const onUnarchiveUser = async () => {
    if (!user?.id) return;

    await SolaceAPI.put({
      path: `/api/user/${user.id}`,
      body: { archived_dt: null },
    });

    refresh();

    enqueueSnackbar(`Successfully unarchived patient`, { variant: 'success' });
  };

  const isArchived = !!user?.archived_dt;

  const archivedAdvocatePatient = user.advocate_relationships?.find(
    (ap) => ap.archived_dt && hasTrait({ user: ap.advocate, traitId: TraitId.CommunityHealthAdvocate }),
  );

  const isLovedOneClient = user.client_care_relationships.length !== 0;

  const isLovedOne = hasTrait({ user, traitId: TraitId.CommunityHealthLovedOne });

  const encounterSubmitted = careTimelineData.some(
    (item) => item.type === CareTimelineTypes.Encounter && !!item.data.submitted_dt,
  );

  let tabs = [
    ...(isCustomerExperience
      ? [
          {
            label: 'Visits & Encounters',
            key: Tab.VisitEncounters,
            children: (
              <VisitsAndEncounters patient={user} careTimelineData={careTimelineData} refresh={refreshCareTimeline} />
            ),
          },
        ]
      : []),
    ...(isCustomerExperience || isOutreach
      ? [
          {
            label: 'Patient Info',
            key: Tab.PatientInfo,
            children: <PatientInfoSection patient={user} refresh={refresh} isLovedOne={isLovedOne} />,
          },
        ]
      : []),
    ...(isCustomerExperience || isOutreach
      ? [
          {
            label: 'Insurance',
            key: Tab.Insurance,
            children: <InsuranceSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience || isOutreach
      ? [
          {
            label: 'Eligibility Checks',
            key: Tab.EligibilityChecks,
            children: <EligibilityCheckSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Care Team',
            key: Tab.CareTeam,
            children: <CareTeamSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Care Plans',
            key: Tab.CarePlans,
            children: <CarePlanSection patient={user} />,
          },
        ]
      : []),
    ...(isCustomerExperience || isOutreach
      ? [
          {
            label: 'Notes',
            key: Tab.Notes,
            children: <Notes user={user} refresh={refresh} modalTitle="Add Patient Note" />,
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: 'Statements',
            key: Tab.Statements,
            children: <StatementsSection patient={user} setActiveTab={setActiveTab} refreshPatient={refresh} />,
          },
        ]
      : []),

    ...(isOmniscient
      ? [
          {
            label: (
              <Button.Link onClick={onLoginAs} color="#285E50">
                Log in as user
              </Button.Link>
            ),
            key: 'login-as-user',
          },
        ]
      : []),
    ...(isCustomerExperience
      ? [
          {
            label: isArchived ? (
              <Button.Link color="#285E50" onClick={onUnarchiveUser}>
                Unarchive User
              </Button.Link>
            ) : (
              <Button.Link color="#DA4D54" onClick={onArchiveUser}>
                Archive User
              </Button.Link>
            ),
            key: 'archive-user',
          },
        ]
      : []),
  ];

  const lovedOneTabs = isCustomerExperience
    ? [
        {
          label: 'Loved One Info',
          key: Tab.PatientInfo,
          children: <PatientInfoSection patient={user} refresh={refresh} isLovedOne={isLovedOne} />,
        },
        {
          label: 'Notes',
          key: 'notes',
          children: <Notes user={user} refresh={refresh} />,
        },
        {
          label: (
            <Button.Link onClick={onLoginAs} color="#285E50">
              Log in as user
            </Button.Link>
          ),
          key: 'login-as-user',
        },
      ]
    : [];

  if (isLovedOneClient) {
    const newTab = {
      label: 'Loved One',
      key: Tab.LovedOne,
      children: <LovedOneInfo lovedOne={user?.client_care_relationships?.[0].sharee || undefined} />,
    };
    tabs = [...tabs.slice(0, 7), newTab, ...tabs.slice(7)];
    tabs.push();
  }

  return (
    <>
      <S.Container>
        {inOmniscientMode && (
          <S.OmniscientContainer>
            <h4 style={{ color: '#fff', marginTop: 0 }}>
              You are now in Omniscient Mode for {user.first_name} {user.last_name}.
            </h4>
            <a style={{ color: '#fff' }} href={`${process.env.REACT_APP_SOLACE_APP_URL}/login_as`} target="_blank">
              Go to Dashboard
            </a>
          </S.OmniscientContainer>
        )}
        <BackButton onClick={() => navigate(-1)} label="Back" />

        <PatientHeader
          canScheduleAppointment={canScheduleAppointment}
          user={user}
          setDetailsDrawerOpen={setDetailsDrawerOpen}
          setSchedulingDrawerOpen={setDrawerOpen}
          refresh={refresh}
          isLovedOne={isLovedOne}
          lastEligibilityCheck={lastEligibilityCheck}
        />
        <PatientDetails user={user} isLovedOne={isLovedOne} lastEligibilityCheck={lastEligibilityCheck} />
        {archivedAdvocatePatient && <ArchiveAccordian advocatePatient={archivedAdvocatePatient} />}
        {isLovedOneClient && <LovedOneFlag user={user} />}
        <Tabs
          items={isLovedOne ? lovedOneTabs : tabs}
          defaultActiveKey={isOutreach ? Tab.Notes : Tab.VisitEncounters}
          activeKey={activeTab}
          style={{ margin: '0', maxWidth: '100%' }}
          destroyInactiveTabPane
          onChange={(key) => {
            if (key === 'login-as-user' || key === 'archive-user') setActiveTab(activeTab);
            else setActiveTab(key as Tab);
          }}
        />
      </S.Container>

      {canScheduleAppointment && (
        <SchedulingDrawer
          user={user}
          isOpen={drawerOpen}
          handleClose={() => {
            setDrawerOpen(false);
            setSelectedAppointment(null);
          }}
          refresh={() => {
            refresh();
            refreshAppointments();
            refreshCareTimeline();
          }}
          originalBooking={selectedAppointment}
          encounterSubmitted={encounterSubmitted}
        />
      )}

      {isCustomerExperience && <PatientDetailsDrawer open={detailsDrawerOpen} setOpen={setDetailsDrawerOpen} user={user} />}
    </>
  );
};

export default Patient;
