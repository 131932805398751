import { Flex, Form, FormatType } from '@solace-health/ui';

export const relationships = [
  { label: 'Father', value: 'father' },
  { label: 'Mother', value: 'mother' },
  { label: 'Grandmother', value: 'grandmother' },
  { label: 'Grandfather', value: 'grandfather' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Partner', value: 'partner' },
  { label: 'Husband', value: 'husband' },
  { label: 'Wife', value: 'Wife' },
  { label: 'Brother', value: 'brother' },
  { label: 'Sister', value: 'sister' },
  { label: 'Niece', value: 'niece' },
  { label: 'Nephew', value: 'nephew' },
  { label: 'Aunt', value: 'aunt' },
  { label: 'Uncle', value: 'Uncle' },
  { label: 'In-Law', value: 'in_law' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Neighbor', value: 'neighbor' },
  { label: 'Friend', value: 'Friend' },
];

export const LovedOneInfoForm = () => {
  return (
    <Flex vertical gap="0.5rem" style={{ marginLeft: '2rem' }}>
      <Form.Text name="payload.loved_one_first_name" label="First Name" formOptions={{ required: true }} />
      <Form.Text name="payload.loved_one_last_name" label="Last Name" formOptions={{ required: true }} />
      <Form.Select
        label="Relationship to Patient"
        name="payload.loved_one_relationship"
        options={relationships}
        containerStyle={{ marginBottom: 0 }}
      />
      <Form.Text
        label="Phone Number"
        name={'payload.loved_one_phone'}
        format={FormatType.Phone}
        pattern={FormatType.Phone}
        placeholder="(123) 456-7890"
      />
    </Flex>
  );
};
