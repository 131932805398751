import useSWR from 'swr';
import fetcher from './fetcher';
import qs from 'qs';
import type { FeatureFlagUser } from './useFeatureFlagUsers';

export enum FeatureFlagId {
  BookingHelpRequest = 'booking_help_request',
}

export type FeatureFlag = {
  id: FeatureFlagId;
  is_active: boolean;
  description: string;
  name: string;
  feature_flag_users?: Array<FeatureFlagUser>;
};

const useFeatureFlags = ({ include = [] }: { include?: Array<'users'> } = {}) => {
  const { data, error, mutate, isLoading } = useSWR<FeatureFlag[]>(
    `/v1/api/feature_flags?${qs.stringify({ include })}`,
    fetcher,
  );

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
  };
};

export default useFeatureFlags;
