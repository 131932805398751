import { Booking, BookingState } from '../../../../types/booking';
import { TraitId } from '../../../../types/user';

export type UseGetVisitsFilter = {
  traitIds?: TraitId[];
  bookingStateFilters?: BookingState[];
};

export type TableBooking = {
  type: string;
  status: BookingState;
} & Booking;

export enum AdvocateTypeFilter {
  ClinicianVisits = 'Clinician Visits',
  AdvocateVisits = 'Advocate Visits',
  AllVisits = 'All Visits',
}

export enum VisitType {
  ClinicianVisit = 'Clinician Visit',
  AdvocateIntro = 'Advocate Intro',
  AdvocateFollowUp = 'Advocate Follow-up',
  Unknown = 'Unknown',
}
