import { Flex, Icons, LoadingSpinner, Typography } from '@solace-health/ui';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import { getStateNameFromAbbreviation } from '../../../../../shared/utils/gen-utils';
import { Prospect } from '../../../../../types/prospect';
import { User } from '../../../../../types/user';
import { SolaceAPI } from '../../../../../utils/api';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { MAX_SCHEDULING_DAYS } from '../../const';
import { AddPatientEventContext } from '../../stateMachine';
import { WaitlistType } from '../../types';
import { getPhysicianAvailabilityForProspect } from '../PatientInfoForm/util';

const now = dayjs.utc().startOf('day').toDate();
const start_dt = dayjs
  .utc(now)
  .add(MAX_SCHEDULING_DAYS + 1, 'day')
  .toDate();
const end_dt = dayjs.utc(start_dt).add(2, 'week').toDate();

type NoPhysiciansAvailableProps = {
  prospect: Prospect;
  upsertProspect: (data: UpsertProspectDto) => Promise<{ data: Prospect }>;
  refreshProspect: () => Promise<Prospect | undefined>;
  onNextStep: (context?: AddPatientEventContext) => void;
  onLastStep: () => void;
};

export const NoPhysiciansAvailable = ({
  prospect,
  upsertProspect,
  refreshProspect,
  onNextStep,
  onLastStep,
}: NoPhysiciansAvailableProps) => {
  const stateName = getStateNameFromAbbreviation(prospect.state || '');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingPayors, setIsLoadingPayors] = useState(true);
  const [physiciansForPayor, setPhysiciansForPayor] = useState<User[]>([]);
  const [earliestAvailabilityText, setEarliestAvailabilityText] = useState('');

  const onClickAddToWaitlist = async () => {
    setIsSubmitting(true);

    // Need to set fallback for email so that things can be tracked in Customer IO properly
    if (!prospect.email) {
      await upsertProspect({
        ...prospect,
        email: `prospect+${prospect.id}@solace.health`,
      });
      await refreshProspect();
    }

    await SolaceAPI.post({
      path: `/api/prospects/${prospect.id}/add_to_insurance_waitlist`,
      body: {
        type: WaitlistType.NoAvailability,
      },
    });

    setIsSubmitting(false);

    onNextStep({
      waitlistMessage: `We’ve added ${prospect.first_name} ${prospect.last_name} to the waitlist. They’ll receive an email as soon as we have physician availability in ${getStateNameFromAbbreviation(prospect.state || '')}.`,
    });
  };

  useEffect(() => {
    getPhysicianAvailabilityForProspect({
      start_dt,
      end_dt,
      prospect_id: prospect.id,
    })
      .then(({ earliestAvailableDateText, physiciansForPayor }) => {
        setEarliestAvailabilityText(earliestAvailableDateText || '');
        setPhysiciansForPayor(physiciansForPayor || []);
      })
      .finally(() => {
        setIsLoadingPayors(false);
      });
  }, []);

  return (
    <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
      <Flex vertical gap="1.5rem" align="center">
        <Typography.Display>No Available Physicians</Typography.Display>

        <Flex vertical gap="0.5rem" align="center">
          <Typography.Body color="#555" center>
            All physicians licensed in {stateName} are currently unavailable.
          </Typography.Body>
          {isLoadingPayors ? (
            <LoadingSpinner />
          ) : (
            <Typography.Body color="#555" center>
              {stateName} Licenses:{' '}
              {isEmpty(physiciansForPayor)
                ? 'N/A'
                : physiciansForPayor.map((physician) => `Dr. ${physician.fullName}`).join(', ')}
            </Typography.Body>
          )}
        </Flex>

        <Icons.ErrorExclamation color="#fff" secondaryColor="#DA4D54" size={190} />

        {isLoadingPayors ? (
          <LoadingSpinner />
        ) : (
          <Typography.Body color="#555" center>
            Earliest Expected Availability: {earliestAvailabilityText}
          </Typography.Body>
        )}
      </Flex>

      <DrawerFooter
        primaryButtonText="Add to Waitlist"
        primaryButtonOnClick={onClickAddToWaitlist}
        backButtonOnClick={onLastStep}
        primaryButtonIsSubmitting={isSubmitting}
      />
    </Flex>
  );
};
