import { Flex, Size, Table, Typography, Toggle, Icons, Button, Modal, Select } from '@solace-health/ui';
import { User } from '../../../../../../../types/user';
import { Payor } from '../../../../../../../types/payor';
import { USA_STATES } from '../../../../../../../shared/constants/general-constants';
import usePhysicianPayors from '../../../../../../../hooks/usePhysicianPayors';
import { PhysicianPayor } from '../../../../../../../types/physcianPayor';
import usePayors from '../../../../../../../hooks/usePayors';
import useProviders from '../../../../../../../hooks/useProviders';
import { titleCase } from '../../../../../../../shared/utils/gen-utils';
import * as S from './style';
import { useState } from 'react';

type Props = {
  user: User;
};

export const PhysicianPayors = ({ user }: Props) => {
  const [isEditingProvider, setIsEditingProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');

  const { providers, updateProvider } = useProviders({ includes: ['physician'] });
  const currentProvider = providers?.find((p) => p.physician_id === user.id);
  const locumProvider = providers?.find((p) => p.id === currentProvider?.locum_provider_id);

  const { payors } = usePayors({});
  const {
    data: physicianPayors,
    upsertPhysicianPayor,
    deletePhysicianPayor,
  } = usePhysicianPayors({
    filter: {
      physician_id: user.id,
    },
    include: ['payor.insurance_company', 'physician'],
  });

  const physicianPayorGroupedByState = physicianPayors?.reduce(
    (acc, p) => {
      if (!p.payor) return acc;

      if (!acc[p.payor.state]) acc[p.payor.state] = {};

      acc[p.payor.state][p.payor_id] = p;

      return acc;
    },
    {} as Record<Payor['state'], Record<Payor['id'], PhysicianPayor>>,
  );

  const payorGroupedByState = payors?.reduce(
    (acc, p) => {
      if (!acc[p.state]) acc[p.state] = [];

      acc[p.state].push({ ...p, key: p.id });

      return acc;
    },
    {} as Record<Payor['state'], (Payor & { key: string })[]>,
  );

  const data = USA_STATES.map((state) => ({
    state: state.name,
    payors: payorGroupedByState[state.abbreviation] ?? [],
    key: state.name,
  }));

  const handleSelect = (payor: Payor, selected: boolean) => {
    if (selected) {
      return upsertPhysicianPayor({
        payor_id: payor.id,
        physician_id: user.id,
      });
    }

    return deletePhysicianPayor({
      payor_id: payor.id,
      physician_id: user.id,
    });
  };

  const handleIsLocum = (physicianPayor: PhysicianPayor, isToggled: boolean) => {
    return upsertPhysicianPayor({
      payor_id: physicianPayor.payor_id,
      physician_id: user.id,
      is_locum: isToggled,
    });
  };

  const handleUpdateProvider = async () => {
    if (!currentProvider) return;

    await updateProvider(currentProvider?.id, {
      locum_provider_id: selectedProvider || null,
    });

    setIsEditingProvider(false);
  };

  return (
    <Flex vertical gap="1rem" justify="center">
      <Typography.Body>
        <b>Note:</b> As of 09/2024, physician payors work in conjunction with physician states. They must both be enabled in
        order for patients to be scheduled with a physician.
      </Typography.Body>
      <Flex gap="1rem" align="center">
        <Typography.Body style={{ lineHeight: '32px' }}>
          Is {user.fullName} temporarily fulfilling the duties of another Solace physician?
        </Typography.Body>
        <Typography.Header size={Size.XS}>
          {currentProvider?.locum_provider_id ? `Yes - For Dr. ${locumProvider?.physician?.fullName}` : 'No'}
        </Typography.Header>
        <S.EditButton onClick={() => setIsEditingProvider(true)}>
          <Icons.Pencil color="#285E50" /> Edit
        </S.EditButton>
      </Flex>
      <Table
        showHeader={false}
        columns={[
          {
            title: 'State',
            dataIndex: 'state',
          },
        ]}
        expandable={{
          defaultExpandedRowKeys: ['0'],
          expandedRowRender: (p) => (
            <S.StyledTable
              columns={[
                {
                  title: 'Insurance Program',
                  dataIndex: 'insurance_program_id',
                  key: 'insurance_program_id',
                  render: (id) => titleCase(id),
                },
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'id',
                  render: (id) => titleCase(id),
                },
                {
                  title: 'Is Locum Tenens?',
                  render: (_, r) => {
                    const physicianPayor = physicianPayorGroupedByState[r.state]?.[r.id];
                    const isLocum = physicianPayor?.is_locum;
                    return (
                      <Toggle
                        defaultState={isLocum}
                        name="is_locum"
                        value="is_locum"
                        handleEvent={(t: boolean) => handleIsLocum(physicianPayor, t)}
                        disabled={!physicianPayor}
                      />
                    );
                  },
                },
              ]}
              dataSource={p.payors}
              rowSelection={{
                hideSelectAll: true,
                onSelect: (r, s) => handleSelect(r, s),
                checkStrictly: true,
                defaultSelectedRowKeys: p.payors
                  .filter((p) => physicianPayorGroupedByState[p.state]?.[p.id])
                  .map((r) => r.id),
              }}
            />
          ),
          rowExpandable: (p) => p?.payors?.length > 0,
        }}
        dataSource={data}
      />
      <Modal isOpen={isEditingProvider} onClose={() => setIsEditingProvider(false)}>
        <Flex vertical justify="center" gap="2rem" style={{ paddingTop: '24px' }}>
          <Typography.Display size={Size.SM}>Edit Locum Tenens Provider</Typography.Display>
          <Select
            allowClear
            placeholder="Select Provider"
            onChange={(id) => setSelectedProvider(id as string)}
            options={providers?.map((p) => ({ label: p.physician?.fullName, value: p.id }))}
            defaultValue={currentProvider?.locum_provider_id || ''}
          />
          <Flex gap="1rem">
            <Button.Secondary onClick={() => setIsEditingProvider(false)}>Nevermind</Button.Secondary>
            <Button.Primary onClick={handleUpdateProvider}>Save</Button.Primary>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};
