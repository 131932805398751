import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  IconButton,
  InputBaseComponentProps,
  TableCell,
  TableCellProps,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useGetSearchSkills, { SearchSkill as ISearchSkill } from '../../../../hooks/useGetSearchSkills';
import { SolaceAPI } from '../../../../utils/api';
import { IFormik } from '../../../../types';
import { orderBy } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import useSkills from '../../../../hooks/useSkills';

const EditableTableCell = ({
  editing,
  valueKey,
  formik,
  inputProps = {},
  ...rest
}: TableCellProps & {
  editing: boolean;
  valueKey: string;
  formik: IFormik;
  inputProps?: InputBaseComponentProps;
}) => {
  return (
    <TableCell {...rest}>
      {editing ? (
        <TextField
          variant="standard"
          onChange={(e) => {
            formik.setFieldValue(valueKey, e.target.value);
          }}
          value={formik.values[valueKey]}
        />
      ) : (
        formik.values[valueKey]
      )}
    </TableCell>
  );
};

type FormikValues = any;

type Props = {
  searchSkill: ISearchSkill;
  handleRemoveNewSkill?: () => void;
};

export const SearchSkill = ({ searchSkill, handleRemoveNewSkill }: Props) => {
  const [open, setOpen] = useState(!searchSkill.id);
  const [editing, setEditing] = useState(!searchSkill.id);

  const { refresh } = useGetSearchSkills();
  const { data: skills } = useSkills();

  const { enqueueSnackbar } = useSnackbar();

  const { id, skill: __, ...initialValues } = searchSkill;

  const formik = useFormik<FormikValues>({
    initialValues,
    onSubmit: (values) => {
      if (searchSkill.id) {
        SolaceAPI.put({ path: `/api/search_skills/${searchSkill.skill_id}`, body: values })
          .then(() => {
            enqueueSnackbar(`Successfully updated`, { variant: 'success' });
            refresh();
            setEditing(false);
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(`Failed to update - ${err.message}`, {
              variant: 'error',
            });
          });
        return;
      }

      const { id, skill, ...body } = values;

      SolaceAPI.post({ path: `/api/search_skills`, body })
        .then(() => {
          enqueueSnackbar(`Successfully created`, { variant: 'success' });
          handleRemoveNewSkill && handleRemoveNewSkill();
          refresh();
          setEditing(false);
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`Failed to create - ${err.message}`, {
            variant: 'error',
          });
        });
    },
  });

  const handleSetEditing = () => {
    setEditing(true);
    setOpen(true);
  };

  const handleDelete = (e: any) => {
    if (!searchSkill.skill_id && handleRemoveNewSkill) {
      handleRemoveNewSkill();
      return;
    }

    SolaceAPI.delete({ path: `/api/search_skills/${searchSkill.id}` })
      .then(() => {
        enqueueSnackbar(`Successfully deleted ${searchSkill.skill_id} keyword match`, { variant: 'success' });
        setEditing(false);
        refresh();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`Failed to delete ${searchSkill.skill_id} keyword match - ${err.message}`, { variant: 'error' });
      });
  };

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e);
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {!searchSkill.id ? (
            <Autocomplete
              options={skills.map((skill) => skill.id) as any}
              freeSolo
              getOptionLabel={(skillId) => skills.find((skill) => skill.id === skillId)?.name || ''}
              onChange={(_, newInputValue) => {
                console.log(newInputValue);
                formik.setFieldValue('skill_id', newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ minWidth: '200px' }}
                  name="skill_id"
                  variant="standard"
                  placeholder="Search skills..."
                />
              )}
            />
          ) : (
            <strong style={{ fontSize: 16 }}>{searchSkill.skill.name}</strong>
          )}
        </TableCell>

        <EditableTableCell formik={formik} valueKey="value" editing={editing} />
        <EditableTableCell formik={formik} valueKey="distance_score_multiplier" editing={editing} />
        <EditableTableCell formik={formik} valueKey="priority" editing={editing} />

        <TableCell>
          {editing ? (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEditing(false);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" size="small" sx={{ marginLeft: '5px' }} onClick={handleSubmit}>
                Save
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Button variant="outlined" size="small" sx={{ marginLeft: 5 }} onClick={handleSetEditing}>
                Edit
              </Button>
              <Button variant="contained" size="small" color="error" sx={{ marginLeft: '5px' }} onClick={handleDelete}>
                Delete
              </Button>
            </Box>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '24px 0' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Tooltip
                  title="Will be shown on the advocates profile if this code is their highest ranking priority match."
                  placement="top"
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography variant="h6" component="div" lineHeight="22px">
                    Advocate Profile Context
                    <InfoIcon htmlColor="black" sx={{ marginLeft: '5px' }} fontSize="small" />
                  </Typography>
                </Tooltip>
              </Box>
              <Typography component="p" fontSize={12} m="0 0 12px"></Typography>
              {editing ? (
                <TextField
                  fullWidth
                  multiline
                  name="advocate_profile_context"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.advocate_profile_context}
                />
              ) : (
                <Typography component="p">{formik.values.advocate_profile_context}</Typography>
              )}
            </Box>

            <Box sx={{ margin: '24px 0' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Tooltip
                  title="Will be shown on the advocate's search result if this code is their highest ranking priority match."
                  placement="top"
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography variant="h6" component="div" lineHeight="22px">
                    Search Result Context
                    <InfoIcon htmlColor="black" sx={{ marginLeft: '5px' }} fontSize="small" />
                  </Typography>
                </Tooltip>
              </Box>
              <Typography component="p" fontSize={12} m="0 0 12px"></Typography>
              {editing ? (
                <TextField
                  fullWidth
                  multiline
                  name="search_result_context"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.search_result_context}
                />
              ) : (
                <Typography component="p">{formik.values.search_result_context}</Typography>
              )}
            </Box>

            <Box sx={{ margin: '24px 0' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" component="div">
                  Keywords
                </Typography>
              </Box>
              {!editing && Object.keys(formik.values.keywords).length === 0 && <h4>No keywords set</h4>}
              {formik.values.keywords.length > 0 &&
                orderBy(formik.values.keywords).map((keyword) => (
                  <Box key={`${searchSkill.skill_id}-${keyword}`}>{keyword}</Box>
                ))}
              {editing && (
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  defaultValue={searchSkill.keywords}
                  onChange={(_, newInputValue) => {
                    formik.setFieldValue('keywords', newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="keywords" variant="standard" placeholder="Enter keyword..." />
                  )}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
