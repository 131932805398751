import { Flex, styled } from '@solace-health/ui';

export const Container = styled(Flex)`
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: #fff;
  z-index: 100;
`;

export const ButtonContainer = styled(Flex)`
  padding: 0 1.5rem 1.5rem 1.5rem;
  gap: 1rem;
`;
