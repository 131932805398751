import { useEffect, useRef } from 'react';
import { AddPatientEventContext } from '../../stateMachine';

type InitializationProps = {
  onNextStep: (context?: AddPatientEventContext) => void;
  patientId: string | undefined;
};

export const Initialization = ({ onNextStep, patientId }: InitializationProps) => {
  // This prevents onNextStep from being called extra times in dev mode
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) return;

    isMountedRef.current = true;

    onNextStep({ userId: patientId });
  }, [isMountedRef]);

  return null;
};
