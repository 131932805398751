import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FeedIcon from '@mui/icons-material/Feed';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import PeopleIcon from '@mui/icons-material/People';
import CampaignIcon from '@mui/icons-material/Campaign';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Toolbar from '@mui/material/Toolbar';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { COLORS } from './enums/layout-enums';
import UserView from './pages/private/users/UserViewPage';
import { FileUploadOutlined, ScienceRounded, RateReview, ManageSearch } from '@mui/icons-material';
import { Collapse, ListItemButton } from '@mui/material';
import useCurrentUser from './hooks/useCurrentUser';
import { Icons, LoadingSpinner, Typography } from '@solace-health/ui';
import EligibilityCheckPage from './pages/private/users/EligibilityCheckPage/EligibilityCheckPage';
import useAuthentication from './pages/public/auth/useAuth';
import AllUsersPage from './pages/private/users/AllUsersPage/AllUsersPage';
import Patients from './pages/private/users/PatientsPage/PatientsPage';
import Patient from './pages/private/users/PatientPage/PatientPage';
import ClientsPage from './pages/private/users/ClientsPage/ClientsPage';
import AdvocatesPage from './pages/private/users/AdvocatesPage/AdvocatesPage';
import Advocate from './pages/private/users/AdvocatesPage/Advocate';
import { Role, RoleName, User } from './types/user';
import { ProspectsPage } from './pages/private/users/ProspectsPage/ProspectsPage';
import { PatientReferralsPage } from './pages/private/users/PatientReferralsPage/PatientReferralsPage';
import AdminSeed from './pages/private/seed/SeedPage';
import { SearchConfigPage } from './pages/private/searchConfig/SearchConfigPage';
import { SearchResultsPage } from './pages/private/searchResults/SearchResultsPage';
import { ReviewsPage } from './pages/private/reviews/ReviewsPage';
import NotificationsPage from './pages/private/notifications/NotificationsPage';
import { CallTranscriptsPage } from './pages/private/callTranscripts/CallTranscriptsPage';
import { CallTranscriptPage } from './pages/private/callTranscripts/CallTranscriptPage';
import { FeatureFlagsPage } from './pages/private/featureFlags/FeatureFlagsPage';
import { HealthRecordsPage } from './pages/private/healthRecords/HealthRecordsPage';
import { HealthRecordPage } from './pages/private/healthRecords/HealthRecordPage';
import { FormPage } from './pages/private/forms/FormPage/FormPage';
import { FormsPage } from './pages/private/forms/FormsPage/FormsPage';
import { PayoutsPage } from './pages/private/payouts/PayoutsPage';
import { TimesheetsPage } from './pages/private/users/TimesheetsPage/TimesheetsPage';
import AcceptedInsurancePage from './pages/private/insurance/AcceptedInsurancePage';
import { NotFoundPage } from './pages/public/NotFoundPage';
import { RouteLink } from './utils/routes';
import VisitsPage from './pages/private/users/VisitsPage/VisitsPage';
import InternalUsersPage from './pages/private/users/InternalUsersPage/InternalUsersPage';
import InternalUserPage from './pages/private/users/InternalUsersPage/InternalUserPage/InternalUserPage';

type MenuItem = {
  name: string;
  link?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  rolesNeeded?: RoleName[];
  element?: React.ReactNode;
};

export const hasRole = ({ user, roles }: { user: User & { roles: Role[] }; roles: RoleName[] }) =>
  user?.roles?.some((role) => rolesNeeded(roles).includes(role.name));

const rolesNeeded = (roles: RoleName[]) => [...roles, RoleName.Admin];

const menuItems: MenuItem[] = [
  {
    name: 'Users',
    icon: <PeopleIcon />,
    rolesNeeded: rolesNeeded([RoleName.CustomerExperience, RoleName.Outreach, RoleName.Operations, RoleName.AdvocateAdmin]),
    children: [
      {
        name: 'All Users',
        link: RouteLink.Users,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperience]),
        element: <AllUsersPage />,
      },
      {
        name: 'Patients',
        link: RouteLink.Patients,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperience, RoleName.Outreach]),
        element: <Patients />,
      },
      {
        name: 'Clients',
        link: RouteLink.Clients,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperience]),
        element: <ClientsPage />,
      },
      {
        name: 'Advocates',
        link: RouteLink.Advocates,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperience, RoleName.Operations, RoleName.AdvocateAdmin]),
        element: <AdvocatesPage />,
      },
      {
        name: 'Internal',
        link: RouteLink.InternalUsers,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperienceAdmin]),
        element: <InternalUsersPage />,
      },
      {
        name: 'Prospects',
        link: RouteLink.Prospects,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperience]),
        element: <ProspectsPage />,
      },
      {
        name: 'Patient Referrals',
        link: RouteLink.PatientReferrals,
        rolesNeeded: rolesNeeded([RoleName.CustomerExperience, RoleName.Outreach]),
        element: <PatientReferralsPage />,
      },
      {
        name: 'Eligibility Checks',
        link: RouteLink.EligibilityChecks,
        rolesNeeded: rolesNeeded([RoleName.Referrals]),
        element: <EligibilityCheckPage />,
      },
    ],
  },
  {
    name: 'Visits',
    icon: <Icons.Calendar />,
    link: RouteLink.Visits,
    rolesNeeded: rolesNeeded([RoleName.CustomerExperience]),
    element: <VisitsPage />,
  },
  {
    name: 'Timesheets',
    icon: <PunchClockIcon />,
    link: RouteLink.Timesheets,
    rolesNeeded: rolesNeeded([RoleName.CustomerExperience]),
    element: <TimesheetsPage />,
  },
  {
    name: 'Seed Data',
    icon: <FileUploadOutlined />,
    link: RouteLink.Seed,
    rolesNeeded: rolesNeeded([RoleName.Product]),
    element: <AdminSeed />,
  },
  {
    name: 'Search Config',
    icon: <ScienceRounded />,
    link: RouteLink.SearchConfig,
    rolesNeeded: rolesNeeded([RoleName.Product]),
    element: <SearchConfigPage />,
  },
  {
    name: 'Reviews',
    icon: <RateReview />,
    link: RouteLink.Reviews,
    rolesNeeded: rolesNeeded([RoleName.Product]),
    element: <ReviewsPage />,
  },
  {
    name: 'Search Results',
    icon: <ManageSearch />,
    link: RouteLink.SearchResults,
    rolesNeeded: rolesNeeded([RoleName.Product]),
    element: <SearchResultsPage />,
  },
  {
    name: 'Notifications',
    icon: <CampaignIcon />,
    link: RouteLink.Notifications,
    rolesNeeded: rolesNeeded([RoleName.Product]),
    element: <NotificationsPage />,
  },
  {
    name: 'Call Transcripts',
    icon: <PermPhoneMsgIcon />,
    link: RouteLink.CallTranscripts,
    rolesNeeded: rolesNeeded([RoleName.CallTranscripts, RoleName.AdvocateAdmin, RoleName.Physician]),
    element: <CallTranscriptsPage />,
  },
  {
    name: 'Feature Flags',
    icon: <ToggleOnIcon />,
    link: RouteLink.FeatureFlags,
    rolesNeeded: rolesNeeded([RoleName.Product, RoleName.CustomerExperience]),
    element: <FeatureFlagsPage />,
  },
  {
    name: 'Health Records',
    icon: <HealthAndSafetyIcon />,
    link: RouteLink.HealthRecords,
    rolesNeeded: rolesNeeded([RoleName.HealthRecords]),
    element: <HealthRecordsPage />,
  },
  {
    name: 'Payouts',
    icon: <AttachMoneyIcon />,
    link: RouteLink.Payouts,
    rolesNeeded: rolesNeeded([RoleName.Payouts]),
    element: <PayoutsPage />,
  },
  {
    name: 'Forms',
    icon: <FeedIcon />,
    link: RouteLink.Forms,
    rolesNeeded: rolesNeeded([RoleName.Forms]),
    element: <FormsPage />,
  },
  {
    name: 'Accepted Insurances',
    icon: <HealthAndSafetyIcon />,
    link: RouteLink.AcceptedInsurance,
    rolesNeeded: rolesNeeded([RoleName.Operations]),
    element: <AcceptedInsurancePage />,
  },
];

const drawerWidth = 240;

function AppAuthenticated(props: any) {
  const { data: user } = useCurrentUser<User & { roles: Role[] }>({
    include: ['roles'],
  });

  const { logout } = useAuthentication();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [submenuOpen, setSubmenuOpen] = React.useState<string>('');
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const history = useNavigate();

  const handleMenuClick = (menuItem: MenuItem) => {
    if (menuItem.link) return history(menuItem.link);
    if (menuItem.children) setSubmenuOpen(menuItem.name === submenuOpen ? '' : menuItem.name);
  };

  const hasAccess = (roles?: RoleName[]) => user?.roles?.some((role: Role) => roles?.includes(role.name));

  const SubMenuRender = (child: MenuItem) => {
    if (child.rolesNeeded && !hasAccess(child.rolesNeeded)) return;
    return (
      <ListItemButton sx={{ pl: 4 }} key={child.name} onClick={() => child.link && history(child.link)}>
        <ListItemText primary={child.name} />
      </ListItemButton>
    );
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {menuItems.map((item) => (
          <div key={item.name}>
            {(!item.rolesNeeded || hasAccess(item.rolesNeeded)) && (
              <>
                <ListItemButton
                  onClick={() => handleMenuClick(item)}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                  {(item.children || []).length > 0 && (
                    <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                      <Icons.Caret direction={submenuOpen === item.name ? 'up' : 'down'} />
                    </ListItemIcon>
                  )}
                </ListItemButton>
                {item.children && item.children.length > 0 && (
                  <Collapse in={submenuOpen === item.name} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.children.map(SubMenuRender)}
                    </List>
                  </Collapse>
                )}
              </>
            )}
          </div>
        ))}
      </List>
      <Divider />
      <List>
        <ListItemButton
          key={'logout'}
          onClick={(): void => {
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Log Out'} />
        </ListItemButton>
      </List>
    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  const routes = menuItems.flatMap((route) =>
    route.children
      ? route.children.flatMap((childRoute) => {
          return user && hasRole({ user, roles: childRoute.rolesNeeded || [] })
            ? [<Route key={childRoute.link} path={childRoute.link} element={childRoute.element} />]
            : [];
        })
      : [<Route key={route.link} path={route.link} element={route.element} />],
  );

  if (!user) return <LoadingSpinner />;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: COLORS.GREEN_BRAND_PRIMARY }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          zIndex: 900,
        }}
      >
        <Toolbar>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flex: 1 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <img
                onClick={() => {
                  history('/users');
                }}
                alt={'logo'}
                width={80}
                style={{ cursor: 'pointer', float: 'left' }}
                src={process.env.PUBLIC_URL + '/logo.png'}
              ></img>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              zIndex: 900,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              zIndex: 900,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 8, width: `calc(100% - ${drawerWidth}px)` }}>
        <Routes>
          {routes}

          {user && hasRole({ user, roles: [RoleName.CustomerExperience, RoleName.AdvocateAdmin, RoleName.Operations] }) && (
            <>
              <Route path={RouteLink.Advocate} element={<Advocate />} />
            </>
          )}

          {user && hasRole({ user, roles: [RoleName.CustomerExperience, RoleName.Outreach] }) && (
            <>
              <Route path={RouteLink.Patient} element={<Patient />} />
            </>
          )}

          {user && hasRole({ user, roles: [RoleName.CustomerExperience] }) ? (
            <>
              <Route path={RouteLink.Home} element={<AllUsersPage />} />
              <Route path={RouteLink.User} element={<UserView />} />
            </>
          ) : (
            <Route path={RouteLink.Home} element={<Typography.Display>Hello, {user?.first_name}</Typography.Display>} />
          )}

          {user && hasRole({ user, roles: [RoleName.CustomerExperienceAdmin] }) && (
            <Route path={RouteLink.InternalUser} element={<InternalUserPage />} />
          )}

          {user && hasRole({ user, roles: [RoleName.Product] }) && (
            <>
              <Route path={RouteLink.SearchResultListing} element={<SearchResultsPage listingView />} />
            </>
          )}

          {user && hasRole({ user, roles: [RoleName.CallTranscripts, RoleName.Physician, RoleName.AdvocateAdmin] }) && (
            <>
              <Route path={RouteLink.CallTranscript} element={<CallTranscriptPage />} />
            </>
          )}

          {user && hasRole({ user, roles: [RoleName.HealthRecords] }) && (
            <>
              <Route path={RouteLink.HealthRecord} element={<HealthRecordPage />} />
            </>
          )}

          {user && hasRole({ user, roles: [RoleName.Forms] }) && (
            <>
              <Route path={RouteLink.Form} element={<FormPage />} />
            </>
          )}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default AppAuthenticated;
