import { Booking, BookingState } from '../../../../types/booking';
import { TraitId } from '../../../../types/user';
import { hasTrait } from '../../../../utils/user';
import { VisitType, AdvocateTypeFilter } from './types';

export const getTraitsForAdvocateType = (visitType: AdvocateTypeFilter) => {
  switch (visitType) {
    case AdvocateTypeFilter.ClinicianVisits:
      return [TraitId.CommunityHealthPhysician];
    case AdvocateTypeFilter.AdvocateVisits:
      return [TraitId.CommunityHealthAdvocate, TraitId.SelfPayAdvocate, TraitId.SolaceHealthAdvocate];
    case AdvocateTypeFilter.AllVisits:
      return [];
    default:
      return [];
  }
};

export const getVisitType = (booking: Booking) => {
  if (!booking?.advocate) return VisitType.Unknown;
  if (hasTrait({ user: booking.advocate, traitId: TraitId.CommunityHealthPhysician })) return VisitType.ClinicianVisit;
  if (booking.is_intro_call) return VisitType.AdvocateIntro;
  return VisitType.AdvocateFollowUp;
};

export const getBookingStates = (statusFilters: string[]) =>
  statusFilters.map((bookingStateFilter) => {
    if (bookingStateFilter === BookingState.Completed) {
      return BookingState.Accepted;
    }

    return bookingStateFilter as BookingState;
  });
