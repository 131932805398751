import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from './useAuth';
import { useSnackbar } from 'notistack';
import { SolaceAPI } from '../../../utils/api';
import { Flex, LoadingSpinner, Size } from '@solace-health/ui';
import { SolaceApiError } from '../../../utils/errors';

const AuthCallbackPage = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { handleCallback, setIsAuthenticated, logout } = useAuthentication();

  useEffect(() => {
    handleCallback(window.location.hash)
      .then(() => {
        SolaceAPI.post({ path: '/api/admin/current_user' })
          .then(() => {
            setIsAuthenticated(true);
            const url = localStorage.getItem('redirectUrl');
            history(url || '/');
          })
          .catch((e) => {
            const error = e as SolaceApiError;
            enqueueSnackbar(`Something went wrong. Contact engineering team for help - [${error?.requestId}]`, {
              variant: 'error',
            });
            logout({ withRedirect: false });
            history('/');
          });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
        history('/');
      });
  }, []);

  return (
    <Flex justify="center" style={{ marginTop: '24px' }}>
      <LoadingSpinner size={Size.LG} />
    </Flex>
  );
};
export default AuthCallbackPage;
