import useSWR from 'swr';
import { Prospect } from '../../types/prospect';
import { ReferralEligibilityCheckStatus } from '../../types/referrals';
import { User } from '../../types/user';
import { SolaceAPI } from '../../utils/api';
import fetcher from '../fetcher';

export type CheckInsuranceEligibilityResponse = {
  is_eligible: boolean;
  user_id: string;
};

export type SerializedInsuranceEligibility =
  | {
      status:
        | ReferralEligibilityCheckStatus.Eligible
        | ReferralEligibilityCheckStatus.Ineligible
        | ReferralEligibilityCheckStatus.Unchecked;
      status_reason: string;
      status_reason_description: string;
      payer_name: string;
      member_id: string;
      member_id_field: string;
      insurance_status: string;
      insurance_status_field: string;
      remaining_deductible: string;
      has_remaining_deductible: boolean;
      coverage_effective_date: string;
      co_insurance: string;
      has_co_insurance: boolean;
      /** Present when status = eligible and status_reason = 'Supplemental' */
      medicare_supplemental_to_insurance: {
        payer_name: string;
        member_id: string;
        effective_date: string;
        type: string;
      } | null;
      vendor_eligibility_check: Record<string, unknown>;
      created_at: string;
    }
  | {
      status: ReferralEligibilityCheckStatus.Failed;
      status_reason: string;
      status_reason_description: string;
      created_at: string;
    };

export const useCheckInsuranceEligibility = ({
  prospectId,
  userId,
  shouldFetch = true,
}: {
  userId?: User['id'] | undefined;
  prospectId?: Prospect['id'] | undefined;
  shouldFetch?: boolean;
}) => {
  const { data: latestCheckByProspect, isLoading: isLoadingLatestCheckByProspect } = useSWR<SerializedInsuranceEligibility>(
    shouldFetch && prospectId ? `/v1/api/admin/prospects/${prospectId}/latest_eligibility_check` : null,
    fetcher,
    { shouldRetryOnError: (error: Error) => error?.message !== 'Not Found' },
  );

  const { data: latestCheckByUser, isLoading: isLoadingLatestCheckByUser } = useSWR<SerializedInsuranceEligibility>(
    shouldFetch && userId ? `/v1/api/admin/users/${userId}/latest_eligibility_check` : null,
    fetcher,
    { shouldRetryOnError: (error: Error) => error?.message !== 'Not Found' },
  );

  const checkInsuranceEligibility = () => {
    if (!prospectId) return Promise.reject(new Error('prospectId is required'));

    return SolaceAPI.post<CheckInsuranceEligibilityResponse>({
      path: `/api/admin/prospects/${prospectId}/check_insurance_eligibility`,
    });
  };

  return {
    checkInsuranceEligibility,
    data: latestCheckByProspect || latestCheckByUser,
    isLoading: isLoadingLatestCheckByProspect || isLoadingLatestCheckByUser,
  };
};
