import { PatientStatus, TraitId, User } from '../types/user';

export const hasTrait = ({ user, traitId }: { user: User; traitId: TraitId }): boolean => {
  if (!user?.traits) return false;

  return user.traits?.some((trait) => trait.id === traitId);
};

export const hasAtLeastOneTrait = ({ user, traitIds }: { user: User; traitIds: TraitId[] }): boolean => {
  if (!user?.traits) return false;

  return user.traits.some((trait) => traitIds.includes(trait.id));
};

export const prettyStatusName = {
  [PatientStatus.Active]: 'Active Care Plan',
  [PatientStatus.Archived]: 'Archived',
  [PatientStatus.Check_Insurance]: 'Verify Insurance',
  [PatientStatus.Chw_Visit]: 'Advocate Booked',
  [PatientStatus.Clinician_Visit]: 'Clinician Booked',
  [PatientStatus.Schedule_Chw]: 'Schedule Advocate',
  [PatientStatus.Schedule_Clinician]: 'Schedule Clinician',
  [PatientStatus.Unknown]: 'Unknown',
  [PatientStatus.LovedOne]: 'Loved One',
  [PatientStatus.Unverified]: 'Unverified',
};
