import { Prospect } from '../../../../../types/prospect';
import { AddPatientEventContext } from '../../stateMachine';
import { WarningCard } from '../../shared/WarningCard/WarningCard';
import { BadEligibilityCheckRequestInvalidField } from '../../../../../utils/errors';
import { CheckAnotherPolicy } from '../CheckAnotherPolicy/CheckAnotherPolicy';
import { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import { Flex, LoadingSpinner } from '@solace-health/ui';
import { useCheckInsuranceEligibility } from '../../../../../hooks/prospects/useCheckInsuranceEligibility';
import { FailedStatusReason } from '../../../../../types/referrals';
import { User } from '../../../../../types/user';
import useGetUser from '../../../../../hooks/useGetUser';
import { UserCard } from '../../shared/UserCard/UserCard';
import { useNavigate } from 'react-router-dom';

export const EligibilityStatusFailed = ({
  prospect,
  onNextStep,
  onLastStep,
  invalidField,
  existingUserId,
  upsertProspect,
}: {
  prospect: Prospect;
  onNextStep: (context?: AddPatientEventContext) => void;
  onLastStep: () => void;
  invalidField: BadEligibilityCheckRequestInvalidField | undefined;
  existingUserId: User['id'] | undefined;
  upsertProspect: (data: UpsertProspectDto) => Promise<{ data: Prospect }>;
}) => {
  const { data: lastEligibilityCheck, isLoading: isLoadingLatestCheck } = useCheckInsuranceEligibility({
    prospectId: prospect.id,
  });
  const { data: existingUser, loading: isLoadingExistingUser } = useGetUser({ id: existingUserId });

  const navigate = useNavigate();

  if (isLoadingLatestCheck || isLoadingExistingUser) return <LoadingSpinner />;
  if (!lastEligibilityCheck) return <>Something went wrong...</>;

  const warningCardTitle = (() => {
    if (existingUser) return 'MBI Already on File';

    switch (lastEligibilityCheck.status_reason) {
      case FailedStatusReason.PayorIsTemporarilyDown:
        return 'Payor is Down';
      case FailedStatusReason.InsufficientInformation:
        return 'Insufficient Information';
      default:
        return 'Patient not found.';
    }
  })();

  const warningCardContent = existingUser
    ? 'The Medicare Beneficiary Identifier is already on file for another patient.'
    : lastEligibilityCheck.status_reason_description;

  const onClickPatientFile = () => {
    if (!existingUser) return;

    navigate(`/Patients/${existingUser.id}`);
  };

  return (
    <CheckAnotherPolicy
      preMainContent={
        <Flex vertical gap="1.5rem">
          <WarningCard
            bgColor="#F1E1E2"
            secondaryColor="#DA4D54"
            title={warningCardTitle}
            content={warningCardContent}
            textColor="#DA4D54"
          />
          {existingUser && (
            <UserCard
              fullName={existingUser.fullName}
              userType="Patient"
              phone={existingUser.phone}
              email={existingUser.email}
              onClickCta={onClickPatientFile}
              ctaText="View Patient File"
            />
          )}
        </Flex>
      }
      prospect={prospect}
      onNextStep={onNextStep}
      onLastStep={onLastStep}
      upsertProspect={upsertProspect}
      invalidField={invalidField}
    />
  );
};
