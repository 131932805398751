import { Button, styled } from '@solace-health/ui';

export const TableContainer = styled.div`
  &&& th {
    background-color: #fff;
    border-bottom: 1px solid var(--border-color-dark, #555);
    color: var(--Grey-grey-1, #555);
  }

  &&& td {
    border-bottom: 1px solid var(--color-borders-border-stroke-dark, #ccc);
    padding: 2rem 1rem;
  }
`;

export const ForgiveButton = styled(Button.Unstyled)`
  border-radius: var(--Border-Radius-rounded-lg, 0.5rem);
  border: 1px solid var(--border-color-light-green, #afc8bf);
  background: var(--green-100, #f4f8f7);
  padding: 0.25rem;
  height: 2rem;
`;

export const PayNowButton = styled(Button.Unstyled)`
  border-radius: 0.625rem;
  border: var(--border-width-sm, 1px) solid var(--Color-Opal-Greens-Opal, #afc8bf);
  background: var(--button-bg-white, #fff);
  padding: 0.25rem 1.5rem;
  height: 2rem;
`;
