import { Button, Flex, Modal, Typography } from '@solace-health/ui';

type ConfirmCloseModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
};

export const ConfirmCloseModal = ({ isOpen, handleCancel, handleConfirm }: ConfirmCloseModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={handleCancel} destroyOnClose>
      <Flex vertical gap={8}>
        <Typography.Header>Are you sure about that?</Typography.Header>
        <Typography.Body>Exiting will remove all unsaved progress...</Typography.Body>
      </Flex>
      <Flex gap={20} style={{ marginTop: 20 }}>
        <Button.Outline onClick={handleCancel}>Go Back</Button.Outline>
        <Button.Primary onClick={handleConfirm}>Confirm</Button.Primary>
      </Flex>
    </Modal>
  );
};
