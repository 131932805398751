import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import qs from 'qs';
import useSWR from 'swr';
import { CommunityHealthAppointments, Listing } from '../types/listing';
import { User } from '../types/user';
import fetcher from './fetcher';
dayjs.extend(objectSupport);

export const useCHWAvailability = ({
  month,
  year,
  user,
  listingId,
  includeOffHours,
  shouldFetch,
}: {
  month: number;
  year: number;
  user: User;
  listingId?: Listing['id'];
  includeOffHours: boolean;
  shouldFetch: boolean;
}) => {
  const monthOffset = month - 1;
  const startDate = dayjs({ year, month: monthOffset }).toISOString();
  const endDate = dayjs({ year, month }).endOf('month').toISOString();
  const query = qs.stringify({
    start_dt: startDate,
    end_dt: endDate,
    time_zone: user?.time_zone,
    listing_id: listingId,
    include_off_hours: includeOffHours,
  });

  const { data, error, mutate } = useSWR<CommunityHealthAppointments>(
    shouldFetch && user?.id ? `/v1/api/timeslots/community_health?${query}` : null,
    fetcher,
  );

  return {
    loading: data === undefined,
    data: data || ({} as CommunityHealthAppointments),
    error,
    refresh: mutate,
  };
};
