import { User } from '../../types/user';
import { Referral } from '../../types/referrals';

import useGetPaginatedResource from '../useGetPaginatedResource';

type UseGetUsersProps = {
  filter?: {
    patients?: boolean;
    advocates?: boolean;
    clients?: boolean;
  };
  include?: (
    | 'address'
    | 'client_bookings'
    | 'referral.prospect'
    | 'client_bookings.advocate.traits'
    | 'patient_care_plans'
    | 'physician_visits'
    | 'referral'
    | 'referral.user.client_bookings'
    | 'referral.user.patient_care_plans'
    | 'referral.user.physician_visits'
    | 'traits'
  )[];
};

const useGetPaginatedUsers = <Includes extends Record<string, unknown>>({ filter, include }: UseGetUsersProps) => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<User & { referral?: Referral } & Includes>(
    '/v1/api/user',
    { filter, include, sort: ['-internal_id'] },
  );

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};

export default useGetPaginatedUsers;
