import { Divider, Flex, LoadingSpinner, Size, Typography } from '@solace-health/ui';
import { useCheckInsuranceEligibility } from '../../../../../hooks/prospects/useCheckInsuranceEligibility';
import { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import { Prospect } from '../../../../../types/prospect';
import { IneligibleStatusReason, ReferralEligibilityCheckStatus } from '../../../../../types/referrals';
import { SolaceAPI } from '../../../../../utils/api';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { EligibilityResultItem } from '../../shared/EligibilityResultItem/EligibilityResultItem';
import { EligibilityResultPatientInformation } from '../../shared/EligibilityResultPatientInformation/EligibilityResultPatientInformation';
import { WarningCard } from '../../shared/WarningCard/WarningCard';
import { AddPatientEventContext } from '../../stateMachine';
import { WaitlistType } from '../../types';

export const EligibilityStatusIneligible = ({
  prospect,
  upsertProspect,
  refreshProspect,
  onNextStep,
}: {
  prospect: Prospect;
  upsertProspect: (data: UpsertProspectDto) => Promise<{ data: Prospect }>;
  refreshProspect: () => Promise<Prospect | undefined>;
  onNextStep: (context?: AddPatientEventContext) => void;
}) => {
  const { data: lastEligibilityCheck, isLoading } = useCheckInsuranceEligibility({ prospectId: prospect.id });

  if (isLoading) return <LoadingSpinner />;
  if (!lastEligibilityCheck || lastEligibilityCheck.status === ReferralEligibilityCheckStatus.Failed)
    return <>Something went wrong...</>;

  const payorIsNotSupported = lastEligibilityCheck.status_reason === IneligibleStatusReason.PayorNotSupported;
  const coverageNotActive = [IneligibleStatusReason.PlanNotActive, IneligibleStatusReason.PartBnotActive].includes(
    lastEligibilityCheck.status_reason as IneligibleStatusReason,
  );
  const coverageActiveInFuture = [
    IneligibleStatusReason.PlanActiveInFuture,
    IneligibleStatusReason.PartBactiveInFuture,
  ].includes(lastEligibilityCheck.status_reason as IneligibleStatusReason);

  const onClickAddToWaitlist = async () => {
    // Need to set fallback for email so that things can be tracked in Customer IO properly
    if (!prospect.email) {
      await upsertProspect({
        ...prospect,
        email: `prospect+${prospect.id}@solace.health`,
      });
      await refreshProspect();
    }

    await SolaceAPI.post({
      path: `/api/prospects/${prospect.id}/add_to_insurance_waitlist`,
      body: {
        type: WaitlistType.NotCovered,
        ...(coverageActiveInFuture ? { eligible_in_future_dt: lastEligibilityCheck.coverage_effective_date } : {}),
      },
    });

    onNextStep({
      waitlistMessage: `We’ve added ${prospect.first_name} ${prospect.last_name} to the waitlist. They’ll receive an email on the effective date of their insurance policy.`,
    });
  };

  const onClickCheckAnotherPolicy = () => {
    onNextStep();
  };

  return (
    <>
      <Flex vertical style={{ width: '100%' }} gap="1.5rem">
        {coverageActiveInFuture ? (
          <WarningCard
            bgColor="#F4E5CA"
            secondaryColor="#D7A13B"
            title="The patient isn't eligible just yet..."
            content={
              <Flex vertical>
                <Typography.Body>Here's the reason:</Typography.Body>
                <ul>
                  <li>
                    The patient's policy isn't active yet. This coverage starts on{' '}
                    {lastEligibilityCheck.coverage_effective_date}.
                  </li>
                </ul>

                <Typography.Body>
                  <i>Here's a few things you can do:</i>
                </Typography.Body>
                <ul>
                  <li>
                    <i>Ask the patient if they have another policy that’s currently active.</i>
                  </li>
                  <li>
                    <i>Schedule them for a visit after {lastEligibilityCheck.coverage_effective_date}.</i>
                  </li>
                  <li>
                    <i>Ask them to call back after {lastEligibilityCheck.coverage_effective_date}.</i>
                  </li>
                </ul>
              </Flex>
            }
          />
        ) : (
          <WarningCard
            bgColor="#F1E1E2"
            secondaryColor="#DA4D54"
            title="Sorry, this patient isn’t eligible right now."
            content={lastEligibilityCheck.status_reason_description}
            textColor="#DA4D54"
          />
        )}

        <Flex vertical gap="1.5rem">
          <EligibilityResultPatientInformation prospect={prospect} />

          <Divider style={{ margin: 0 }} />

          <Flex vertical gap="1.5rem">
            <Typography.Header size={Size.SM} color="#3F937C">
              Primary Payer
            </Typography.Header>
            <Flex>
              <EligibilityResultItem field="Payer" value={lastEligibilityCheck.payer_name} highlight={payorIsNotSupported} />
              <EligibilityResultItem field={lastEligibilityCheck.member_id_field} value={lastEligibilityCheck.member_id} />
            </Flex>
            <Flex>
              <EligibilityResultItem
                field={lastEligibilityCheck.insurance_status_field}
                value={lastEligibilityCheck.insurance_status}
                highlight={coverageNotActive}
              />
              <EligibilityResultItem
                field="Remaining Deductible"
                value={lastEligibilityCheck.remaining_deductible}
                valueColor={lastEligibilityCheck.has_remaining_deductible ? undefined : '#3F937C'}
              />
            </Flex>
            <Flex>
              <EligibilityResultItem field="Effective Date" value={lastEligibilityCheck.coverage_effective_date} />
              <EligibilityResultItem
                field="Co-Insurance"
                value={lastEligibilityCheck.co_insurance}
                valueColor={lastEligibilityCheck.has_co_insurance ? undefined : '#3F937C'}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <DrawerFooter
        showCheckAnotherPolicy={coverageNotActive || payorIsNotSupported || coverageActiveInFuture}
        checkAnotherPolicyOnClick={onClickCheckAnotherPolicy}
        primaryButtonOnClick={payorIsNotSupported || coverageActiveInFuture ? onClickAddToWaitlist : undefined}
        primaryButtonText={payorIsNotSupported || coverageActiveInFuture ? 'Add to Waitlist' : undefined}
        showBackButton={false}
      />
    </>
  );
};
