import { Flex, Typography } from '@solace-health/ui';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import useGetUserCareTeam, { UserCareTeamResponse } from '../../../../../hooks/useGetUserCareTeam';
import { textCompare } from '../../../../../shared/utils/table-utils';
import { AdminTable } from '../../../../../components/shared/Table';
import { getStateNameFromAbbreviation } from '../../../../../shared/utils/gen-utils';
import { phoneNumberFormat } from '../../../../../utils/general';
import StatusPill from '../../../../../components/sharedStatusPill/StatusPill';
import { capitalize } from 'lodash';
import { TraitId } from '../../../../../types/user';

type CareTeamSectionProps = {
  patient: GetUserResponse;
};

const getPillColorOptions = (relationship: string) => {
  switch (relationship) {
    case TraitId.CommunityHealthPhysician:
      return {
        backgroundColor: 'var(--gold-100, #FFF7E9)',
        borderColor: 'var(--border-color-warning, #D7A13B)',
        text: 'Clinician',
      };
    case TraitId.CommunityHealthAdvocate:
      return {
        backgroundColor: 'var(--green-100, #F4F8F7)',
        borderColor: 'var(--border-color-dark-green, #285E50)',
        text: 'Advocate',
      };

    default:
      return {
        backgroundColor: 'var(--grey-100, #EEE)',
        borderColor: 'var(--border-color-dark, #555)',
        text: relationship,
      };
  }
};

export default function CareTeamSection({ patient }: CareTeamSectionProps) {
  const { data } = useGetUserCareTeam({ userId: patient.sharetribe_uuid });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: UserCareTeamResponse, b: UserCareTeamResponse) => textCompare(a, b, 'name'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      render: (state: string) => getStateNameFromAbbreviation(state),
      sorter: (a: UserCareTeamResponse, b: UserCareTeamResponse) => textCompare(a, b, 'location'),
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      render: (phone: string) => phoneNumberFormat(phone),
      sorter: (a: UserCareTeamResponse, b: UserCareTeamResponse) => textCompare(a, b, 'contact'),
    },
    {
      title: 'Relationship',
      dataIndex: 'relationship',
      render: (relationship: string) => {
        const { backgroundColor, borderColor, text } = getPillColorOptions(relationship);
        return (
          <StatusPill backgroundColor={backgroundColor} borderColor={borderColor}>
            {capitalize(text)}
          </StatusPill>
        );
      },
    },
  ];

  const formattedData = data
    ? data.map((item) => ({
        ...item,
        relationship: typeof item.relationship === 'string' ? item.relationship : item.relationship[0].name,
      }))
    : [];

  return (
    <Flex vertical gap="1rem">
      <Typography.Header>Care Team</Typography.Header>
      <AdminTable showSearch={false} columns={columns} data={formattedData} />
    </Flex>
  );
}
