import { Flex, Form, FormatType, LoadingSpinner, Typography, useForm } from '@solace-health/ui';
import { InsuranceProgramId, Prospect } from '../../../../../types/prospect';
import { DrawerFooter } from '../../DrawerFooter/DrawerFooter';
import { AddPatientEventContext } from '../../stateMachine';
import { UpsertProspectDto } from '../../../../../hooks/prospects/useProspect';
import usePayors from '../../../../../hooks/usePayors';
import { BadEligibilityCheckRequestInvalidField } from '../../../../../utils/errors';
import { useEffect } from 'react';

enum FormField {
  FirstName = 'first_name',
  LastName = 'last_name',
  Dob = 'dob',
  MedicareNumber = 'medicare_number',
  PayorId = 'payor_id',
}

type FormSchema = {
  [FormField.FirstName]: string;
  [FormField.LastName]: string;
  [FormField.Dob]: string;
  [FormField.MedicareNumber]: string;
  [FormField.PayorId]: string;
};

const INVALID_FIELD_TO_FORM_FIELD_NAMES: Record<BadEligibilityCheckRequestInvalidField, FormField[]> = {
  [BadEligibilityCheckRequestInvalidField.Dob]: [FormField.Dob],
  [BadEligibilityCheckRequestInvalidField.MemberId]: [FormField.MedicareNumber],
  [BadEligibilityCheckRequestInvalidField.Name]: [FormField.FirstName, FormField.LastName],
  [BadEligibilityCheckRequestInvalidField.NameOrDob]: [FormField.FirstName, FormField.LastName, FormField.Dob],
  [BadEligibilityCheckRequestInvalidField.DuplicateRecord]: [],
};

const FORM_FIELD_TO_ERROR_MESSAGE: Partial<Record<FormField, string>> = {
  [FormField.Dob]: "Please confirm patient's date of birth",
  [FormField.MedicareNumber]: "Please confirm patient's member ID",
  [FormField.FirstName]: "Please confirm patient's name",
  [FormField.LastName]: ' ',
};

export const CheckAnotherPolicy = ({
  prospect,
  onNextStep,
  onLastStep,
  upsertProspect,
  invalidField = undefined,
  preMainContent = undefined,
  existingUserId,
}: {
  prospect: Prospect;
  onNextStep: (context?: AddPatientEventContext) => void;
  onLastStep: () => void;
  upsertProspect: (data: UpsertProspectDto) => Promise<{ data: Prospect }>;
  invalidField?: BadEligibilityCheckRequestInvalidField;
  preMainContent?: React.ReactNode;
  existingUserId?: string;
}) => {
  const { payors, isLoading: isLoadingPayors } = usePayors();

  const form = useForm<FormSchema>({
    defaultValues: {
      first_name: prospect.first_name,
      last_name: prospect.last_name,
      dob: prospect.payload.patient_dob,
      medicare_number: prospect.payload.medicare_number,
      payor_id: prospect.payload.payor_id,
    },
  });

  const payorId = form.watch('payor_id');

  useEffect(() => {
    if (invalidField) {
      const formFieldNames = INVALID_FIELD_TO_FORM_FIELD_NAMES[invalidField];
      for (const fieldName of formFieldNames) {
        form.setError(fieldName, { message: FORM_FIELD_TO_ERROR_MESSAGE[fieldName] });
      }
    }
  }, [invalidField]);

  if (isLoadingPayors) return <LoadingSpinner />;

  const onSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    const values = form.getValues();

    await upsertProspect({
      id: prospect.id,
      first_name: values.first_name,
      last_name: values.last_name,
      payload: {
        patient_dob: values.dob,
        medicare_number: values.medicare_number,
        payor_id: values.payor_id,
      },
    });

    onNextStep();
  };

  const payorOptions = payors.map((payor) => ({
    label: `${payor.name} (${payor.state})`,
    value: payor.id,
  }));

  const isMedicare = (() => {
    if (!payorId) return false;

    const selectedPayor = payors.find((payor) => payor.id === payorId);
    if (!selectedPayor) return false;

    return !selectedPayor.insurance_company_id && selectedPayor.insurance_program_id === InsuranceProgramId.Medicare;
  })();

  return (
    <>
      <Form.Container formMethods={form} onSubmit={() => null}>
        <Flex vertical gap="2rem">
          {preMainContent}
          <Typography.Header>Patient Information</Typography.Header>
          <Form.SelectMenu
            name={FormField.PayorId}
            label="Payor"
            options={payorOptions}
            formOptions={{ required: true }}
            onChange={() => {
              form.setValue(FormField.MedicareNumber, '');
              form.clearErrors(FormField.MedicareNumber);
            }}
          />
          <Flex gap="0.5rem">
            <Form.Text name={FormField.FirstName} label="First Name" formOptions={{ required: true }} />
            <Form.Text name={FormField.LastName} label="Last Name" formOptions={{ required: true }} />
          </Flex>
          <Form.DateTextMaskInput name={FormField.Dob} label="Date of Birth" formOptions={{ required: true }} />

          <Form.Text
            name={FormField.MedicareNumber}
            label={isMedicare ? 'MBI' : 'Member ID'}
            formOptions={{
              required: true,
              ...(isMedicare
                ? {
                    maxLength: { value: 11, message: 'Must be 11 characters' },
                    minLength: { value: 11, message: 'Must be 11 characters' },
                  }
                : // Need to set some values below otherwise above restrictions persist when switching from medicare -> non medicare
                  {
                    maxLength: { value: 30, message: 'Invalid member id' },
                    minLength: { value: 1, message: 'Invalid member id' },
                  }),
            }}
            format={isMedicare ? FormatType.MBI : undefined}
          />
        </Flex>
      </Form.Container>
      <DrawerFooter
        primaryButtonOnClick={onSubmit}
        primaryButtonText="Check Eligibility"
        primaryButtonShowArrow
        showBackButton={!existingUserId}
        backButtonOnClick={onLastStep}
      />
    </>
  );
};
