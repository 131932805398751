import dayjs from 'dayjs';
import { z } from 'zod';

const onlyDigits = (string: string) => string.replace(/\D/g, '');

export const UserSchema = z.object({
  first_name: z.string().min(1, { message: 'This field is required' }),
  last_name: z.string().min(1, { message: 'This field is required' }),
  phone: z.string().refine(
    (phone: string) => {
      const digits = onlyDigits(phone);
      return digits.length === 10;
    },
    { message: 'Wrong number of digits' },
  ),
  email: z.coerce.string().email().min(1, { message: 'This field is required' }),
  patient_sex: z.enum(['male', 'female', '']).optional(),
  patient_dob: z.string().optional(),
  address: z
    .object({
      street: z.string().optional(),
      city: z.string().optional(),
      state: z.string().optional(),
      zip: z.string().optional(),
      unit: z.string().optional(),
    })
    .optional(),
});

export type UserSchemaType = z.infer<typeof UserSchema>;

export const PatientSchema = UserSchema.extend({
  patient_sex: z.enum(['male', 'female', '']),
  patient_dob: z
    .string()
    .min(1, { message: 'This field is required' })
    .refine(
      (date) => {
        const parsedDate = dayjs.utc(date, 'MM/DD/YYYY');
        const isValid = parsedDate.isValid();
        return isValid;
      },
      { message: 'Invalid date format' },
    ),
  address: z.object({
    street: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().min(1, { message: 'This field is required' }),
    zip: z.string().nullable(),
    unit: z.string().nullable(),
  }),
});

export type PatientSchemaType = z.infer<typeof PatientSchema>;
