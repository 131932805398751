import { Pill, Table, Toggle, Typography } from '@solace-health/ui';
import { User } from '../../../../../../types/user';
import { SolaceAPI } from '../../../../../../utils/api';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../../../../../../utils/errors';
import useFeatureFlags, { type FeatureFlag } from '../../../../../../hooks/useFeatureFlags';
import useFeatureFlagUsers from '../../../../../../hooks/useFeatureFlagUsers';

type Props = {
  user: User;
};

export const UserFeatureFlags = ({ user }: Props) => {
  const { data, refresh: refreshFeatureFlags } = useFeatureFlags({ include: ['users'] });

  const { data: userFeatureFlags, refresh: refreshUserFlags } = useFeatureFlagUsers({
    userId: user.id,
    include: ['feature_flag'],
    filter: {
      user_id: user.id,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  if (!data) return <Typography.Header>No Feature Flags found</Typography.Header>;

  const handleActivate = async ({ isToggled, featureFlag }: { isToggled: boolean; featureFlag: FeatureFlag }) => {
    try {
      if (isToggled) {
        await SolaceAPI.post({
          path: '/api/feature_flag_users',
          body: { feature_flag_id: featureFlag.id, user_id: user.id },
        });
      } else {
        await SolaceAPI.delete({ path: `/api/feature_flag_users/${featureFlag.id}/${user.id}` });
      }
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to ${isToggled ? 'add' : 'delete'} user for feature flag - ${error.message}`, {
        variant: 'error',
      });
    } finally {
      refreshFeatureFlags();
      refreshUserFlags();
    }
  };

  const featureFlagColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <strong>
          <pre>{id}</pre>
        </strong>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Flag Status',
      render: (featureFlag: FeatureFlag) => {
        const { is_active: isActive } = featureFlag;
        return <Pill status={isActive ? 'success' : 'default'}>{isActive ? 'Active' : 'Inactive'}</Pill>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'User Activated',
      render: (featureFlag: FeatureFlag) => (
        <Toggle
          defaultState={userFeatureFlags.some((userFeature) => userFeature.feature_flag_id === featureFlag.id)}
          name="is_active"
          handleEvent={(isToggled) => handleActivate({ isToggled, featureFlag })}
          value={String(featureFlag)}
        />
      ),
    },
  ];
  return <Table columns={featureFlagColumns} dataSource={data} />;
};
