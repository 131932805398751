import styled from '@emotion/styled';
import React, { CSSProperties } from 'react';

type Props = {
  children: string;
  style?: CSSProperties;
};

// eslint-disable-next-line no-useless-escape
const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

const Link = styled.a`
  text-decoration: underline;
  overflow-wrap: break-word;

  &:hover {
    opacity: 0.75;
  }
`;

export const Linkify = ({ children, style = {} }: Props) => (
  <div>
    {children.split(urlRegex).map((word) =>
      !word.match(urlRegex) ? (
        word
      ) : (
        <Link key={word} href={word} style={style} target="_blank">
          {word}
        </Link>
      ),
    )}
  </div>
);
