import { User } from '../../types/user';
import useGetPaginatedResource from '../useGetPaginatedResource';

export const useGetPatients = ({
  include = [],
  filter = {},
  sort = [],
}: {
  include?: string[];
  filter?: Record<string, unknown>;
  sort?: '-internal_id'[];
}) => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<User>('/v1/api/admin/patients', {
    include,
    filter,
    sort,
  });

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};
