import { styled } from '@solace-health/ui';
import * as LocalIcons from '../../../../../../components/shared/Icons';
import { CommunityHealthAppointment, Listing } from '../../../../../../types/listing';
import { timeInOriginalTimeZone } from '../../../../../../utils/general';
import { HighlightButton } from '../style';

type TimeSlot = CommunityHealthAppointment & { time: string };
interface TimeSlotsProps {
  timeSlot: TimeSlot;
  selectedTimeSlot: TimeSlot | null;
  selectedListing: Listing | null | undefined;
  setSelectedTimeSlot: (timeSlot: TimeSlot) => void;
}
const TimeBlock = styled(HighlightButton)<{ selected: boolean }>`
  padding: 0;
  height: 100%;
  gap: 12px;
  font-variant: small-caps;
`;
export function TimeSlot({ timeSlot, selectedTimeSlot, selectedListing, setSelectedTimeSlot }: TimeSlotsProps) {
  const isTimeSlotSelected = timeSlot.time === selectedTimeSlot?.time;

  return (
    <div key={timeSlot.time} style={{ width: '118px', height: '48px' }}>
      {isTimeSlotSelected ? (
        <SelectedTimeSlot timeSlot={timeSlot} selectedListing={selectedListing} />
      ) : (
        <UnselectedTimeSlot timeSlot={timeSlot} setSelectedTimeSlot={setSelectedTimeSlot} />
      )}
    </div>
  );
}

function UnselectedTimeSlot({
  timeSlot,
  setSelectedTimeSlot,
}: {
  timeSlot: TimeSlot;
  setSelectedTimeSlot: (timeSlot: TimeSlot) => void;
}) {
  const isSomeListingOffHours = Object.values(timeSlot?.is_listing_off_hour).some((offHour) => offHour);
  const isAllListingOffHours = Object.values(timeSlot?.is_listing_off_hour).every((offHour) => offHour);
  return (
    <TimeBlock onClick={() => setSelectedTimeSlot(timeSlot)} selected={false}>
      {isSomeListingOffHours ? <LocalIcons.Moon color={isAllListingOffHours ? 'gold' : '#BED3CC'} size={18} /> : null}
      {timeInOriginalTimeZone(timeSlot.time)}
    </TimeBlock>
  );
}

function SelectedTimeSlot({
  timeSlot,
  selectedListing,
}: {
  timeSlot: TimeSlot;
  selectedListing: Listing | null | undefined;
}) {
  const isOffHour = timeSlot?.is_listing_off_hour[selectedListing?.id || ''];
  const isAllListingOffHours = Object.values(timeSlot?.is_listing_off_hour).every((offHour) => offHour);
  return (
    <TimeBlock selected={true}>
      {isOffHour ? <LocalIcons.Moon color={isAllListingOffHours ? 'gold' : '#BED3CC'} size={18} /> : null}
      {timeInOriginalTimeZone(timeSlot.time)}
    </TimeBlock>
  );
}
