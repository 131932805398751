import useSWR from 'swr';
import qs from 'qs';
import { User } from '../types/user';
import type { FeatureFlag } from './useFeatureFlags';
import fetcher from './fetcher';

export type FeatureFlagUser = {
  id: string;
  feature_flag_id: string;
  user_id: string;
  feature_flag?: FeatureFlag;
  user?: User;
  active_dt: Date;
  created_at: Date;
  updated_at: Date;
};

type Props = {
  userId: string;
  include?: Array<'feature_flag' | 'user'>;
  filter?: Record<string, unknown>;
  isFlagActive?: boolean;
};

const useFeatureFlagUsers = ({ userId, include = [], filter = {} }: Props) => {
  const { data, error, mutate, isLoading } = useSWR<Array<FeatureFlagUser>>(
    userId ? `/v1/api/feature_flag_users?${qs.stringify({ include, filter })}` : null,
    fetcher,
  );

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
  };
};

export default useFeatureFlagUsers;
