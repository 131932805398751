import { isEmpty, uniqBy } from 'lodash';
import qs from 'qs';
import { CommunityHealthAppointments } from '../../../../../types/listing';
import { Payor } from '../../../../../types/payor';
import { Prospect } from '../../../../../types/prospect';
import { User, UserTimeZone } from '../../../../../types/user';
import { SolaceAPI } from '../../../../../utils/api';
import dayjs from 'dayjs';
import { DateFormat } from '../../../../../shared/utils/date-utils';

export const getPhysicianAvailabilityForProspect = async ({
  prospect_id,
  start_dt,
  end_dt,
}: {
  prospect_id: string;
  start_dt: Date;
  end_dt: Date;
}): Promise<{
  noPhysiciansAvailableForPayor?: boolean;
  noPhysiciansAvailable?: boolean;
  physiciansForPayor?: User[];
  earliestAvailableDateText?: string;
}> => {
  const { data: currentProspect } = await SolaceAPI.get<Prospect>({ path: `/api/prospects/${prospect_id}` });

  const { data: currentUser } = await SolaceAPI.get<User>({
    path: '/api/current_user',
  });

  const getPayorsParams = qs.stringify({
    include: ['physicians.listings'],
    filter: { state: currentProspect.state, is_enabled: true, notNull: { physicians: 'id' } },
  });
  const { data: payors } = await SolaceAPI.get<Payor[]>({
    path: `/api/payors?${getPayorsParams}`,
  });

  if (isEmpty(payors)) {
    return {
      noPhysiciansAvailableForPayor: true,
      earliestAvailableDateText: 'N/A',
    };
  }

  const getTimeSlotsParams = qs.stringify({
    start_dt,
    end_dt,
    time_zone: currentUser?.time_zone || UserTimeZone.NewYork,
    prospect_id,
  });
  const { data: physicianAvailability } = await SolaceAPI.get<CommunityHealthAppointments>({
    path: `/api/timeslots/physician?${getTimeSlotsParams}`,
  });

  const physiciansForPayor = (() => {
    const physiciansWithDups = payors.flatMap((p) => p.physicians || []);
    return uniqBy(physiciansWithDups, 'id');
  })();

  const availabilityDates = Object.keys(physicianAvailability);
  const hasTimeSlots = availabilityDates.length > 0;
  const earliestAvailableDateText = hasTimeSlots ? dayjs(availabilityDates[0]).format(DateFormat.Short) : 'N/A';

  return {
    noPhysiciansAvailable: !hasTimeSlots,
    physiciansForPayor,
    earliestAvailableDateText,
  };
};
