import useSWR from 'swr';
import { Prospect, ProspectPayload } from '../../types/prospect';
import { SolaceAPI } from '../../utils/api';
import fetcher from '../fetcher';

export type UpsertProspectDto = {
  id?: string;
  first_name?: string;
  last_name?: string;
  state?: string;
  phone?: string;
  email?: string;
  loved_one_name?: string;
  loved_one_relationship?: string;
  here_for?: string;
  payload?: Partial<ProspectPayload>;
  user_id?: string;
};

export type ProspectSearchResult = {
  phone: string;
  email: string;
  first_name: string;
  last_name: string;
  prospect_id: string;
  booking_help_request_type: string | null;
};

const useProspect = ({ id }: { id?: string } = {}) => {
  const { data, error, isLoading, mutate: refresh } = useSWR<Prospect>(id ? `/v1/api/prospects/${id}` : null, fetcher);

  const upsertProspect = async (data?: UpsertProspectDto) => {
    const body = data?.id ? { id: data.id, last_interaction_dt: new Date(), ...data } : data;

    return await SolaceAPI.post<Prospect>({
      path: `/api/prospects`,
      body,
    });
  };

  const searchProspectsWithoutUser = async ({
    firstName,
    lastName,
    phone,
    dob,
  }: {
    firstName: string;
    lastName: string;
    phone: string;
    dob: string;
  }) => {
    return await SolaceAPI.get<ProspectSearchResult[]>({
      path: '/api/admin/prospects/search',
      params: {
        first_name: firstName,
        last_name: lastName,
        phone,
        dob,
      },
    });
  };

  return {
    data,
    refresh,
    loading: isLoading,
    error,
    upsertProspect,
    searchProspectsWithoutUser,
  };
};

export default useProspect;
