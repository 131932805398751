import { useParams } from 'react-router-dom';
import useGetUser from '../../../../../hooks/useGetUser';
import Loading from '../../../../../components/general/Loading';
import { Header } from './shared/Header';
import { Size, Tabs, Typography } from '@solace-health/ui';
import Timespans from './shared/Timespans';
import MainSection from './shared/MainSection/MainSection';
import { Role, RoleName, TraitId, User } from '../../../../../types/user';
import { AdvocateOnboarding } from './AdvocateOnboarding/AdvocateOnboarding';
import { useQuery } from '../../../../../hooks/useQuery';
import { UserFeatureFlags } from './shared/UserFeatureFlags';
import { PhysicianPayors } from './shared/PhysicianPayors/PhysicianPayors';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import { Notes } from '../../../../../components/shared/Notes/Notes';

function Advocate() {
  const params = useParams<{ userId: string }>();
  const query = useQuery();
  const tab = query.get('tab');

  const { canViewOnboarding, userHasRole, userHasRoles } = useCurrentUser<User & { roles: Role[] }>({
    include: ['roles'],
  });
  const canViewTimeSpans = userHasRole(RoleName.CustomerExperience);
  const canViewFeatureFlags = userHasRoles([RoleName.CustomerExperience, RoleName.Product]);
  const isCustomerExperience = userHasRole(RoleName.CustomerExperience);
  const isAdvocateAdmin = userHasRole(RoleName.AdvocateAdmin);

  const {
    data: user,
    loading: loadingUser,
    refresh,
  } = useGetUser<{
    identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[];
  }>({
    id: params.userId,
    includes: ['identities', 'traits', 'feature_flags', 'user_notes'],
  });

  if (loadingUser || !user) return <Loading loading={true} />;

  const isSICA = user?.traits?.find((trait) => trait.id === TraitId.SolaceHealthAdvocate);
  const isPhysician = user?.traits?.find((trait) => trait.id === TraitId.CommunityHealthPhysician);

  return (
    <div style={{ padding: '.5rem', maxWidth: 1200 }}>
      <Header user={user} />

      <Tabs
        style={{ marginTop: '1rem' }}
        defaultActiveKey={tab || '/'}
        items={[
          {
            label: <Typography.Header size={Size.XS}>Main</Typography.Header>,
            key: '/',
            children: <MainSection />,
          },
          ...(canViewTimeSpans
            ? [
                {
                  label: <Typography.Header size={Size.XS}>Time Spans</Typography.Header>,
                  key: 'time-spans',
                  children: <Timespans advocateId={user.id} />,
                },
              ]
            : []),
          ...(canViewFeatureFlags
            ? [
                {
                  label: <Typography.Header size={Size.XS}>Feature Flags</Typography.Header>,
                  key: 'feature-flags',
                  children: <UserFeatureFlags user={user} />,
                },
              ]
            : []),
          ...(isPhysician
            ? [
                {
                  label: <Typography.Header size={Size.XS}>Physician Payors</Typography.Header>,
                  key: 'physician-payors',
                  children: <PhysicianPayors user={user} />,
                },
              ]
            : []),
          ...(isSICA && canViewOnboarding()
            ? [
                {
                  label: <Typography.Header size={Size.XS}>Onboarding</Typography.Header>,
                  key: 'onboarding',
                  children: <AdvocateOnboarding advocate={user} />,
                },
              ]
            : []),
          ...(isCustomerExperience || isAdvocateAdmin
            ? [
                {
                  label: <Typography.Header size={Size.XS}>Notes</Typography.Header>,
                  key: 'notes',
                  children: <Notes user={user} refresh={refresh} modalTitle="Add Advocate Note" />,
                },
              ]
            : []),
        ]}
      />
    </div>
  );
}

export default Advocate;
