import { Button, Table, styled } from '@solace-health/ui';
import { Payor } from '../../../../../../../types/payor';

export const StyledTable = styled(Table<Payor>)`
  && .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background: transparent;
  }
`;

export const EditButton = styled(Button.Unstyled)`
  && {
    width: fit-content;

    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 16px;

    color: #285e50;
  }
`;
