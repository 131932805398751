import { Controller, Form, Modal, Typography, useForm } from '@solace-health/ui';
import { useEffect } from 'react';
import * as S from './style';
import { useSnackbar } from 'notistack';
import { NoteType, User, UserNote } from '../../../../types/user';
import { SolaceAPI } from '../../../../utils/api';
import { SolaceApiError } from '../../../../utils/errors';

export type AddNoteModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  user: User;
  refresh: () => void;
  existingUserNote: UserNote | null;
  title: string;
};

export default function AddNoteModal({ isOpen, handleClose, existingUserNote, user, refresh, title }: AddNoteModalProps) {
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm<{ userNote: string }>({
    defaultValues: {
      userNote: existingUserNote?.description,
    },
  });

  const { setValue } = formMethods;

  useEffect(() => {
    formMethods.setValue('userNote', existingUserNote?.description || '');
  }, [existingUserNote]);

  const handleNoteSubmit = async () => {
    const { userNote } = formMethods.getValues();

    try {
      if (existingUserNote?.id) {
        await SolaceAPI.put({ path: `/api/note/${existingUserNote.id}`, body: { description: userNote } });
      } else {
        await SolaceAPI.post({
          path: `/api/note`,
          body: { description: userNote, title: 'Admin Note', to: user.id, type: NoteType.Admin },
        });
      }

      enqueueSnackbar('Note saved', { variant: 'success' });
      refresh();
      handleClose();
    } catch (error) {
      enqueueSnackbar(`Failed to save note - ${(error as SolaceApiError).error}`, { variant: 'error' });
    } finally {
      setValue('userNote', '');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <S.Container>
        <Typography.Header>{title}</Typography.Header>
        <Form.Container formMethods={formMethods} onSubmit={handleNoteSubmit}>
          <Controller
            name="userNote"
            control={formMethods.control}
            render={({ field }) => (
              <S.StyledTextInput
                textArea
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                formOptions={{ required: true }}
              />
            )}
          />
          <div style={{ maxWidth: 160, paddingTop: 32 }}>
            <Form.Submit label={existingUserNote ? 'Save Note' : 'Add Note'}> </Form.Submit>
          </div>
        </Form.Container>
      </S.Container>
    </Modal>
  );
}
