import { Drawer, Flex, Icons, Size, Typography } from '@solace-health/ui';
import { phoneNumberFormat } from '../../../../../utils/general';
import dayjs from 'dayjs';
import { User } from '../../../../../types/user';
import { CareRelationship } from '../../../../../types/careRelationship';
import { ClaimAccountUrl } from './ClaimAccountUrl';
import { Link } from 'react-router-dom';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: User & {
    client_care_relationships: (CareRelationship & { sharee: User })[];
    sharee_care_relationships: (CareRelationship & { client: User })[];
  };
};

export const PatientDetailsDrawer = ({ open, setOpen, user }: Props) => {
  const lovedOne = user?.client_care_relationships?.[0]?.sharee;
  const lovedOnesPatient = user?.sharee_care_relationships?.[0]?.client;

  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      placement="right"
      width="450px"
      destroyOnClose
    >
      <Flex vertical gap="2rem">
        <Flex align="center" gap=".5rem">
          <Icons.PatientAvatar />
          <Typography.Header size={Size.LG}>{user.fullName}</Typography.Header>
        </Flex>

        {lovedOne && (
          <Flex align="center" gap=".5rem">
            <Icons.Heart />
            <Flex align="center">
              <b>
                <Link to={`/patients/${lovedOne.id}`}>{lovedOne.fullName} (Loved One)</Link>
              </b>
            </Flex>
          </Flex>
        )}
        {lovedOnesPatient && (
          <Flex align="center" gap=".5rem">
            <Icons.Heart />
            <Flex align="center">
              <Link to={`/patients/${lovedOnesPatient.id}`}>
                <b>{lovedOnesPatient.fullName} (Patient)</b>
              </Link>
            </Flex>
          </Flex>
        )}

        <Flex vertical gap="1rem">
          <Typography.Header>Contact Details</Typography.Header>
          <Flex align="center" gap=".5rem">
            <Icons.Phone color="#000" />
            <Typography.Body>{phoneNumberFormat(user.phone, '')}</Typography.Body>
          </Flex>
          <Flex align="center" gap=".5rem">
            <Icons.Email color="#000" />
            <Typography.Body>{user.email}</Typography.Body>
          </Flex>
        </Flex>

        <Flex vertical gap="1rem">
          <Typography.Header>Account Access</Typography.Header>

          {user.signup_dt ? (
            <Typography.Body>User created account {dayjs(user.signup_dt).format('MM/DD/YYYY')}</Typography.Body>
          ) : (
            <ClaimAccountUrl user={user} />
          )}
        </Flex>
      </Flex>
    </Drawer>
  );
};
