import { Button, Flex, Size, Typography } from '@solace-health/ui';
import { GoogleIcon } from './GoogleIcon';

type Props = {
  onClick: () => void;
};

export const SocialLoginButton = ({ onClick }: Props) => {
  return (
    <Flex justify="center" style={{ width: '100%' }}>
      <Flex style={{ maxWidth: '300px' }}>
        <Button.Outline onClick={onClick}>
          <Flex justify="center" gap="1rem">
            <GoogleIcon />
            <Typography.Header size={Size.SM}>Login with Google</Typography.Header>
          </Flex>
        </Button.Outline>
      </Flex>
    </Flex>
  );
};
