import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Login from './pages/public/auth/LoginPage';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { COLORS } from './enums/layout-enums';
import AuthCallbackPage from './pages/public/auth/AuthCallbackPage';
import { GoogleLogin } from './pages/public/auth/GoogleLogin/GoogleLogin';

const authContStyle = {
  marginTop: 16,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function AppPublic() {
  const history = useNavigate();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{ backgroundColor: COLORS.GREEN_BRAND_PRIMARY }}>
        <Toolbar>
          <img
            onClick={() => {
              history('/');
            }}
            alt={'logo'}
            width={80}
            style={{ cursor: 'pointer', float: 'left' }}
            src={process.env.PUBLIC_URL + '/logo.png'}
          ></img>
        </Toolbar>
      </AppBar>

      <Box component="main" sx={{ width: '100%', marginTop: 8 }}>
        <Routes>
          <Route path="auth-callback" element={<AuthCallbackPage />} />
          {process.env.REACT_APP_ENABLE_GOOGLE_AUTH === 'true' ? (
            <Route path="*" element={<GoogleLogin />} />
          ) : (
            <Route
              path="*"
              element={
                <div style={authContStyle}>
                  <Login />
                </div>
              }
            />
          )}
        </Routes>
      </Box>
    </Box>
  );
}

export default AppPublic;
