import { Drawer as SolaceDrawer, styled, Flex, Select, DatePicker, Divider, Icons, Typography } from '@solace-health/ui';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useCHWAvailability } from '../../../../../hooks/useCHWAvailability';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import { usePhysicianAvailability } from '../../../../../hooks/usePhysicianAvailability';
import { Booking } from '../../../../../types/booking';
import { CommunityHealthListing, Listing } from '../../../../../types/listing';
import { ChooseAdvocate } from './ChooseAdvocate';
import { Confirmation } from './Confirmation/Confirmation';
import { MeetingInfo } from './MeetingInfo/MeetingInfo';
import { PatientInfo } from './PatientInfo';
import * as S from './style';

const Drawer = styled(SolaceDrawer)`
  .ant-drawer-body {
    padding: 2rem;
  }
`;

const month = (date: Date) => date.getUTCMonth() + 1;
const formatDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export enum MeetingOptions {
  Physician = 'physician',
  AdvocateIntro = 'advocate-intro',
  AdvocateFollowUp = 'advocate-follow-up',
}

type Props = {
  user: GetUserResponse;
  isOpen: boolean;
  handleClose: () => void;
  refresh: () => void;
  originalBooking?: Booking | null;
  encounterSubmitted?: boolean | null;
  useDrawerDisplay?: boolean;
};

export const SchedulingDrawer = ({
  user,
  isOpen,
  handleClose,
  refresh,
  originalBooking = undefined,
  encounterSubmitted = undefined,
  useDrawerDisplay = true,
}: Props) => {
  const [date, setDate] = useState({
    full: formatDate(dayjs(new Date()).add(1, 'day').toDate()),
    month: month(new Date()),
    year: new Date().getUTCFullYear(),
  });
  const [meetingType, setMeetingType] = useState<MeetingOptions>(MeetingOptions.Physician);
  const showPhysicianAvailability = meetingType === MeetingOptions.Physician;
  const isIntroCall = [MeetingOptions.AdvocateIntro, MeetingOptions.Physician].includes(meetingType);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [booking, setBooking] = useState<(Booking & { listing: CommunityHealthListing }) | null>(null);

  const [selectedListingId, setSelectedListingId] = useState<string | null>(null);
  const [includeOffHours, setIncludeOffHours] = useState(false);

  const listingId = selectedListingId as Listing['id'];
  const { data: advocateAvailability } = useCHWAvailability({
    month: date.month,
    year: date.year,
    user,
    listingId,
    shouldFetch: isOpen && !showPhysicianAvailability,
    includeOffHours,
  });
  const { data: physicianAvailability } = usePhysicianAvailability({
    month: date.month,
    year: date.year,
    user,
    shouldFetch: isOpen && showPhysicianAvailability,
    includeOffHours,
  });

  const currentAvailability = showPhysicianAvailability ? physicianAvailability : advocateAvailability;

  const onDateSelect = (dayJsDate: Dayjs | null) => {
    if (!dayJsDate) return;

    const toDate = dayJsDate.toDate();

    setDate({ full: formatDate(toDate), month: month(toDate), year: toDate.getUTCFullYear() });
  };

  const onPanelChange = (dayJsDate: Dayjs) => {
    if (!dayJsDate) return;

    const toDate = dayJsDate.startOf('month').toDate();

    setDate({ full: formatDate(toDate), month: month(toDate), year: toDate.getUTCFullYear() });
  };

  const disabledDates = (current: Dayjs) => {
    if (current && current.isBefore(dayjs(), 'day')) {
      return true;
    }

    const dates = Object.keys(currentAvailability);

    const dayJsdates = dates.map((date) => dayjs(date).format('YYY-MM-DD'));
    return !dayJsdates.includes(current.format('YYY-MM-DD'));
  };

  const onClose = () => {
    handleClose();
    setShowConfirmation(false);
    setIncludeOffHours(false);
  };

  const Content = () =>
    showConfirmation && booking ? (
      <Confirmation
        booking={booking}
        onClose={onClose}
        user={user}
        rescheduling={!!originalBooking}
        meetingType={meetingType}
      />
    ) : (
      <Flex vertical gap="1.5rem">
        <PatientInfo user={user} originalBooking={originalBooking} showPhysicianAvailability={showPhysicianAvailability} />

        <Select
          sort={false}
          onChange={(e) => setMeetingType(e as MeetingOptions)}
          defaultValue={MeetingOptions.Physician}
          showSearch={false}
          options={[
            { label: 'Physician', value: MeetingOptions.Physician },
            { label: 'Advocate Intro', value: MeetingOptions.AdvocateIntro },
            { label: 'Advocate Follow-up', value: MeetingOptions.AdvocateFollowUp },
          ]}
        />
        {meetingType !== MeetingOptions.Physician && (
          <ChooseAdvocate selectedListingId={selectedListingId} setSelectedListingId={setSelectedListingId} />
        )}

        {meetingType === MeetingOptions.AdvocateIntro && !encounterSubmitted && (
          <Flex align="center" gap="1rem">
            <Icons.WarningTriangle size={32} />
            <Typography.Body>
              <b>Warning!</b> An encounter has not been submitted yet.
            </Typography.Body>
          </Flex>
        )}

        <Divider />

        {isOpen && (
          <Flex>
            <DatePicker
              value={dayjs(date.full)}
              onChange={onDateSelect}
              onPanelChange={onPanelChange}
              disabledDate={(current) => disabledDates(current)}
            />
          </Flex>
        )}
        <S.OffHoursSwitch checked={includeOffHours} onChange={setIncludeOffHours} />
        <MeetingInfo
          availability={currentAvailability}
          date={date}
          refresh={refresh}
          user={user}
          setShowConfirmation={setShowConfirmation}
          setBooking={setBooking}
          showPhysicianAvailability={showPhysicianAvailability}
          isIntroCall={isIntroCall}
          originalBooking={originalBooking}
        />
      </Flex>
    );

  return useDrawerDisplay ? (
    <Drawer open={isOpen} onClose={onClose} placement="right" width="450px" destroyOnClose>
      <Content />
    </Drawer>
  ) : (
    <Content />
  );
};
