import dayjs from 'dayjs';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import useGetUserHealthInsurances from '../../../../../hooks/useGetUserHealthInsurances';
import { Button, Flex, Icons, InfoContainer, LoadingSpinner, Size, Typography } from '@solace-health/ui';
import { useState } from 'react';
import UpsertPatientDrawer from '../../../../../components/shared/UpsertPatientDrawer';
import { first, orderBy } from 'lodash';
import { useCheckInsuranceEligibility } from '../../../../../hooks/prospects/useCheckInsuranceEligibility';
import { EligibleStatusReason, ReferralEligibilityCheckStatus } from '../../../../../types/referrals';
import { InsuranceProgramId } from '../../../../../types/prospect';
import { DateFormat } from '../../../../../shared/utils/date-utils';

export type Props = {
  patient: GetUserResponse;
};

const Row = ({ field, value }: { field: string; value: string }) => {
  return (
    <Flex>
      <div style={{ width: '12.5rem' }}>
        <Typography.Body bold>{field}</Typography.Body>
      </div>

      <Typography.Body>{value}</Typography.Body>
    </Flex>
  );
};

export default function InsuranceSection({ patient }: Props) {
  const [isOpen, setOpen] = useState(false);
  const {
    data: healthInsurances,
    refresh,
    loading: isLoadingInsurance,
  } = useGetUserHealthInsurances({ userId: patient.id });

  const { data: latestEligibilityCheck, isLoading: isLoadingLatestEligibilityCheck } = useCheckInsuranceEligibility({
    userId: patient.id,
  });

  const latestInsurance = !healthInsurances ? undefined : first(orderBy(healthInsurances, 'created', 'desc'));

  const isMedicare =
    latestInsurance?.payor?.insurance_program_id === InsuranceProgramId.Medicare &&
    !latestInsurance?.payor?.insurance_company_id;
  const insuranceCompanyName = isMedicare ? 'Medicare' : latestInsurance?.payor?.name;

  const latestEligibilityCheckWasProcessed =
    latestEligibilityCheck && latestEligibilityCheck.status !== ReferralEligibilityCheckStatus.Failed;
  const effectiveDate = latestEligibilityCheckWasProcessed ? latestEligibilityCheck?.coverage_effective_date : null;
  const planStatusField = latestEligibilityCheckWasProcessed ? latestEligibilityCheck?.insurance_status_field : null;
  const planStatus = latestEligibilityCheckWasProcessed ? latestEligibilityCheck?.insurance_status : null;
  const remainingDeductibleField = isMedicare ? 'Part B Remaining Deductible' : 'Remaining Deductible';
  const remainingDeductible = latestEligibilityCheckWasProcessed ? latestEligibilityCheck?.remaining_deductible : null;
  const estimatedCoInsurance = latestEligibilityCheckWasProcessed ? latestEligibilityCheck?.co_insurance : null;
  const eligibilityCheckCreatedDate = dayjs(latestEligibilityCheck?.created_at).format(DateFormat.Short);
  const referralInsuranceAddress = patient.referral?.insurance_address?.full;
  const memberIdField = latestEligibilityCheckWasProcessed ? latestEligibilityCheck.member_id_field : 'Member ID';

  const medicareIsSecondaryPayer =
    latestEligibilityCheck?.status === ReferralEligibilityCheckStatus.Eligible &&
    latestEligibilityCheck?.status_reason === EligibleStatusReason.SupplementalInsurance;

  const medicareInsuranceInformationHeader = medicareIsSecondaryPayer ? 'Secondary Payer' : 'Primary Payer';

  return (
    <Flex vertical gap="1rem">
      {isLoadingInsurance || isLoadingLatestEligibilityCheck ? (
        <LoadingSpinner />
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <Typography.Header size={Size.LG}>Current Insurance</Typography.Header>
            {!latestInsurance && (
              <Button.Outline style={{ maxWidth: '13rem' }} onClick={() => setOpen(true)}>
                <Flex align="center" gap=".25rem" justify="center">
                  <Icons.Plus color="var(--button-label-green, #285E50)" />
                  Add Insurance
                </Flex>
              </Button.Outline>
            )}
          </Flex>
          {latestInsurance && medicareIsSecondaryPayer && (
            <InfoContainer
              label={
                <Flex align="center" gap="1rem">
                  <Typography.Header>{insuranceCompanyName}</Typography.Header>
                  <Typography.Body>Primary Payer</Typography.Body>
                </Flex>
              }
            >
              <Flex vertical gap="1rem">
                <Row
                  field="Patient Name"
                  value={`${latestInsurance.subscriber_first_name} ${latestInsurance.subscriber_last_name}`}
                />
                <Row field="Payer" value={latestEligibilityCheck.medicare_supplemental_to_insurance?.payer_name as string} />
                <Row
                  field="Effective Date"
                  value={latestEligibilityCheck.medicare_supplemental_to_insurance?.effective_date as string}
                />
                <Row field="Type" value={latestEligibilityCheck.medicare_supplemental_to_insurance?.type as string} />
              </Flex>
            </InfoContainer>
          )}
          {latestInsurance && (
            <InfoContainer
              label={
                <Flex align="center" gap="1rem">
                  <Typography.Header>{insuranceCompanyName}</Typography.Header>
                  <Typography.Body>{medicareInsuranceInformationHeader}</Typography.Body>
                </Flex>
              }
            >
              <Flex vertical gap="1rem">
                <Row
                  field="Patient Name"
                  value={`${latestInsurance.subscriber_first_name} ${latestInsurance.subscriber_last_name}`}
                />
                {insuranceCompanyName && <Row field="Payer" value={insuranceCompanyName} />}
                <Row field={memberIdField} value={latestInsurance.member_id} />
                {effectiveDate && <Row field="Effective Date" value={effectiveDate} />}
                {planStatusField && planStatus && <Row field={planStatusField} value={planStatus} />}
                <Row
                  field={remainingDeductibleField}
                  value={remainingDeductible ? `${remainingDeductible} (${eligibilityCheckCreatedDate})` : 'Unknown'}
                />
                {estimatedCoInsurance && <Row field="Estimated Co-Insurance" value={estimatedCoInsurance} />}
                {referralInsuranceAddress && <Row field="Patient Address" value={referralInsuranceAddress} />}
              </Flex>
            </InfoContainer>
          )}
        </>
      )}
      <UpsertPatientDrawer
        isOpen={isOpen}
        onClose={async () => {
          await refresh();
          setOpen(false);
        }}
        patient={patient}
      />
    </Flex>
  );
}
