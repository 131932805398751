import { Flex, Typography } from '@solace-health/ui';
import { Prospect } from '../../../../../types/prospect';
import dayjs from 'dayjs';
import { DateFormat } from '../../../../../shared/utils/date-utils';
import { EligibilityResultItem } from '../EligibilityResultItem/EligibilityResultItem';

export const EligibilityResultPatientInformation = ({ prospect }: { prospect: Prospect }) => {
  return (
    <Flex vertical gap="1.5rem">
      <Typography.Header>Patient Information</Typography.Header>
      <Flex>
        <EligibilityResultItem field="First Name" value={prospect.first_name} />
        <EligibilityResultItem field="Last Name" value={prospect.last_name} />
      </Flex>
      <Flex>
        <EligibilityResultItem field="Date of Birth" value={dayjs(prospect.payload.patient_dob).format(DateFormat.Short)} />
        <EligibilityResultItem field="Who's booking?" value={prospect.here_for} />
      </Flex>
    </Flex>
  );
};
