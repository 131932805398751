import { LoadingSpinner, Typography } from '@solace-health/ui';
import useGetUser from '../../../../../hooks/useGetUser';
import { SchedulingDrawer } from '../../../../../pages/private/users/PatientPage/SchedulingDrawer/SchedulingDrawer';
import { User } from '../../../../../types/user';
import * as S from './style';

type ScheduleProps = {
  handleClose: () => void;
  isOpen: boolean;
  userId: string;
};

export const Schedule = ({ handleClose, isOpen, userId }: ScheduleProps) => {
  const { data: user, loading: isLoadingUser, refresh: refreshUser, error: userError } = useGetUser<User>({ id: userId });

  if (isLoadingUser) {
    return <LoadingSpinner />;
  }

  if (userError || !user) {
    return <Typography.Display>Something went wrong</Typography.Display>;
  }

  return (
    <S.ScheduleContainer>
      <SchedulingDrawer
        handleClose={handleClose}
        isOpen={isOpen}
        refresh={refreshUser}
        user={user}
        useDrawerDisplay={false}
      />
    </S.ScheduleContainer>
  );
};
