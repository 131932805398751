import { Button, Flex, LoadingSpinner, Popover, Size, Tags, TagStatus, Typography } from '@solace-health/ui';
import dayjs from 'dayjs';
import { User } from '../../../../../types/user';
import useClaims from '../../../../../hooks/claims/useClaims';
import { formatCurrency } from '../../../payouts/calculations';
import * as S from './style';
import { Claim } from '../../../../../types/claim';
import { AdminTable } from '../../../../../components/shared/Table';
import { useState } from 'react';
import { ForgiveBalanceModal } from './ForgiveBalanceModal';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../../../../../utils/errors';
import useGetClaimsSummary from '../../../../../hooks/claims/useGetClaimsSummary';
import { SolaceAPI } from '../../../../../utils/api';
import { Tab } from '../PatientPage';

const sort = ['-encounter.submitted_dt'];

const StatusLabel = ({ children }: { children: React.ReactNode }) => (
  <Tags.Rectangle tagStatus={TagStatus.Success} style={{ border: 'none' }}>
    <Typography.Body color="#285E50">{children}</Typography.Body>
  </Tags.Rectangle>
);

export const StatementsSection = ({
  patient,
  setActiveTab,
  refreshPatient,
}: {
  patient: User;
  setActiveTab: (key: Tab) => void;
  refreshPatient: () => void;
}) => {
  const {
    data: claims,
    isLoading: isLoadingClaims,
    pagination,
    setQueryParams,
    forgiveAllClaims,
    forgiveClaim,
    refresh: refreshClaims,
  } = useClaims({ patientId: patient.id, include: ['encounter', 'service_lines'], sort });

  const {
    data: claimsSummary,
    isLoading: isLoadingClaimsSummary,
    refresh: refreshClaimsSummary,
  } = useGetClaimsSummary({ patientId: patient.id });

  const { enqueueSnackbar } = useSnackbar();

  const [selectedClaim, setSelectedClaim] = useState<Claim>();
  const [isForgiveBalanceModalOpen, setForgiveBalanceModalOpen] = useState(false);

  if (isLoadingClaims || isLoadingClaimsSummary || !claimsSummary) return <LoadingSpinner />;

  const patientOwesAnyAmount = claimsSummary.total_patient_responsibility_in_cents > 0;

  const onClickPayNow = async (claim: Claim) => {
    await SolaceAPI.post<{ checkout_url: string }>({
      path: `/api/claims/${claim.id}/checkout_sessions`,
      body: {},
    })
      .then((response) => {
        window.open(response.data.checkout_url, '_self');
      })
      .catch((error: SolaceApiError) => {
        enqueueSnackbar(error.error, { variant: 'error' });
      });
  };

  const onClickForgiveAllClaims = () => {
    setSelectedClaim(undefined);
    setForgiveBalanceModalOpen(true);
  };

  const onClickForgiveClaim = (claim: Claim) => {
    setSelectedClaim(claim);
    setForgiveBalanceModalOpen(true);
  };

  const resetAndRefreshData = async () => {
    await refreshClaims();
    await refreshClaimsSummary();
    refreshPatient();
    setForgiveBalanceModalOpen(false);
    setSelectedClaim(undefined);
  };

  const onForgiveBalance = async (formValues: { reason: string }) => {
    if (selectedClaim) {
      return await forgiveClaim({ id: selectedClaim.id, reason: formValues.reason }).then(
        () => {
          enqueueSnackbar(`Balance forgiven`, { variant: 'success' });
          resetAndRefreshData();
        },
        (error: SolaceApiError) => {
          enqueueSnackbar(`Something went wrong. Please try again. Error ID: ${error.requestId}`, { variant: 'error' });
        },
      );
    }

    return await forgiveAllClaims({ reason: formValues.reason }).then(
      () => {
        enqueueSnackbar(`Balances forgiven`, { variant: 'success' });
        resetAndRefreshData();
      },
      (error: SolaceApiError) => {
        enqueueSnackbar(`Something went wrong. Please try again. Error ID: ${error.requestId}`, { variant: 'error' });
      },
    );
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: ['encounter', 'submitted_dt'],
      render: (submittedDt: string) => dayjs(submittedDt).format('MM/DD/YYYY'),
      width: '10rem',
    },
    {
      title: 'Statement ID',
      dataIndex: 'statement_id',
      width: '15rem',
    },
    {
      title: 'Balance',
      dataIndex: ['cost_breakdown', 'total_patient_responsibility_cents'],
      render: (patientResponsibilityCents: number) => formatCurrency(patientResponsibilityCents),
      width: '12rem',
    },
    {
      title: '',
      render: (claim: Claim) => {
        if (claim.is_paid && claim.paid_by_user_id)
          return (
            <Flex gap="0.5rem" justify="flex-end">
              <StatusLabel>{claim.paid_by_user_id === patient.id ? 'Paid Online' : 'Paid via Admin'}</StatusLabel>
            </Flex>
          );

        if (claim.is_balance_forgiven)
          return (
            <Flex gap="0.5rem" justify="flex-end">
              <Popover
                placement="top"
                content={
                  <Flex vertical gap="1rem">
                    <Typography.Body bold>Payment Forgiven</Typography.Body>
                    <Button.Link color="#285E50" onClick={() => setActiveTab(Tab.Notes)}>
                      See note
                    </Button.Link>
                  </Flex>
                }
                arrow
              >
                <StatusLabel>Payment Forgiven</StatusLabel>
              </Popover>
            </Flex>
          );

        if (!claim.is_forgivable) return null;

        return (
          <Flex gap="0.5rem" justify="flex-end">
            <div>
              <S.ForgiveButton onClick={() => onClickForgiveClaim(claim)}>
                <Typography.Header size={Size.LG}>🪄</Typography.Header>
              </S.ForgiveButton>
            </div>
            <div>
              <S.PayNowButton onClick={() => onClickPayNow(claim)}>
                <Typography.Body color="#285E50" bold>
                  Pay Now
                </Typography.Body>
              </S.PayNowButton>
            </div>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Flex vertical gap="1rem">
        <Flex justify="space-between">
          <Typography.Header>Statements</Typography.Header>
          {patientOwesAnyAmount && (
            <div>
              <S.ForgiveButton onClick={onClickForgiveAllClaims}>
                <Typography.Header size={Size.LG}>🪄</Typography.Header>
                <Typography.Body color="#285E50" bold>
                  Forgive All Balances
                </Typography.Body>
              </S.ForgiveButton>
            </div>
          )}
        </Flex>

        <S.TableContainer>
          <AdminTable
            columns={columns}
            data={claims}
            showSearch={false}
            showPagination
            pagination={pagination}
            onChange={(queryParamInputs) => {
              setQueryParams({ ...queryParamInputs, sort });
            }}
            clickable={false}
          />
        </S.TableContainer>
      </Flex>
      <ForgiveBalanceModal
        setIsOpen={setForgiveBalanceModalOpen}
        isOpen={isForgiveBalanceModalOpen}
        onSubmit={onForgiveBalance}
        claim={selectedClaim}
        patient={patient}
        totalPatientResponsibilityCents={claimsSummary.total_patient_responsibility_in_cents}
      />
    </>
  );
};
