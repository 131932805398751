import { Divider } from '@mui/material';
import { Flex, Icons, LoadingSpinner, Radio, Size, Typography } from '@solace-health/ui';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as LocalIcons from '../../../../../../components/shared/Icons';
import { GetUserResponse } from '../../../../../../hooks/useGetUser';
import { Booking } from '../../../../../../types/booking';
import {
  CommunityHealthAppointment,
  CommunityHealthAppointments,
  CommunityHealthListing,
} from '../../../../../../types/listing';
import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { getAppointmentDurationInMinutes, timeZoneName } from '../../../../../../utils/general';
import { StyledButton } from '../../style';
import { AvailableAppointments } from '../AvailableAppointments';
import { TimeSlot } from './TimeSlot';

type Props = {
  availability: CommunityHealthAppointments;
  date: {
    full: string;
    month: number;
    year: number;
  };
  refresh: () => void;
  user: GetUserResponse;
  setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setBooking: (booking: Booking & { listing: CommunityHealthListing }) => void;
  showPhysicianAvailability: boolean;
  isIntroCall: boolean;
  originalBooking?: Booking | null;
};

export const MeetingInfo = ({
  availability,
  date,
  refresh,
  user,
  setShowConfirmation,
  setBooking,
  showPhysicianAvailability,
  isIntroCall,
  originalBooking,
}: Props) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<(CommunityHealthAppointment & { time: string }) | null>(null);
  const [selectedListingId, setSelectedListingId] = useState<string | null>(null);
  const [callType, setCallType] = useState<string>('phone');

  const { timeSlots } = useMemo(() => {
    const timesObj = availability[date.full] || {};
    const timeSlotArray = Object.keys(timesObj || {}).map((time) => ({
      time,
      ...timesObj[time],
    }));
    const sortedTimeSlotArray = timeSlotArray.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

    return { timeSlots: sortedTimeSlotArray };
  }, [date.full, availability]);

  const { firstAvailableListing, listings } = useMemo(() => {
    let listings: CommunityHealthListing[] = [];
    let firstAvailableListing: CommunityHealthListing | null = null;

    if (timeSlots.length > 0 && selectedTimeSlot) {
      const allListings = selectedTimeSlot.listings;

      const sortedListings = allListings.sort((a, b) => a.bookings_count - b.bookings_count);
      firstAvailableListing = sortedListings[0];
      listings = sortedListings.slice(1);
    } else {
      firstAvailableListing = null;
      listings = [];
    }

    return { firstAvailableListing, listings };
  }, [timeSlots, selectedTimeSlot]);

  const selectedListing = useMemo(() => {
    return [firstAvailableListing, ...listings].find((listing) => listing?.id === selectedListingId);
  }, [firstAvailableListing, listings, selectedListingId]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectedTimeSlot(timeSlots[0]);
  }, [timeSlots]);

  useEffect(() => {
    setSelectedListingId(firstAvailableListing?.id || null);
  }, [firstAvailableListing]);

  const onScheduleMeeting = useCallback(() => {
    if (!selectedListing || !selectedTimeSlot) return;

    const response = originalBooking
      ? SolaceAPI.post<Booking>({
          path: `/api/booking/${originalBooking.id}/reschedule`,
          body: {
            start: selectedTimeSlot.start,
            end: selectedTimeSlot.end,
            listing_id: selectedListing.id,
            call_type_requested: callType,
          },
        })
      : SolaceAPI.post<Booking>({
          path: '/api/booking/community_health',
          body: {
            client_id: user.sharetribe_uuid,
            start: selectedTimeSlot.start,
            end: selectedTimeSlot.end,
            listing_id: selectedListing.id,
            is_intro_call: isIntroCall,
            is_video_call: true,
            call_type_requested: callType,
          },
        });

    response
      .then((response) => {
        setBooking({ ...response.data, listing: selectedListing as CommunityHealthListing });
        refresh();
        setShowConfirmation(true);
      })
      .catch((response: SolaceApiError) => {
        enqueueSnackbar(`Failed to ${originalBooking ? 'reschedule' : 'schedule'} meeting - ${response.error}`, {
          variant: 'error',
        });
      });
  }, [
    originalBooking,
    user,
    isIntroCall,
    refresh,
    setBooking,
    setShowConfirmation,
    selectedListing,
    selectedTimeSlot,
    callType,
  ]);

  if (!user) return <LoadingSpinner />;

  return (
    <Flex vertical gap="2rem" style={{ width: '386px' }}>
      <Flex align="center" justify="space-between">
        <Typography.Header size={Size.XS}>{timeZoneName(user.time_zone, { includeAbbr: true })}</Typography.Header>
        <Typography.Header size={Size.XS}>
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Icons.Schedule /> {getAppointmentDurationInMinutes(selectedTimeSlot?.start, selectedTimeSlot?.end)} min call
          </span>
        </Typography.Header>
      </Flex>

      <Flex wrap="wrap" gap="1rem">
        {timeSlots.map((timeSlot) => (
          <TimeSlot
            timeSlot={timeSlot}
            selectedTimeSlot={selectedTimeSlot}
            selectedListing={selectedListing}
            setSelectedTimeSlot={setSelectedTimeSlot}
          />
        ))}
      </Flex>

      {selectedTimeSlot?.is_listing_off_hour[selectedListing?.id || ''] ? (
        <Flex
          vertical
          align="center"
          justify="center"
          gap="0.5rem"
          style={{
            borderRadius: '8px',
            border: '1px solid var(--border-color-light-green, #AFC8BF)',
            padding: 'var(--spacing-md, 1rem)',
          }}
        >
          <Flex align="center" gap="0.5rem">
            <LocalIcons.Moon color="#BED3CC" size={24} />
            <Typography.Header size={Size.SM}> Scheduling outside of working hours</Typography.Header>
          </Flex>
          <Typography.Body color="var(--copy-secondary, #555)" style={{ textAlign: 'center' }}>
            Please note that you&apos;re scheduling outside of {selectedListing?.advocate_name}&apos;s set working hours.
          </Typography.Body>
        </Flex>
      ) : null}

      <Divider />

      {!firstAvailableListing ? (
        <Typography.Header>No available appointments</Typography.Header>
      ) : (
        <AvailableAppointments
          firstAvailableListing={firstAvailableListing}
          selectedListingId={selectedListingId}
          setSelectedListingId={setSelectedListingId}
          listings={listings}
          showPhysicianAvailability={showPhysicianAvailability}
        />
      )}

      <Flex vertical gap="1rem">
        <Flex vertical>
          <Typography.Header size={Size.XS}>Call Type</Typography.Header>
          <Typography.Body size={Size.XS}>
            How does the patient want to meet with the {showPhysicianAvailability ? 'physician' : 'advocate'}?
          </Typography.Body>
        </Flex>
        <Radio
          options={[
            { label: 'Phone Call', value: 'phone' },
            { label: 'Video Call', value: 'video' },
          ]}
          onChange={(e) => setCallType(e.target.value)}
          value={callType}
        />
      </Flex>

      {firstAvailableListing && (
        <Flex justify="center">
          <StyledButton onClick={onScheduleMeeting} disabled={!selectedListingId}>
            <Icons.Video color="#FFF" />
            <Typography.Header size={Size.SM}>{originalBooking ? 'Reschedule' : 'Schedule'} Meeting</Typography.Header>
          </StyledButton>
        </Flex>
      )}
    </Flex>
  );
};
