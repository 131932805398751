type SortInput = {
  field?: React.Key | readonly React.Key[] | string;
  // why does is order potentially both undefined or null? Antd's table types are ... a bit difficult to work with
  order?: 'ascend' | 'descend' | 'filter' | null;
};

type AntDSorter = SortInput | SortInput[];
type AntDFilter = { [key: string]: (string | number | boolean)[] | boolean | null } | null;

type Pagination = {
  current?: number;
};

export type QueryParamInputs = {
  page?: number;
  filter?: Record<string, unknown>;
  sort?: string[];
  include?: string[];
  search?: string;
};

export default function antdOnChangeToQueryParams(
  pagination: Pagination,
  filter: AntDFilter = null,
  sorter: AntDSorter,
): QueryParamInputs {
  return {
    include: [],
    filter: parseFilter(filter),
    page: pagination.current ?? 1,
    sort: parseSort(sorter),
  };
}

function parseFilter(filter: AntDFilter = null): QueryParamInputs['filter'] {
  if (!filter) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(filter).filter(([key, val]) => {
      return !!val;
    }),
  );
}

function parseSort(sorter?: AntDSorter): string[] {
  if (!sorter) {
    return [];
  }

  if (!Array.isArray(sorter)) {
    const sign = sorter.order === 'ascend' ? '' : '-';

    if (!sorter.field) {
      return [];
    }

    if (['dt', 'date'].includes(sorter.field as string)) {
      const sign = sorter.order === 'descend' ? '' : '-';
      return [`${sign}${sorter.field}`];
    }

    return [`${sign}${sorter.field}`];
  }

  return sorter.map((s) => {
    if (!s.field) {
      return '';
    }

    if (['dt', 'date'].includes(s.field as string)) {
      const sign = s.order === 'descend' ? '' : '-';
      return `${sign}${s.field}`;
    }

    const sign = s.order === 'ascend' ? '' : '-';
    return `${sign}${s.field}`;
  });
}
