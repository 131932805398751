import styled from '@emotion/styled';
import { Stack, Switch, SwitchProps } from '@mui/material';
import { baseColors, Button, Typography } from '@solace-health/ui';

export const HighlightButton = styled(Button.Unstyled)<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 0.5rem;
  padding: 0.5rem;

  && {
    background-color: #f4f8f7;
  }
  border: 1px solid #285e50;

  &.ant-btn-text:hover {
    border: 1px solid #d7a13b;
    && {
      background-color: #fff7e9;
    }
  }

  ${({ selected }) =>
    selected
      ? `
    border: 1px solid #d7a13b;
    && {
      background-color: #fff7e9;
    }
  `
      : `
  border: 1px solid  #555;
  background:  #FFF
    `}
`;

const OffHoursSwitchContainer = styled(Stack)`
  width: 386px;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  gap: 0.75rem;
  align-items: center;
  align-self: stretch;

  border-radius: 0.5rem;
  border: 1px solid var(--border-color-light, #d9d9d9);
`;

const OffHoursSwitchUI = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#285E50',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: baseColors.grey100,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: 'background-color 500ms',
  },
}));

const OffHourSwitchLabel = styled(Typography.Body)`
  font-size: 1rem;
`;

export const OffHoursSwitch = ({ checked, onChange }: { checked: boolean; onChange: (checked: boolean) => void }) => {
  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <OffHoursSwitchContainer>
      <OffHoursSwitchUI checked={checked} onChange={onSwitchChange} size="small" />
      <OffHourSwitchLabel>Include availability outside of working hours</OffHourSwitchLabel>
    </OffHoursSwitchContainer>
  );
};
