import React from 'react';
import useAuthentication from '../useAuth';
import * as S from '../style';
import { Flex, Size, Typography } from '@solace-health/ui';
import { SocialLoginButton } from './GoogleLoginButton';

export const GoogleLogin = () => {
  const { googleLogin } = useAuthentication();

  return (
    <Flex justify="center" style={{ marginTop: '8rem' }}>
      <S.CardContent vertical gap="2rem" justify="center">
        <Typography.Display center size={Size.LG}>
          Solace Admin
        </Typography.Display>
        <SocialLoginButton onClick={googleLogin} />
      </S.CardContent>
    </Flex>
  );
};
