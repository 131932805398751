import { Flex, Size, Icons, Typography } from '@solace-health/ui';
import * as S from './style';

export const WarningCard = ({
  bgColor,
  secondaryColor,
  title,
  content,
  textColor = '#101010',
}: {
  bgColor: string;
  secondaryColor: string;
  title: string;
  content: React.ReactNode;
  textColor?: string;
}) => {
  return (
    <S.Container style={{ backgroundColor: bgColor, borderColor: secondaryColor }}>
      <Flex vertical gap="0.5rem">
        <Typography.Body size={Size.LG} bold color={textColor}>
          {title}
        </Typography.Body>
        {typeof content === 'string' ? <Typography.Body color={textColor}>{content}</Typography.Body> : content}
      </Flex>
      <Flex>
        <Icons.ErrorExclamation color={bgColor} secondaryColor={secondaryColor} size={28} />
      </Flex>
    </S.Container>
  );
};
