import { Flex, Typography } from '@solace-health/ui';

export const EligibilityResultItem = ({
  field,
  value,
  valueColor = '#101010',
  highlight = false,
}: {
  field: string;
  value: string | undefined;
  valueColor?: string;
  highlight?: boolean;
}) => {
  return (
    <Flex vertical gap="0.5rem" style={{ flexBasis: '50%' }}>
      <Typography.Body bold>{field}</Typography.Body>
      <div
        style={
          highlight
            ? { backgroundColor: '#F4E5CA', borderRadius: '0.5rem', width: 'fit-content', padding: '0.0625rem 0.25rem' }
            : {}
        }
      >
        <Typography.Body color={valueColor}>{value}</Typography.Body>
      </div>
    </Flex>
  );
};
