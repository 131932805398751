import { LoadingSpinner } from '@solace-health/ui';
import { useMachine } from '@xstate/react';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useProspect, { UpsertProspectDto } from '../../../hooks/prospects/useProspect';
import { GetUserResponse } from '../../../hooks/useGetUser';
import { Prospect } from '../../../types/prospect';
import { User } from '../../../types/user';
import { ConfirmCloseModal } from './ConfirmCloseModal/ConfirmCloseModal';
import { DrawerHeader } from './DrawerHeader/DrawerHeader';
import { GenericError } from './shared/GenericError/GenericError';
import { AddPatientEvent, AddPatientEventContext, AddPatientState, stateMachine } from './stateMachine';
import { AddedToWaitingList } from './steps/AddedToWaitingList/AddedToWaitingList';
import { CheckAnotherPolicy } from './steps/CheckAnotherPolicy/CheckAnotherPolicy';
import { CheckingEligibility } from './steps/CheckingEligibility/CheckingEligibility';
import { ConfirmMemberId } from './steps/ConfirmMemberId/ConfirmMemberId';
import { ConfirmNameAndDob } from './steps/ConfirmNameAndDob/ConfirmNameAndDob';
import { EligibilityStatusEligible } from './steps/EligibilityStatusEligible/EligibilityStatusEligible';
import { EligibilityStatusFailed } from './steps/EligibilityStatusFailed/EligibilityStatusFailed';
import { EligibilityStatusIneligible } from './steps/EligibilityStatusIneligible/EligibilityStatusIneligible';
import { EmailAlreadyInUse } from './steps/EmailAlreadyInUse/EmailAlreadyInUse';
import { Initialization } from './steps/Initialization/Initialization';
import { NoPhysiciansAvailable } from './steps/NoPhysiciansAvailable/NoPhysiciansAvailable';
import PatientInfoForm from './steps/PatientInfoForm/PatientInfoForm';
import { PossibleDuplicate } from './steps/PossibleDuplicate/PossibleDuplicate';
import { Schedule } from './steps/Schedule/Schedule';
import * as S from './style';

type DrawerConfig = {
  hideHeader?: boolean;
  headingText?: string;
  headingButtonOnClick?: () => void;
  content: JSX.Element;
  centerContent?: boolean;
  hideFooter?: boolean;
  extendedFooter?: boolean;
  allowCloseWithoutConfirm?: boolean;
};

type UpsertPatientDrawerProps = {
  isOpen?: boolean;
  patient?: GetUserResponse;
  onClose?: () => void;
  prospectId?: string;
};

const UpsertPatientDrawer = ({
  isOpen = false,
  patient = undefined,
  prospectId: queryParamProspectId = undefined,
  onClose = () => null,
}: UpsertPatientDrawerProps) => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [confirmCloseModalOpen, setConfirmCloseModalOpen] = useState(false);

  const [state, send] = useMachine(stateMachine);

  const {
    data: prospect,
    upsertProspect,
    loading: isLoadingProspect,
    refresh: refreshProspect,
  } = useProspect(state.context.prospectId ? { id: state.context.prospectId } : {});

  // Create new prospect when drawer opened unless prospect is specifically passed
  useEffect(() => {
    if (!isOpen) return;

    if (queryParamProspectId) {
      send({ type: AddPatientEvent.SetProspectId, context: { prospectId: queryParamProspectId } });
      return;
    }

    if (patient) {
      send({ type: AddPatientEvent.SetUserId, context: { userId: patient.id } });
    }

    const existingUserData: UpsertProspectDto = patient
      ? {
          user_id: patient.id,
          first_name: patient.first_name,
          last_name: patient.last_name,
          state: patient.address?.state,
          phone: patient.phone,
          email: patient.email,
          payload: {
            // Fallback to undefined because patient.dob can be null in the User type
            patient_dob: patient.dob || undefined,
          },
        }
      : {};

    upsertProspect(existingUserData)
      .then(({ data }) => {
        send({ type: AddPatientEvent.SetProspectId, context: { prospectId: data.id } });
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong - please try reopening the drawer', { variant: 'error' });
      });
  }, [isOpen]);

  if (!isOpen) return null;
  if (isLoadingProspect || !prospect) return <LoadingSpinner />;

  const onNextStep = (context: AddPatientEventContext = {}) => {
    send({ type: AddPatientEvent.Next, context });
  };

  const onLastStep = () => {
    send({ type: AddPatientEvent.Back });
  };

  const handleConfirmCloseModal = () => {
    send({ type: AddPatientEvent.Reset });
    onClose();
    if (!!queryParamProspectId) history('/patients', { replace: true });
    setConfirmCloseModalOpen(false);
  };

  const drawerConfig: DrawerConfig = (() => {
    switch (state.value) {
      case AddPatientState.Initialization:
        return {
          content: <Initialization onNextStep={onNextStep} patientId={patient?.id} />,
        };
      case AddPatientState.PatientInfo:
        return {
          headingText: 'Add New Patient',
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
          content: <PatientInfoForm prospect={prospect} onNextStep={onNextStep} />,
        };
      case AddPatientState.EmailAlreadyInUse:
        return {
          content: <EmailAlreadyInUse existingUserId={state.context.existingUserId as User['id']} onLastStep={onLastStep} />,
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
        };
      case AddPatientState.NoPhysiciansAvailable:
        return {
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
          content: (
            <NoPhysiciansAvailable
              prospect={prospect}
              upsertProspect={upsertProspect}
              refreshProspect={refreshProspect}
              onNextStep={onNextStep}
              onLastStep={onLastStep}
            />
          ),
        };
      case AddPatientState.PossibleDuplicateFound:
        return {
          content: (
            <PossibleDuplicate
              possibleDuplicates={state.context.possibleDuplicateProspects}
              prospect={prospect}
              onNextStep={onNextStep}
              onLastStep={onLastStep}
            />
          ),
          headingText: 'Matches Found',
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
        };
      case AddPatientState.CheckingCoverage:
        return {
          content: (
            <CheckingEligibility
              prospectId={prospect.id}
              onNextStep={onNextStep}
              refreshProspect={refreshProspect as () => Promise<Prospect>}
            />
          ),
          centerContent: true,
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
          hideFooter: true,
        };
      case AddPatientState.EligibilityStatusEligible:
        return {
          content: (
            <EligibilityStatusEligible
              prospect={prospect}
              onNextStep={onNextStep}
              userId={state.context.userId as User['id']}
            />
          ),
          headingText: 'Eligibility Check',
          extendedFooter: true,
          headingButtonOnClick: handleConfirmCloseModal,
          allowCloseWithoutConfirm: true,
        };
      case AddPatientState.Schedule:
        return {
          hideHeader: true,
          content: <Schedule handleClose={onClose} isOpen={isOpen} userId={state.context.userId as User['id']} />,
          hideFooter: true,
          allowCloseWithoutConfirm: true,
        };
      case AddPatientState.EligibilityStatusIneligible:
        return {
          content: (
            <EligibilityStatusIneligible
              prospect={prospect}
              upsertProspect={upsertProspect}
              refreshProspect={refreshProspect}
              onNextStep={onNextStep}
            />
          ),
          headingText: 'Eligibility Check',
          headingButtonOnClick: handleConfirmCloseModal,
          allowCloseWithoutConfirm: true,
        };
      case AddPatientState.NoMbiFoundConfirmNameAndDob:
        return {
          headingText: 'Eligibility Check',
          content: (
            <ConfirmNameAndDob
              prospect={prospect}
              upsertProspect={upsertProspect}
              refreshProspect={refreshProspect}
              onNextStep={onNextStep}
              onLastStep={onLastStep}
            />
          ),
        };
      case AddPatientState.NoMbiFoundEnterPayorAndMbi:
        return {
          headingText: 'Eligibility Check',
          content: (
            <ConfirmMemberId
              prospect={prospect}
              upsertProspect={upsertProspect}
              refreshProspect={refreshProspect}
              onNextStep={onNextStep}
              onLastStep={onLastStep}
            />
          ),
        };
      case AddPatientState.EligibilityStatusFailed:
        return {
          content: (
            <EligibilityStatusFailed
              prospect={prospect}
              onNextStep={onNextStep}
              onLastStep={onLastStep}
              invalidField={state.context.invalidField}
              existingUserId={state.context.existingUserId}
              upsertProspect={upsertProspect}
            />
          ),
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
          headingText: 'Eligibility Check',
        };
      case AddPatientState.SuccessfullyAddedToWaitlist:
        return {
          content: (
            <AddedToWaitingList subText={state.context.waitlistMessage as string} onClose={handleConfirmCloseModal} />
          ),
          headingButtonOnClick: handleConfirmCloseModal,
          allowCloseWithoutConfirm: true,
        };
      case AddPatientState.CheckAnotherPolicy:
        return {
          content: (
            <CheckAnotherPolicy
              prospect={prospect}
              onNextStep={onNextStep}
              onLastStep={onLastStep}
              upsertProspect={upsertProspect}
              existingUserId={state.context.userId}
            />
          ),
          headingButtonOnClick: () => setConfirmCloseModalOpen(true),
          headingText: 'Eligibility Check',
        };
      default:
        return { content: <GenericError /> };
    }
  })();

  return (
    <>
      <S.StyledDrawer
        open={isOpen}
        destroyOnClose
        placement="right"
        width="30rem"
        onClose={() => {
          if (drawerConfig.allowCloseWithoutConfirm) {
            handleConfirmCloseModal();
          } else {
            setConfirmCloseModalOpen(true);
          }
        }}
      >
        <S.DrawerInnerWrapper vertical hideFooter={drawerConfig.hideFooter} extendedFooter={drawerConfig.extendedFooter}>
          {!drawerConfig.hideHeader && (
            <DrawerHeader
              headingText={drawerConfig.headingText}
              buttonOnClick={
                drawerConfig.headingButtonOnClick ? drawerConfig.headingButtonOnClick : () => setConfirmCloseModalOpen(true)
              }
            />
          )}

          <S.DrawerContentWrapper align={drawerConfig.centerContent ? 'center' : undefined}>
            {drawerConfig.content}
          </S.DrawerContentWrapper>
        </S.DrawerInnerWrapper>
      </S.StyledDrawer>

      <ConfirmCloseModal
        isOpen={confirmCloseModalOpen}
        handleCancel={() => setConfirmCloseModalOpen(false)}
        handleConfirm={handleConfirmCloseModal}
      />
    </>
  );
};

export default UpsertPatientDrawer;
