import { Booking } from '../types/booking';
import useGetPaginatedResource from './useGetPaginatedResource';

export const useGetVisits = ({ include = [], filter = {} }: { include?: string[]; filter?: Record<string, unknown> }) => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<Booking>('/v1/api/admin/visits', {
    include,
    filter,
    sort: ['-start'],
  });

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};
