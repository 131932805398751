import { Flex, Icons, Size, Typography } from '@solace-health/ui';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { WarningCard } from '../../../../../components/shared/UpsertPatientDrawer/shared/WarningCard/WarningCard';
import { SerializedInsuranceEligibility } from '../../../../../hooks/prospects/useCheckInsuranceEligibility';
import useReferrals from '../../../../../hooks/referrals/useReferrals';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import { LastContactButton } from '../../../../../shared/components/LastContactButton';
import {
  EligibleStatusReason,
  IneligibleStatusReason,
  ReferralEligibilityCheckStatus,
} from '../../../../../types/referrals';
import { User } from '../../../../../types/user';
import ActiveTag from './ActiveTag/ActiveTag';
import * as S from './style';

type PatientHeaderProps = {
  user: GetUserResponse;
  setDetailsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSchedulingDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLovedOne?: boolean;
  canScheduleAppointment?: boolean;
  refresh: () => void;
  lastEligibilityCheck: SerializedInsuranceEligibility | undefined;
};

export default function PatientHeader({
  user,
  setDetailsDrawerOpen,
  setSchedulingDrawerOpen,
  isLovedOne = false,
  canScheduleAppointment = false,
  refresh,
  lastEligibilityCheck,
}: PatientHeaderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { updateLastContact } = useReferrals();
  const navigate = useNavigate();

  const handleUpdateLastContact = async (lastContact: Date) => {
    const referralId = user?.referral?.id;
    try {
      if (!referralId) throw new Error('No Referral ID');
      if (dayjs(lastContact) > dayjs()) throw new Error('Cannot set last contact in the future');

      const res = await updateLastContact({ id: referralId, lastContact });

      if (res.status !== 200) throw new Error(res.statusText);

      enqueueSnackbar('Updated last contact', { variant: 'success' });
      refresh();
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    }
  };

  const hasIneligibleStatus =
    !!lastEligibilityCheck && lastEligibilityCheck.status === ReferralEligibilityCheckStatus.Ineligible;
  const hasEligibleStatus =
    !!lastEligibilityCheck && lastEligibilityCheck.status === ReferralEligibilityCheckStatus.Eligible;
  const medicareIsSecondaryPayer = lastEligibilityCheck
    ? hasEligibleStatus && lastEligibilityCheck.status_reason === EligibleStatusReason.SupplementalInsurance
    : false;
  const coverageActiveInFuture = hasIneligibleStatus
    ? [IneligibleStatusReason.PlanActiveInFuture, IneligibleStatusReason.PartBactiveInFuture].includes(
        lastEligibilityCheck.status_reason as IneligibleStatusReason,
      )
    : false;

  const lastContactDT = user?.referral?.last_contact_dt;
  let lovedOnesPatient: User | undefined = undefined;
  if (user.sharee_care_relationships && user.sharee_care_relationships.length > 0) {
    lovedOnesPatient = user?.sharee_care_relationships?.[0].client;
  }

  const PatientFileWarningCard = () => {
    if (hasEligibleStatus && medicareIsSecondaryPayer) {
      return (
        <WarningCard
          bgColor="#F4E5CA"
          secondaryColor="#D7A13B"
          title="Medicare is Secondary Payer"
          content={`${lastEligibilityCheck.medicare_supplemental_to_insurance?.payer_name} is the primary payer. You can collect information for the patient's ${lastEligibilityCheck.medicare_supplemental_to_insurance?.payer_name} policy now or later.`}
        />
      );
    }

    if (hasIneligibleStatus && coverageActiveInFuture) {
      return (
        <WarningCard
          bgColor="#F4E5CA"
          secondaryColor="#D7A13B"
          title="This Policy Isn't Active Yet"
          content={`We accept this patient's policy but it isn't active until ${lastEligibilityCheck.coverage_effective_date}.`}
        />
      );
    }

    if (hasIneligibleStatus && !coverageActiveInFuture) {
      return (
        <WarningCard
          bgColor="#F1E1E2"
          secondaryColor="#DA4D54"
          textColor="#DA4D54"
          title="This patient isn't eligible right now."
          content={lastEligibilityCheck.status_reason_description}
        />
      );
    }

    return null;
  };

  return (
    <Flex align="center" justify="space-between">
      <Flex align="center" gap="1rem">
        <Typography.Header size={Size.XL}>
          <S.NameButton onClick={setDetailsDrawerOpen}>
            {user.first_name} {user.last_name}
          </S.NameButton>
        </Typography.Header>
        <ActiveTag active={user.active} />
        <Typography.Body color="#555555">ID: {user.internal_id}</Typography.Body>
      </Flex>

      <div style={{ maxWidth: '33rem', padding: '0 1rem 0.5rem 1rem' }}>
        <PatientFileWarningCard />
      </div>

      <Flex align="center" gap="1rem">
        {canScheduleAppointment && (
          <LastContactButton onClick={() => handleUpdateLastContact(new Date())}>
            <Typography.Body>
              Last Contact: {lastContactDT ? new Date(lastContactDT).toLocaleDateString() : 'N/A'}
            </Typography.Body>
          </LastContactButton>
        )}
        {canScheduleAppointment && !isLovedOne && (
          <S.StyledButton onClick={() => setSchedulingDrawerOpen(true)}>
            <Icons.Call />
            <Typography.Header size={Size.SM}>Schedule Meeting</Typography.Header>
          </S.StyledButton>
        )}
        {isLovedOne && (
          <S.StyledButton onClick={() => navigate(`/patients/${lovedOnesPatient?.id}`)}>
            <Icons.ExternalLink />
            <Typography.Header size={Size.SM}>View Patient Profile</Typography.Header>
          </S.StyledButton>
        )}
      </Flex>
    </Flex>
  );
}
