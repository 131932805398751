import { Button, Flex, Form, Modal, Size, Typography, useForm } from '@solace-health/ui';
import { Claim } from '../../../../../types/claim';
import { User } from '../../../../../types/user';
import { formatCurrency } from '../../../payouts/calculations';

export const ForgiveBalanceModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  claim,
  patient,
  totalPatientResponsibilityCents,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (values: { reason: string }) => Promise<void>;
  claim: Claim | undefined;
  patient: User;
  totalPatientResponsibilityCents: number;
}) => {
  const form = useForm({
    defaultValues: {
      reason: '',
    },
  });

  const onClose = () => {
    form.reset();
    setIsOpen(false);
  };

  const title = claim ? 'Forgive balance?' : `Forgive total balance of ${formatCurrency(totalPatientResponsibilityCents)}?`;
  const SubTitle = () =>
    claim ? (
      <>
        To forgive the balance for Statement ID <strong>{claim.statement_id}</strong> totaling:{' '}
        {formatCurrency(claim.cost_breakdown.total_patient_responsibility_cents)}, please add a note establishing{' '}
        {patient.fullName}’s hardship exception.
      </>
    ) : (
      <>To forgive all current balances, please add a note establishing {patient.fullName}’s hardship exception.</>
    );

  return (
    <Modal isOpen={isOpen} onClose={onClose} destroyOnClose>
      <Form.Container
        formMethods={form}
        onSubmit={(values) => {
          return onSubmit(values).then(() => form.reset());
        }}
      >
        <Flex vertical gap="1.5rem">
          <Flex vertical gap="0.5rem">
            <Typography.Display>{title}</Typography.Display>
            <Typography.Body color="#555" size={Size.LG}>
              <SubTitle />
            </Typography.Body>
          </Flex>

          <Form.Text
            textArea
            name="reason"
            placeholder="Enter notes"
            formOptions={{ required: true }}
            style={{ height: '10rem' }}
          />

          <Flex gap="0.5rem">
            <Button.Secondary onClick={onClose}>Cancel</Button.Secondary>
            <Button.Primary htmlType="submit">Confirm</Button.Primary>
          </Flex>
        </Flex>
      </Form.Container>
    </Modal>
  );
};
